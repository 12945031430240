// ========================================================
// AVATAR
// ========================================================

.avatar {
	display: inline-block;
	vertical-align: middle;
	border-radius: 100%;
	border: 2px solid #FFF;
	margin-right: $gutter/4;
}

.avatar--medium{
	width: 30px;
	height: 30px;
}


.avatar--small {
	width: 20px;
	height: 20px;
}