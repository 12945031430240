.section {
	position: relative;
}

.section--gapless {
    padding: 0;
}

.section--gap-bottom {
    margin-bottom: $gutter * 3;
}

.section--fullscreen-bg {
    background-size: cover;
    background-repeat: no-repeat;
    min-height: calc(100vh - 78px);
    background-position: top;
    background-attachment: fixed;
    padding-bottom: $gutter * 2; 
}

.section__inner {
    width: 100%;
    padding: $gutter/2;
    margin-left: auto;
    margin-right: auto;
    max-width: 90rem; // 1440px
    position: relative;
}


.section__inner--small {
	max-width: 39.5rem;
}

.section__inner--medium {
	max-width: 47rem;
}

.section__line-divided {
    margin: $gutter*2 0;
    width: 100%;
    height: 7px;
    background-image: url(../assets/images/icons/background-menu-underline-white.svg);
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100% 7px;
}

@media screen and (min-width: $view-desktop) {
    
    .section--fullscreen-bg {
        min-height: calc(100vh - 118px);
    }
}