@font-face {
  font-family: 'a_song_for_jennifer';
  src: url("../assets/fonts/a_song_for_jennifer.eot");
  src: url("../assets/fonts/a_song_for_jennifer.woff") format("woff"), url("../assets/fonts/a_song_for_jennifer.ttf") format("truetype"), url("../assets/fonts/a_song_for_jennifer.svg") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'a_song_for_jennifer';
  src: url("../assets/fonts/a_song_for_jennifer_bold.eot");
  src: url("../assets/fonts/a_song_for_jennifer_bold.woff") format("woff"), url("../assets/fonts/a_song_for_jennifer_bold.ttf") format("truetype"), url("../assets/fonts/a_song_for_jennifer_bold.svg") format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'flama';
  src: url("../assets/fonts/flama-light.eot");
  src: url("../assets/fonts/flama-light.woff") format("woff"), url("../assets/fonts/flama-light.ttf") format("truetype"), url("../assets/fonts/flama-light.svg") format("svg");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'flama';
  src: url("../assets/fonts/flama-basic.eot");
  src: url("../assets/fonts/flama-basic.woff") format("woff"), url("../assets/fonts/flama-basic.ttf") format("truetype"), url("../assets/fonts/flama-basic.svg") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'flama';
  src: url("../assets/fonts/flama-medium.eot");
  src: url("../assets/fonts/flama-medium.woff") format("woff"), url("../assets/fonts/flama-medium.ttf") format("truetype"), url("../assets/fonts/flama-medium.svg") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'flama';
  src: url("../assets/fonts/flama-semibold.eot");
  src: url("../assets/fonts/flama-semibold.woff") format("woff"), url("../assets/fonts/flama-semibold.ttf") format("truetype"), url("../assets/fonts/flama-semibold.svg") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'iconfont';
  src: url("../assets/fonts/iconfont.eot?pgvi0t");
  src: url("../assets/fonts/iconfont.eot?#iefixpgvi0t") format("embedded-opentype"), url("../assets/fonts/iconfont.woff?pgvi0t") format("woff"), url("../assets/fonts/iconfont.ttf?pgvi0t") format("truetype"), url("../assets/fonts/iconfont.svg?pgvi0t#iconfont") format("svg");
  font-weight: bold;
  font-style: normal; }

.icon-font, .list--ingredients li:before, .breadcrumb li:not(:last-of-type):after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.accessibility, .js .js-accessibility {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px; }

.accessibility:focus, .accessibility:active {
  clip: auto;
  height: auto;
  overflow: visible;
  position: fixed;
  width: auto; }

.group:before, .fieldset:before, .group:after, .fieldset:after {
  content: " ";
  display: table; }

.group:after, .fieldset:after {
  clear: both; }

.skip-to-main {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -9999; }

.skip-to-main:focus, .skip-to-main:active {
  color: #FFF;
  background-color: #000;
  left: 0;
  top: 0;
  overflow: auto;
  z-index: 999; }

.image {
  margin: 0; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-green {
  color: #286542; }

.scroll-x {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

.image-bg-leaves {
  background-image: url(../assets/images/green-bg.png);
  background-size: cover; }

html:not(.no-js) .no-js-only {
  display: none; }

@media only screen and (max-width: 30rem) {
  .hide-mobile {
    display: none; } }

html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, button, datalist, input, select, textarea, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figcaption, figure, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border-color: #000;
  border-style: solid;
  float: none;
  font: inherit;
  margin: 0;
  padding: 0;
  text-align: inherit;
  vertical-align: baseline;
  white-space: inherit; }

html, button, datalist, input, select, textarea {
  -webkit-font-smoothing: antialiased; }

html {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; }

body {
  background: #fff;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  word-wrap: break-word; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed; }

th, td, textarea {
  vertical-align: top; }

th {
  font-weight: bold;
  text-align: left; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none; }

pre, code, kbd, samp, button, datalist, input, select, textarea, .default {
  font-size: 1em; }

pre, code, kbd, samp {
  font-family: monospace, monospace; }

strong, b {
  font-weight: bold; }

em, i, dfn, span[lang], .special {
  font-style: italic; }

img, video, object, iframe, legend, datalist, input, select, textarea, label, article, aside, audio, canvas, details, figcaption, figure, footer, header, hgroup, marquee, menu, nav, section, svg, video, .block, .wrap, .group, .fieldset, .paragraph {
  display: block; }

img, video, object, iframe {
  max-width: 100%; }

img, video {
  height: auto;
  width: auto; }

ol, ul {
  list-style-position: outside; }

ol {
  list-style-type: decimal; }

ul {
  list-style-type: disc; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

legend {
  white-space: normal; }

label, .label {
  margin-bottom: .25em; }

textarea {
  min-height: 8em;
  overflow: auto;
  -moz-resize: vertical;
  resize: vertical; }

button, datalist, input, select, textarea, legend, label, .label {
  line-height: normal; }

button, datalist, input, select, textarea {
  border-radius: 0;
  border-width: 0;
  box-sizing: border-box;
  font-weight: normal;
  margin: 0; }

button, input[type="button"], input[type="submit"], input[type="checkbox"], input[type="file"], input[type="radio"], input[type="reset"], label, select, a {
  cursor: pointer; }

button, html input[type="button"], input[type="submit"], input[type="reset"] {
  -webkit-appearance: button;
  background-color: transparent;
  display: inline-block;
  font-weight: bold;
  overflow: visible;
  vertical-align: top;
  width: auto; }

button[disabled], input[disabled] {
  cursor: default; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border-width: 0;
  padding: 0; }

datalist, input, select, textarea, fieldset, legend, table, .full {
  width: 100%; }

input[type="text"], input[type="number"], input[type="email"], input[type="tel"], input[type="url"], input[type="password"] {
  -moz-appearance: none;
  -webkit-appearance: none; }

/* remove mobile input shadow */
input[type="checkbox"], input[type="radio"] {
  background-color: transparent;
  border-width: 0;
  box-shadow: none;
  float: left;
  margin: .075em .5em 0 0;
  padding: 0;
  width: auto; }

input[type="checkbox"]:focus, input[type="radio"]:focus {
  background-color: transparent; }

input[type="checkbox"] + label, input[type="radio"] + label {
  float: left;
  margin-bottom: 0; }

input[type="search"] {
  -webkit-appearance: textfield; }

input[type="search"]::-webkit-search-decoration {
  display: none; }

select {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

:focus, :active {
  transition: none; }

:focus {
  outline: none; }

html,
button,
datalist,
input,
select,
textarea {
  color: #000000; }

html {
  font-style: normal;
  font-weight: 300;
  letter-spacing: normal;
  line-height: 1.5;
  box-sizing: border-box;
  overflow-x: hidden; }

* {
  box-sizing: inherit; }
  *:before, *:after {
    box-sizing: inherit; }

body {
  font-family: "flama", sans-serif;
  font-size: 100%; }
  body:after {
    content: "0em";
    display: block;
    height: 0;
    position: absolute;
    overflow: hidden;
    width: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin: 0 0 0.625rem 0;
  line-height: .8; }

h1,
.title-primary {
  font-family: "a_song_for_jennifer", sans-serif;
  font-size: 2.2em;
  text-transform: uppercase; }

h2,
.title-secondary {
  font-family: "a_song_for_jennifer", sans-serif;
  font-size: 2em;
  text-transform: uppercase; }

h3,
.title-tertiary {
  margin-top: 0.625rem;
  font-weight: 500; }

sup {
  font-weight: 400;
  color: #737373;
  font-size: .875rem; }

time,
time span {
  font-size: .875rem;
  line-height: 1.4; }

span {
  vertical-align: middle; }

h2:first-child,
h3:first-child,
p:first-child,
img:first-child,
table:first-child,
form:first-child,
fieldset:first-child,
.text-label:first-child,
pre:first-child {
  margin-top: 0; }

h2:only-child,
h3:only-child,
p:only-child,
img:only-child,
table:only-child,
form:only-child,
fieldset:only-child,
.text-label:only-child,
pre:only-child {
  margin-bottom: 0; }

h2 + p,
h3 + p,
h2 + ol,
h3 + ol,
h2 + ul,
h3 + ul {
  margin-top: 0; }

p,
ol,
ul,
img,
table,
form,
fieldset,
pre,
object,
embed,
video,
audio,
figure,
figcaption,
.paragraph {
  margin-top: 0.625rem; }
  p:last-of-type,
  ol:last-of-type,
  ul:last-of-type,
  img:last-of-type,
  table:last-of-type,
  form:last-of-type,
  fieldset:last-of-type,
  pre:last-of-type,
  object:last-of-type,
  embed:last-of-type,
  video:last-of-type,
  audio:last-of-type,
  figure:last-of-type,
  figcaption:last-of-type,
  .paragraph:last-of-type {
    margin-bottom: 0; }

p:last-of-type {
  margin-top: 0.625rem; }

p:only-child {
  margin: 0; }

p,
ol,
ul,
blockquote,
img,
table,
form,
fieldset,
pre,
object,
embed,
video,
audio,
figure,
figcaption,
.paragraph {
  margin-bottom: 0.625rem; }

ol,
ul {
  padding-left: 1.25rem; }

ol ol,
ul ul {
  padding-left: 1.25rem; }

li {
  margin-bottom: 0.625rem;
  margin-top: 0.625rem; }

datalist,
input,
select,
textarea {
  padding: 0.625rem 1.25rem;
  background-color: transparent;
  color: inherit; }
  datalist:focus, datalist:active,
  input:focus,
  input:active,
  select:focus,
  select:active,
  textarea:focus,
  textarea:active {
    color: inherit; }

a,
p a {
  color: #286542;
  fill: currentColor;
  text-decoration: underline;
  position: relative;
  transition: 0.2s ease-in-out; }
  a:active, a:focus, a:hover,
  p a:active,
  p a:focus,
  p a:hover {
    color: #aaaaaa; }

a {
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  text-decoration: none;
  display: inline-block; }

blockquote {
  position: relative;
  z-index: 0;
  color: #fff; }
  blockquote:before {
    content: '';
    position: absolute;
    top: 0;
    left: -2.5rem;
    width: 150%;
    height: 100%;
    background-image: url("../assets/images/bg-main-green.png");
    background-size: cover;
    z-index: -1;
    transform: rotate(-2deg);
    mask: url("../assets/images/mask-square.png");
    mask-size: 100% 100%;
    mask-position: center; }
  blockquote:after {
    content: "";
    display: none; }

blockquote p {
  width: 100%;
  quotes: '\201c' '\201d';
  font-family: "a_song_for_jennifer", sans-serif;
  font-size: 1.9rem;
  line-height: 1.5;
  padding: 2.5rem 0;
  margin: 0;
  text-transform: uppercase; }
  blockquote p:before {
    content: open-quote;
    margin-right: 0.3125rem; }
  blockquote p:after {
    content: close-quote;
    margin-left: 0; }

button {
  background-color: #000;
  color: #fff;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  font-weight: 500; }
  button:focus, button:hover, button:active {
    color: #FFF; }

::-webkit-input-placeholder {
  opacity: 1; }

@media screen and (min-width: 48rem) {
  body:after {
    content: "48rem"; }
  h1,
  .title-primary {
    font-size: 2em; }
  h2,
  .title-secondary {
    font-size: 1.75em; }
  h3,
  .title-tertiary {
    font-size: 1.25em; }
  blockquote:before {
    left: 0;
    width: 100%; }
  blockquote p {
    padding: 3.125rem; } }

@media screen and (min-width: 60rem) {
  body:after {
    content: "60rem"; }
  body {
    font-size: 106.25%; }
  h1,
  .title-primary {
    font-size: 3.7645em; }
  .hero-title {
    font-size: 5.8em; }
  h2,
  .title-secondary {
    font-size: 2.824em; }
  h3,
  .title-tertiary {
    font-size: 1.411em; }
  h4,
  .title-quaternary {
    font-size: 1.125em; } }

.list {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0; }

.list--ingredients {
  margin-bottom: 2.5rem; }

.list--ingredients li {
  position: relative;
  padding-left: 2.5rem; }
  .list--ingredients li:before {
    content: '\e901';
    color: white;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0.3125rem; }

.list--inline li {
  display: inline-block;
  vertical-align: middle; }

.list--links li {
  margin: 0; }

table {
  position: relative;
  min-width: 500px; }

th {
  text-transform: capitalize;
  padding: 0.625rem 1.25rem;
  border-right: 1px solid #fff;
  width: 125px; }
  th:first-of-type {
    padding-left: 0;
    white-space: no-wrap; }
  th:last-of-type {
    border-right: none; }

tr {
  padding-right: 1.25rem;
  border-bottom: 1px solid #fff; }
  tr:last-of-type {
    border-bottom: none; }

td {
  border-right: 1px solid #fff;
  padding: 0.625rem 1.25rem; }
  td h3 {
    margin-bottom: 0.625rem; }
  td:first-of-type {
    padding-left: 0;
    font-weight: 700; }
  td:last-of-type {
    border-right: none; }

.icon {
  display: inline-block;
  vertical-align: middle;
  height: 22px;
  width: 22px;
  transition: fill 0.2s ease-in-out, transform 0.2s ease-in-out; }

.icon--inline {
  margin-right: 0.625rem; }

.icon--facebook-footer,
.icon--close,
.icon--prev-arrow,
.icon--next-arrow {
  height: 20px;
  width: 20px; }

.icon--facebook-menu {
  height: 15px;
  width: 15px;
  margin-top: -3px; }

.icon--prev-arrow {
  transform: rotate(180deg); }

.icon--turn {
  height: 25px;
  width: 25px; }

.icon--youtube-footer {
  height: 21px;
  width: 30px; }

.icon--product-info {
  margin-right: 0.625rem;
  fill: #fff; }

.icon--eye {
  width: 50px;
  height: 50px; }

.icon--eye-circle {
  height: 70px;
  width: 70px;
  font-family: "flama", sans-serif;
  backface-visibility: hidden; }

.icon--shopping-basket {
  height: 25px;
  width: 25px; }

.icon--zoomer {
  width: 65px;
  height: 65px; }

.icon--overview {
  width: 30px;
  height: 30px; }

.heading__underline {
  border-bottom: 3px solid #286542;
  box-shadow: inset 0 -8px 0 #286542; }

.gradient:before {
  content: '';
  background-image: linear-gradient(360deg, rgba(46, 46, 46, 0.5) 0, rgba(46, 46, 46, 0) 50%);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.sub-title {
  font-size: 16px; }

form {
  margin-top: 0; }

h2 + form {
  margin-top: 1.25rem; }

.text-label,
fieldset > label {
  font-weight: 700;
  margin-bottom: 0.625rem;
  font-size: 1em;
  line-height: 1.365; }

fieldset {
  margin-bottom: 1.25rem;
  position: relative; }
  fieldset:last-of-type {
    margin-bottom: 0; }

fieldset .icon {
  margin-right: 0; }

.fieldset__inner {
  position: relative;
  display: block;
  border: 1px solid #fff;
  border-radius: 4px;
  margin-top: 1.25rem;
  background-color: #FFF; }
  .fieldset__inner:before {
    content: "";
    display: table;
    clear: both; }
  .fieldset__inner:last-of-type {
    margin: 0; }

.fieldset__inner--bordless {
  border: none; }

.fieldset__row {
  margin: -0.625rem; }

.fieldset__row > fieldset {
  padding: 0.625rem;
  margin: 0; }

textarea,
input,
select {
  padding: 0.83333rem;
  border: 1px solid #286542;
  border-radius: 4px;
  transition: color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  line-height: 1;
  background-color: #FFF;
  color: #000000; }
  textarea::-webkit-input-placeholder,
  input::-webkit-input-placeholder,
  select::-webkit-input-placeholder {
    color: #000000;
    opacity: 0.8; }
  textarea:focus, textarea:active,
  input:focus,
  input:active,
  select:focus,
  select:active {
    transition: color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15), 0 0 0px rgba(0, 0, 0, 0.05);
    color: #000000; }

label {
  font-weight: 400;
  display: inline-block; }

.js-floating-label label {
  position: absolute;
  top: 0.83333rem;
  left: 0.83333rem;
  right: 0.83333rem;
  transform-origin: 0 center;
  z-index: 1;
  transition: transform 0.2s ease-in-out, top 0.2s ease-in-out; }

.js-floating-label.focus label,
.js-floating-label.has-value label,
.no-js .js-floating-label label {
  transform: scale(0.7);
  top: 0; }

input[type='checkbox'],
input[type='radio'] {
  position: fixed;
  bottom: 100%;
  right: 100%;
  opacity: 0;
  visibility: hidden; }

input[type='range'] {
  display: none;
  position: absolute;
  top: 0;
  width: 100%;
  left: 50%;
  transform: translateX(-50%); }

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  animate: 0.2s;
  background-color: #286542; }

input[type=range]::-webkit-slider-thumb {
  height: 36px;
  width: 16px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7.5px; }

.no-js input[type='range'] {
  display: block;
  padding: 0; }

.fieldset-error {
  position: relative;
  z-index: 4; }

.error-box {
  transition: opacity 0.4s ease-in-out, visibility 0.4s 0.4s ease-in-out, transform 0.4s ease-in-out;
  transform: translateY(20%);
  visibility: hidden;
  opacity: 0;
  border-radius: 4px;
  background-color: #E63C2F;
  padding: 0.625rem 1rem;
  font-size: 16px;
  color: #FFF;
  box-sizing: border-box;
  position: absolute;
  top: 7px;
  line-height: 1.4;
  left: 0;
  display: inline-block;
  z-index: 1;
  speak: none;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.32); }

.error-box:after {
  bottom: 100%;
  left: 12px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #E63C2F;
  border-width: 7px; }

.error .fieldset-error .error-box {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  speak: auto;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out, transform 0.4s ease-in-out; }

.error textarea:focus ~ .fieldset-error .error-box, .error input:focus ~ .fieldset-error .error-box {
  transition: opacity 0.4s ease-in-out, visibility 0.4s 0.4s ease-in-out, transform 0.4s ease-in-out;
  transform: translateY(20%);
  visibility: hidden;
  opacity: 0; }

.fieldset-required.error label:after {
  color: #E63C2F; }

input[type='radio'] + label {
  display: block;
  float: none;
  position: relative;
  margin: 0 0 0.625rem;
  padding: 0 0 0 1.875rem;
  font-weight: 300; }
  input[type='radio'] + label:before, input[type='radio'] + label:after {
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100%; }
  input[type='radio'] + label:before {
    left: 0;
    width: 20px;
    height: 20px;
    background-color: #FFF;
    border: 1px solid #286542; }
  input[type='radio'] + label:after {
    left: 5px;
    width: 10px;
    height: 10px;
    background-color: transparent; }
  input[type='radio'] + label:hover:after, input[type='radio'] + label:focus:after {
    background-color: #286542; }

input[type='radio']:checked + label:before {
  background-color: #fff; }

input[type='radio']:checked + label:after {
  background-color: #286542; }

[type='search'] {
  padding-left: 3.125rem; }

[type='search'] + button {
  position: absolute; }

.search__submit {
  padding: 0.625rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  background-color: transparent; }

.fieldset__search,
.fieldset__search--button {
  position: relative; }
  .fieldset__search:before,
  .fieldset__search--button:before {
    content: '';
    background-image: url("../assets/images/icons/search.svg");
    background-size: contain;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1rem;
    transition: color 0.2s ease-in-out; }

.fieldset__search--button [type='search'] {
  padding-right: 6.25rem; }

.fieldset__search--button [type='search']:not(.border) {
  border: none; }

.fieldset__search--button button {
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  line-height: 0; }

.select-box {
  position: relative; }
  .select-box:before {
    content: '';
    border-radius: 4px;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .select-box:after {
    content: '';
    background-image: url("../assets/images/icons/dropdown-down.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    transition: color 0.2s ease-in-out; }
  .select-box:focus:after {
    color: #404040; }

.select-box select {
  opacity: 0.8;
  height: 49px;
  -webkit-appearance: none;
  /*Removes default chrome and safari style*/
  -moz-appearance: none;
  /*Removes default firefox style*/
  appearance: none;
  background-color: transparent;
  z-index: 1;
  position: relative;
  padding-right: 2.5rem;
  line-height: 1.2; }
  .select-box select::-ms-expand {
    display: none;
    /*Removes default IE style*/ }

.select-box .tall {
  height: 51px; }

input[type="checkbox"] + label {
  display: block;
  position: relative;
  font-weight: normal;
  float: none;
  vertical-align: middle;
  margin: 0 0 0.625rem 0;
  padding: 0 0 0 1.875rem; }
  input[type="checkbox"] + label:before {
    content: '';
    border: 1px solid #fff;
    border-radius: 4px;
    display: inline-block;
    width: 1.4em;
    height: 1.4em;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border 0.2s ease-in-out; }
  input[type="checkbox"] + label:after {
    content: none;
    position: absolute;
    font-size: 12px;
    left: 8px;
    top: 4px;
    transition: color 0.2s ease-in-out; }
  input[type="checkbox"] + label:hover:before, input[type="checkbox"] + label:focus:before {
    background: url("../assets/images/icons/tick.svg") center center no-repeat; }

input[type="checkbox"]:checked + label:before {
  background: #fff url("../assets/images/icons/tick.svg") center center no-repeat; }

.fieldset__range label {
  margin-bottom: 1.875rem; }

.fieldset__range {
  margin: 1.5625rem auto; }

.range {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  width: calc(100% - 80px);
  margin-left: 2.1875rem;
  background-color: #286542;
  position: relative;
  height: 2px;
  font-weight: 700;
  color: #000000; }
  .range:before, .range:after {
    position: absolute;
    top: 50%;
    font-size: 0.9rem; }
  .range:before {
    content: attr(data-min);
    left: 0;
    width: 35px;
    transform: translate(-100%, -50%);
    margin-right: 0.625rem; }
  .range:after {
    content: attr(data-max);
    left: 100%;
    transform: translate(0%, -50%);
    margin-left: 0.625rem; }

.range__track {
  position: relative;
  background-color: #6DA527;
  height: 2px; }

.range__thumb {
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
  border-radius: 100%;
  width: 15px;
  height: 15px;
  background-color: #6DA527;
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out; }
  .range__thumb:hover, .range__thumb:focus, .range__thumb:active,
  .range__thumb .range__thumb--moving {
    background-color: #689d25;
    backdrop-filter: blur(50px);
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.5); }
    .range__thumb:hover .range__tooltip, .range__thumb:focus .range__tooltip, .range__thumb:active .range__tooltip,
    .range__thumb .range__thumb--moving .range__tooltip {
      opacity: 1;
      visibility: visible;
      transition-delay: 0s;
      transform: translate(-50%, -0.625rem); }

.no-js .range__thumb {
  display: none; }

.range__tooltip {
  font-weight: 100;
  background-color: #286542;
  display: inline-block;
  padding: 0.625rem;
  white-space: nowrap;
  border-radius: 4px;
  position: absolute;
  bottom: 100%;
  opacity: 0;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  visibility: hidden;
  transition: 0.2s ease-out;
  transition-property: transform opacity visibility;
  left: 50%;
  transform: translate(-50%, 0); }
  .range__tooltip:before {
    content: '';
    border-left: solid transparent 7px;
    border-right: solid transparent 7px;
    border-top: solid #286542 7px;
    top: 100%;
    height: 0;
    position: absolute;
    width: 0;
    left: 50%;
    transform: translate(-50%, 0); }

.range__face--moving .range__tooltip {
  transform: translate(-50%, 0);
  opacity: 1;
  visibility: visible;
  transition-delay: 0s; }

.range__value {
  vertical-align: middle;
  display: inline-block;
  margin-top: -1px; }

@media screen and (min-width: 60rem) {
  body:after {
    content: "60rem"; }
  textarea,
  input {
    line-height: 1; }
  .fieldset__row > fieldset {
    width: 50%;
    float: left; } }

.button,
button,
.btn {
  font-weight: 500;
  display: inline-block;
  width: auto;
  color: #FFF;
  font-size: .9375rem;
  padding: 1rem;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  transition: 0.2s ease-in-out !important;
  transition-property: background-color box-shadow color transform border-radius border-color;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-right: 0.625rem;
  margin-top: 0.625rem;
  line-height: 1.2;
  text-transform: uppercase;
  will-change: transform;
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
  letter-spacing: .1em; }
  .button:after,
  button:after,
  .btn:after {
    content: none; }
  .button:before,
  button:before,
  .btn:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    padding-bottom: 100%;
    transform: scale(0) translate(-50%, -50%);
    transform-origin: 0 0;
    background-color: #6DA527;
    left: 50%;
    top: 50%;
    border-radius: 100%;
    transition: transform 0.2s ease-in-out;
    z-index: -1; }
  .button:focus, .button:active, .button:hover,
  button:focus,
  button:active,
  button:hover,
  .btn:focus,
  .btn:active,
  .btn:hover {
    background-color: #6DA527;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
    color: #fff;
    transform: scale(1) rotate(0) translateZ(0);
    fill: currentColor;
    -webkit-font-smoothing: antialiased; }
    .button:focus:before, .button:active:before, .button:hover:before,
    button:focus:before,
    button:active:before,
    button:hover:before,
    .btn:focus:before,
    .btn:active:before,
    .btn:hover:before {
      transform: scale(1) translate(-50%, -50%); }

.btn .icon {
  fill: currentColor; }

button,
.btn--primary {
  background-color: #286542; }
  button:focus, button:active, button:hover,
  .btn--primary:focus,
  .btn--primary:active,
  .btn--primary:hover {
    color: #fff; }

.btn--secondary {
  background-color: #fff;
  color: #286542; }
  .btn--secondary .icon {
    fill: #286542; }
  .btn--secondary:before {
    background-color: #286542; }
  .btn--secondary:focus, .btn--secondary:active, .btn--secondary:hover {
    background-color: #286542; }
    .btn--secondary:focus .icon, .btn--secondary:active .icon, .btn--secondary:hover .icon {
      fill: #FFF; }

.btn--tertiary {
  border: 1px solid #fff;
  color: #fff;
  fill: #fff;
  background-color: transparent;
  text-transform: none;
  padding: 0.625rem;
  vertical-align: middle; }
  .btn--tertiary svg {
    width: 20px;
    height: 20px; }
  .btn--tertiary:hover, .btn--tertiary:active, .btn--tertiary:focus {
    border: 1px solid #6DA527; }

.btn--ghost {
  border: 1px solid #6DA527;
  color: #6DA527;
  fill: currentColor;
  background-color: transparent;
  text-transform: none;
  padding: 0.625rem;
  vertical-align: middle; }
  .btn--ghost svg {
    width: 20px;
    height: 20px; }
  .btn--ghost:focus, .btn--ghost:active, .btn--ghost:hover {
    color: #fff;
    background-color: #6DA527; }

.btn--wide {
  font-weight: 500;
  width: 100%;
  display: block;
  text-align: center; }
  .btn--wide:before {
    content: none; }
  .btn--wide:hover, .btn--wide:active, .btn--wide:focus {
    transform: scale(1) rotate(0) translateZ(0); }

.btn--pulse {
  width: 30px;
  height: 30px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center; }
  .btn--pulse:hover:before, .btn--pulse:hover:after, .btn--pulse:focus:before, .btn--pulse:focus:after, .btn--pulse:active:before, .btn--pulse:active:after {
    content: '';
    border: 4px solid #fff;
    border-radius: 100%;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation: pulsate 1.2s infinite ease-out;
    opacity: 0; }
  .btn--pulse:hover:after, .btn--pulse:focus:after, .btn--pulse:active:after {
    animation: pulsate 1.2s 0.8s infinite ease-out; }

.btn--lazy-loader {
  margin: 0 auto;
  display: flex;
  margin-top: 1.25rem; }

@keyframes pulsate {
  0% {
    transform: scale(0.8);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    transform: scale(1.8);
    opacity: 0;
    border: 0 solid #fff; } }

.btn--arrow {
  background-image: url("../assets/images/white-sketch-arrow.png");
  height: 57px;
  width: 83px;
  transition: transform 0.2s ease-in-out, bottom 0.2s ease-in-out; }
  .btn--arrow:hover, .btn--arrow:focus, .btn--arrow:active {
    transform: translateY(20%) rotate(5deg); }

@media screen and (min-width: 60rem) {
  body:after {
    content: "60rem"; }
  button:hover, button:active, button:focus,
  .btn:hover,
  .btn:active,
  .btn:focus {
    transform: scale(1.1, 1.1) rotate(-2deg) translateZ(0); }
    button:hover:before, button:active:before, button:focus:before,
    .btn:hover:before,
    .btn:active:before,
    .btn:focus:before {
      transform: scale(1.5) translate(-50%, -50%); }
  .btn--wide:hover, .btn--wide:active, .btn--wide:focus {
    transform: scale(1) rotate(0) translateZ(0); } }

.image__leafs {
  display: none; }

.image--large {
  width: 300px; }

@media only screen and (min-width: 48rem) {
  .image__leafs {
    display: block;
    position: absolute;
    z-index: 5;
    width: 200px; } }

[class*='grid--col'],
.grid--fill {
  display: flex;
  margin: 0; }

[class*='grid--col'] {
  flex-wrap: wrap; }

[class*='grid--col'] > * {
  margin: 0; }

.grid--fill > * {
  margin: 0;
  width: 100%; }

.footer__grid--col-max-3 > * {
  max-width: 50%;
  flex-basis: 50%; }

.grid--col-max-2 > *,
.grid--col-max-3 > *,
.grid--col-max-4 > * {
  max-width: 100%;
  flex-basis: 100%; }

.recipe-overview__grid--col-max-4 > * {
  max-width: 50%;
  flex-basis: 50%; }

.grid--stretch-0x {
  margin: 0rem; }

.grid--stretch-h-0x {
  margin-left: 0rem;
  margin-right: 0rem; }

.grid--stretch-v-0x {
  margin-top: 0rem;
  margin-bottom: 0rem; }

.grid--stretch-t-0x {
  margin-top: 0rem; }

.grid--stretch-r-0x {
  margin-right: 0rem; }

.grid--stretch-b-0x {
  margin-bottom: 0rem; }

.grid--stretch-l-0x {
  margin-left: 0rem; }

.grid--stretch-1x {
  margin: -0.625rem; }

.grid--stretch-h-1x {
  margin-left: -0.625rem;
  margin-right: -0.625rem; }

.grid--stretch-v-1x {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem; }

.grid--stretch-t-1x {
  margin-top: -0.625rem; }

.grid--stretch-r-1x {
  margin-right: -0.625rem; }

.grid--stretch-b-1x {
  margin-bottom: -0.625rem; }

.grid--stretch-l-1x {
  margin-left: -0.625rem; }

.grid--stretch-2x {
  margin: -1.25rem; }

.grid--stretch-h-2x {
  margin-left: -1.25rem;
  margin-right: -1.25rem; }

.grid--stretch-v-2x {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem; }

.grid--stretch-t-2x {
  margin-top: -1.25rem; }

.grid--stretch-r-2x {
  margin-right: -1.25rem; }

.grid--stretch-b-2x {
  margin-bottom: -1.25rem; }

.grid--stretch-l-2x {
  margin-left: -1.25rem; }

.grid--stretch-3x {
  margin: -1.875rem; }

.grid--stretch-h-3x {
  margin-left: -1.875rem;
  margin-right: -1.875rem; }

.grid--stretch-v-3x {
  margin-top: -1.875rem;
  margin-bottom: -1.875rem; }

.grid--stretch-t-3x {
  margin-top: -1.875rem; }

.grid--stretch-r-3x {
  margin-right: -1.875rem; }

.grid--stretch-b-3x {
  margin-bottom: -1.875rem; }

.grid--stretch-l-3x {
  margin-left: -1.875rem; }

.grid--stretch-4x {
  margin: -2.5rem; }

.grid--stretch-h-4x {
  margin-left: -2.5rem;
  margin-right: -2.5rem; }

.grid--stretch-v-4x {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem; }

.grid--stretch-t-4x {
  margin-top: -2.5rem; }

.grid--stretch-r-4x {
  margin-right: -2.5rem; }

.grid--stretch-b-4x {
  margin-bottom: -2.5rem; }

.grid--stretch-l-4x {
  margin-left: -2.5rem; }

.grid--stretch-5x {
  margin: -3.125rem; }

.grid--stretch-h-5x {
  margin-left: -3.125rem;
  margin-right: -3.125rem; }

.grid--stretch-v-5x {
  margin-top: -3.125rem;
  margin-bottom: -3.125rem; }

.grid--stretch-t-5x {
  margin-top: -3.125rem; }

.grid--stretch-r-5x {
  margin-right: -3.125rem; }

.grid--stretch-b-5x {
  margin-bottom: -3.125rem; }

.grid--stretch-l-5x {
  margin-left: -3.125rem; }

.grid--stretch-6x {
  margin: -3.75rem; }

.grid--stretch-h-6x {
  margin-left: -3.75rem;
  margin-right: -3.75rem; }

.grid--stretch-v-6x {
  margin-top: -3.75rem;
  margin-bottom: -3.75rem; }

.grid--stretch-t-6x {
  margin-top: -3.75rem; }

.grid--stretch-r-6x {
  margin-right: -3.75rem; }

.grid--stretch-b-6x {
  margin-bottom: -3.75rem; }

.grid--stretch-l-6x {
  margin-left: -3.75rem; }

.grid--stretch-7x {
  margin: -4.375rem; }

.grid--stretch-h-7x {
  margin-left: -4.375rem;
  margin-right: -4.375rem; }

.grid--stretch-v-7x {
  margin-top: -4.375rem;
  margin-bottom: -4.375rem; }

.grid--stretch-t-7x {
  margin-top: -4.375rem; }

.grid--stretch-r-7x {
  margin-right: -4.375rem; }

.grid--stretch-b-7x {
  margin-bottom: -4.375rem; }

.grid--stretch-l-7x {
  margin-left: -4.375rem; }

.grid--stretch-8x {
  margin: -5rem; }

.grid--stretch-h-8x {
  margin-left: -5rem;
  margin-right: -5rem; }

.grid--stretch-v-8x {
  margin-top: -5rem;
  margin-bottom: -5rem; }

.grid--stretch-t-8x {
  margin-top: -5rem; }

.grid--stretch-r-8x {
  margin-right: -5rem; }

.grid--stretch-b-8x {
  margin-bottom: -5rem; }

.grid--stretch-l-8x {
  margin-left: -5rem; }

.grid--stretch-9x {
  margin: -5.625rem; }

.grid--stretch-h-9x {
  margin-left: -5.625rem;
  margin-right: -5.625rem; }

.grid--stretch-v-9x {
  margin-top: -5.625rem;
  margin-bottom: -5.625rem; }

.grid--stretch-t-9x {
  margin-top: -5.625rem; }

.grid--stretch-r-9x {
  margin-right: -5.625rem; }

.grid--stretch-b-9x {
  margin-bottom: -5.625rem; }

.grid--stretch-l-9x {
  margin-left: -5.625rem; }

.grid--stretch-10x {
  margin: -6.25rem; }

.grid--stretch-h-10x {
  margin-left: -6.25rem;
  margin-right: -6.25rem; }

.grid--stretch-v-10x {
  margin-top: -6.25rem;
  margin-bottom: -6.25rem; }

.grid--stretch-t-10x {
  margin-top: -6.25rem; }

.grid--stretch-r-10x {
  margin-right: -6.25rem; }

.grid--stretch-b-10x {
  margin-bottom: -6.25rem; }

.grid--stretch-l-10x {
  margin-left: -6.25rem; }

.grid--stretch-11x {
  margin: -6.875rem; }

.grid--stretch-h-11x {
  margin-left: -6.875rem;
  margin-right: -6.875rem; }

.grid--stretch-v-11x {
  margin-top: -6.875rem;
  margin-bottom: -6.875rem; }

.grid--stretch-t-11x {
  margin-top: -6.875rem; }

.grid--stretch-r-11x {
  margin-right: -6.875rem; }

.grid--stretch-b-11x {
  margin-bottom: -6.875rem; }

.grid--stretch-l-11x {
  margin-left: -6.875rem; }

.grid--stretch-12x {
  margin: -7.5rem; }

.grid--stretch-h-12x {
  margin-left: -7.5rem;
  margin-right: -7.5rem; }

.grid--stretch-v-12x {
  margin-top: -7.5rem;
  margin-bottom: -7.5rem; }

.grid--stretch-t-12x {
  margin-top: -7.5rem; }

.grid--stretch-r-12x {
  margin-right: -7.5rem; }

.grid--stretch-b-12x {
  margin-bottom: -7.5rem; }

.grid--stretch-l-12x {
  margin-left: -7.5rem; }

.grid--stretch-13x {
  margin: -8.125rem; }

.grid--stretch-h-13x {
  margin-left: -8.125rem;
  margin-right: -8.125rem; }

.grid--stretch-v-13x {
  margin-top: -8.125rem;
  margin-bottom: -8.125rem; }

.grid--stretch-t-13x {
  margin-top: -8.125rem; }

.grid--stretch-r-13x {
  margin-right: -8.125rem; }

.grid--stretch-b-13x {
  margin-bottom: -8.125rem; }

.grid--stretch-l-13x {
  margin-left: -8.125rem; }

.grid--stretch-14x {
  margin: -8.75rem; }

.grid--stretch-h-14x {
  margin-left: -8.75rem;
  margin-right: -8.75rem; }

.grid--stretch-v-14x {
  margin-top: -8.75rem;
  margin-bottom: -8.75rem; }

.grid--stretch-t-14x {
  margin-top: -8.75rem; }

.grid--stretch-r-14x {
  margin-right: -8.75rem; }

.grid--stretch-b-14x {
  margin-bottom: -8.75rem; }

.grid--stretch-l-14x {
  margin-left: -8.75rem; }

.grid--stretch-15x {
  margin: -9.375rem; }

.grid--stretch-h-15x {
  margin-left: -9.375rem;
  margin-right: -9.375rem; }

.grid--stretch-v-15x {
  margin-top: -9.375rem;
  margin-bottom: -9.375rem; }

.grid--stretch-t-15x {
  margin-top: -9.375rem; }

.grid--stretch-r-15x {
  margin-right: -9.375rem; }

.grid--stretch-b-15x {
  margin-bottom: -9.375rem; }

.grid--stretch-l-15x {
  margin-left: -9.375rem; }

.col-1\/2 {
  max-width: 100%;
  flex-basis: 100%; }

.col-2\/2 {
  max-width: 100%;
  flex-basis: 100%; }

.col-3\/2 {
  max-width: 100%;
  flex-basis: 100%; }

.col-4\/2 {
  max-width: 100%;
  flex-basis: 100%; }

.col-5\/2 {
  max-width: 100%;
  flex-basis: 100%; }

.col-6\/2 {
  max-width: 100%;
  flex-basis: 100%; }

.col-1\/3 {
  max-width: 100%;
  flex-basis: 100%; }

.col-2\/3 {
  max-width: 100%;
  flex-basis: 100%; }

.col-3\/3 {
  max-width: 100%;
  flex-basis: 100%; }

.col-4\/3 {
  max-width: 100%;
  flex-basis: 100%; }

.col-5\/3 {
  max-width: 100%;
  flex-basis: 100%; }

.col-6\/3 {
  max-width: 100%;
  flex-basis: 100%; }

.col-1\/4 {
  max-width: 100%;
  flex-basis: 100%; }

.col-2\/4 {
  max-width: 100%;
  flex-basis: 100%; }

.col-3\/4 {
  max-width: 100%;
  flex-basis: 100%; }

.col-4\/4 {
  max-width: 100%;
  flex-basis: 100%; }

.col-5\/4 {
  max-width: 100%;
  flex-basis: 100%; }

.col-6\/4 {
  max-width: 100%;
  flex-basis: 100%; }

.col-1\/5 {
  max-width: 100%;
  flex-basis: 100%; }

.col-2\/5 {
  max-width: 100%;
  flex-basis: 100%; }

.col-3\/5 {
  max-width: 100%;
  flex-basis: 100%; }

.col-4\/5 {
  max-width: 100%;
  flex-basis: 100%; }

.col-5\/5 {
  max-width: 100%;
  flex-basis: 100%; }

.col-6\/5 {
  max-width: 100%;
  flex-basis: 100%; }

.col-1\/6 {
  max-width: 100%;
  flex-basis: 100%; }

.col-2\/6 {
  max-width: 100%;
  flex-basis: 100%; }

.col-3\/6 {
  max-width: 100%;
  flex-basis: 100%; }

.col-4\/6 {
  max-width: 100%;
  flex-basis: 100%; }

.col-5\/6 {
  max-width: 100%;
  flex-basis: 100%; }

.col-6\/6 {
  max-width: 100%;
  flex-basis: 100%; }

@media screen and (min-width: 30rem) {
  .grid--col-max-3 > *,
  .grid--col-max-4 > * {
    max-width: 50%;
    flex-basis: 50%; }
  .footer__grid--col-max-3 > * {
    max-width: 33.333%;
    flex-basis: 33.333%; } }

@media screen and (min-width: 48rem) {
  .grid--col-max-4 > * {
    max-width: 33.333%;
    flex-basis: 33.333%; }
  .grid--col-max-2 > * {
    max-width: 50%;
    flex-basis: 50%; } }

@media screen and (min-width: 60rem) {
  .footer__grid--col-max-2 > * {
    max-width: 50%;
    flex-basis: 50%; }
  .grid--col-max-3 > * {
    max-width: 33.333%;
    flex-basis: 33.333%; }
  .grid--col-max-4 > * {
    max-width: 25%;
    flex-basis: 25%; }
  .col-1\/2 {
    max-width: 50%;
    flex-basis: 50%; }
  .col-2\/2 {
    max-width: 100%;
    flex-basis: 100%; }
  .col-3\/2 {
    max-width: 150%;
    flex-basis: 150%; }
  .col-4\/2 {
    max-width: 200%;
    flex-basis: 200%; }
  .col-5\/2 {
    max-width: 250%;
    flex-basis: 250%; }
  .col-6\/2 {
    max-width: 300%;
    flex-basis: 300%; }
  .col-1\/3 {
    max-width: 33.33333%;
    flex-basis: 33.33333%; }
  .col-2\/3 {
    max-width: 66.66667%;
    flex-basis: 66.66667%; }
  .col-3\/3 {
    max-width: 100%;
    flex-basis: 100%; }
  .col-4\/3 {
    max-width: 133.33333%;
    flex-basis: 133.33333%; }
  .col-5\/3 {
    max-width: 166.66667%;
    flex-basis: 166.66667%; }
  .col-6\/3 {
    max-width: 200%;
    flex-basis: 200%; }
  .col-1\/4 {
    max-width: 25%;
    flex-basis: 25%; }
  .col-2\/4 {
    max-width: 50%;
    flex-basis: 50%; }
  .col-3\/4 {
    max-width: 75%;
    flex-basis: 75%; }
  .col-4\/4 {
    max-width: 100%;
    flex-basis: 100%; }
  .col-5\/4 {
    max-width: 125%;
    flex-basis: 125%; }
  .col-6\/4 {
    max-width: 150%;
    flex-basis: 150%; }
  .col-1\/5 {
    max-width: 20%;
    flex-basis: 20%; }
  .col-2\/5 {
    max-width: 40%;
    flex-basis: 40%; }
  .col-3\/5 {
    max-width: 60%;
    flex-basis: 60%; }
  .col-4\/5 {
    max-width: 80%;
    flex-basis: 80%; }
  .col-5\/5 {
    max-width: 100%;
    flex-basis: 100%; }
  .col-6\/5 {
    max-width: 120%;
    flex-basis: 120%; }
  .col-1\/6 {
    max-width: 16.66667%;
    flex-basis: 16.66667%; }
  .col-2\/6 {
    max-width: 33.33333%;
    flex-basis: 33.33333%; }
  .col-3\/6 {
    max-width: 50%;
    flex-basis: 50%; }
  .col-4\/6 {
    max-width: 66.66667%;
    flex-basis: 66.66667%; }
  .col-5\/6 {
    max-width: 83.33333%;
    flex-basis: 83.33333%; }
  .col-6\/6 {
    max-width: 100%;
    flex-basis: 100%; }
  .grid--stretch-0x\@desktop {
    margin-left: 0rem;
    margin-right: 0rem; }
  .grid--stretch-h-0x\@desktop {
    margin-left: 0rem;
    margin-right: 0rem; }
  .grid--stretch-v-0x\@desktop {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .grid--stretch-t-0x\@desktop {
    margin-top: 0rem; }
  .grid--stretch-r-0x\@desktop {
    margin-right: 0rem; }
  .grid--stretch-b-0x\@desktop {
    margin-bottom: 0rem; }
  .grid--stretch-l-0x\@desktop {
    margin-left: 0rem; }
  .grid--stretch-1x\@desktop {
    margin-left: -0.625rem;
    margin-right: -0.625rem; }
  .grid--stretch-h-1x\@desktop {
    margin-left: -0.625rem;
    margin-right: -0.625rem; }
  .grid--stretch-v-1x\@desktop {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem; }
  .grid--stretch-t-1x\@desktop {
    margin-top: -0.625rem; }
  .grid--stretch-r-1x\@desktop {
    margin-right: -0.625rem; }
  .grid--stretch-b-1x\@desktop {
    margin-bottom: -0.625rem; }
  .grid--stretch-l-1x\@desktop {
    margin-left: -0.625rem; }
  .grid--stretch-2x\@desktop {
    margin-left: -1.25rem;
    margin-right: -1.25rem; }
  .grid--stretch-h-2x\@desktop {
    margin-left: -1.25rem;
    margin-right: -1.25rem; }
  .grid--stretch-v-2x\@desktop {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem; }
  .grid--stretch-t-2x\@desktop {
    margin-top: -1.25rem; }
  .grid--stretch-r-2x\@desktop {
    margin-right: -1.25rem; }
  .grid--stretch-b-2x\@desktop {
    margin-bottom: -1.25rem; }
  .grid--stretch-l-2x\@desktop {
    margin-left: -1.25rem; }
  .grid--stretch-3x\@desktop {
    margin-left: -1.875rem;
    margin-right: -1.875rem; }
  .grid--stretch-h-3x\@desktop {
    margin-left: -1.875rem;
    margin-right: -1.875rem; }
  .grid--stretch-v-3x\@desktop {
    margin-top: -1.875rem;
    margin-bottom: -1.875rem; }
  .grid--stretch-t-3x\@desktop {
    margin-top: -1.875rem; }
  .grid--stretch-r-3x\@desktop {
    margin-right: -1.875rem; }
  .grid--stretch-b-3x\@desktop {
    margin-bottom: -1.875rem; }
  .grid--stretch-l-3x\@desktop {
    margin-left: -1.875rem; }
  .grid--stretch-4x\@desktop {
    margin-left: -2.5rem;
    margin-right: -2.5rem; }
  .grid--stretch-h-4x\@desktop {
    margin-left: -2.5rem;
    margin-right: -2.5rem; }
  .grid--stretch-v-4x\@desktop {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem; }
  .grid--stretch-t-4x\@desktop {
    margin-top: -2.5rem; }
  .grid--stretch-r-4x\@desktop {
    margin-right: -2.5rem; }
  .grid--stretch-b-4x\@desktop {
    margin-bottom: -2.5rem; }
  .grid--stretch-l-4x\@desktop {
    margin-left: -2.5rem; }
  .grid--stretch-5x\@desktop {
    margin-left: -3.125rem;
    margin-right: -3.125rem; }
  .grid--stretch-h-5x\@desktop {
    margin-left: -3.125rem;
    margin-right: -3.125rem; }
  .grid--stretch-v-5x\@desktop {
    margin-top: -3.125rem;
    margin-bottom: -3.125rem; }
  .grid--stretch-t-5x\@desktop {
    margin-top: -3.125rem; }
  .grid--stretch-r-5x\@desktop {
    margin-right: -3.125rem; }
  .grid--stretch-b-5x\@desktop {
    margin-bottom: -3.125rem; }
  .grid--stretch-l-5x\@desktop {
    margin-left: -3.125rem; }
  .grid--stretch-6x\@desktop {
    margin-left: -3.75rem;
    margin-right: -3.75rem; }
  .grid--stretch-h-6x\@desktop {
    margin-left: -3.75rem;
    margin-right: -3.75rem; }
  .grid--stretch-v-6x\@desktop {
    margin-top: -3.75rem;
    margin-bottom: -3.75rem; }
  .grid--stretch-t-6x\@desktop {
    margin-top: -3.75rem; }
  .grid--stretch-r-6x\@desktop {
    margin-right: -3.75rem; }
  .grid--stretch-b-6x\@desktop {
    margin-bottom: -3.75rem; }
  .grid--stretch-l-6x\@desktop {
    margin-left: -3.75rem; }
  .grid--stretch-7x\@desktop {
    margin-left: -4.375rem;
    margin-right: -4.375rem; }
  .grid--stretch-h-7x\@desktop {
    margin-left: -4.375rem;
    margin-right: -4.375rem; }
  .grid--stretch-v-7x\@desktop {
    margin-top: -4.375rem;
    margin-bottom: -4.375rem; }
  .grid--stretch-t-7x\@desktop {
    margin-top: -4.375rem; }
  .grid--stretch-r-7x\@desktop {
    margin-right: -4.375rem; }
  .grid--stretch-b-7x\@desktop {
    margin-bottom: -4.375rem; }
  .grid--stretch-l-7x\@desktop {
    margin-left: -4.375rem; }
  .grid--stretch-8x\@desktop {
    margin-left: -5rem;
    margin-right: -5rem; }
  .grid--stretch-h-8x\@desktop {
    margin-left: -5rem;
    margin-right: -5rem; }
  .grid--stretch-v-8x\@desktop {
    margin-top: -5rem;
    margin-bottom: -5rem; }
  .grid--stretch-t-8x\@desktop {
    margin-top: -5rem; }
  .grid--stretch-r-8x\@desktop {
    margin-right: -5rem; }
  .grid--stretch-b-8x\@desktop {
    margin-bottom: -5rem; }
  .grid--stretch-l-8x\@desktop {
    margin-left: -5rem; }
  .grid--stretch-9x\@desktop {
    margin-left: -5.625rem;
    margin-right: -5.625rem; }
  .grid--stretch-h-9x\@desktop {
    margin-left: -5.625rem;
    margin-right: -5.625rem; }
  .grid--stretch-v-9x\@desktop {
    margin-top: -5.625rem;
    margin-bottom: -5.625rem; }
  .grid--stretch-t-9x\@desktop {
    margin-top: -5.625rem; }
  .grid--stretch-r-9x\@desktop {
    margin-right: -5.625rem; }
  .grid--stretch-b-9x\@desktop {
    margin-bottom: -5.625rem; }
  .grid--stretch-l-9x\@desktop {
    margin-left: -5.625rem; }
  .grid--stretch-10x\@desktop {
    margin-left: -6.25rem;
    margin-right: -6.25rem; }
  .grid--stretch-h-10x\@desktop {
    margin-left: -6.25rem;
    margin-right: -6.25rem; }
  .grid--stretch-v-10x\@desktop {
    margin-top: -6.25rem;
    margin-bottom: -6.25rem; }
  .grid--stretch-t-10x\@desktop {
    margin-top: -6.25rem; }
  .grid--stretch-r-10x\@desktop {
    margin-right: -6.25rem; }
  .grid--stretch-b-10x\@desktop {
    margin-bottom: -6.25rem; }
  .grid--stretch-l-10x\@desktop {
    margin-left: -6.25rem; }
  .grid--stretch-11x\@desktop {
    margin-left: -6.875rem;
    margin-right: -6.875rem; }
  .grid--stretch-h-11x\@desktop {
    margin-left: -6.875rem;
    margin-right: -6.875rem; }
  .grid--stretch-v-11x\@desktop {
    margin-top: -6.875rem;
    margin-bottom: -6.875rem; }
  .grid--stretch-t-11x\@desktop {
    margin-top: -6.875rem; }
  .grid--stretch-r-11x\@desktop {
    margin-right: -6.875rem; }
  .grid--stretch-b-11x\@desktop {
    margin-bottom: -6.875rem; }
  .grid--stretch-l-11x\@desktop {
    margin-left: -6.875rem; }
  .grid--stretch-12x\@desktop {
    margin-left: -7.5rem;
    margin-right: -7.5rem; }
  .grid--stretch-h-12x\@desktop {
    margin-left: -7.5rem;
    margin-right: -7.5rem; }
  .grid--stretch-v-12x\@desktop {
    margin-top: -7.5rem;
    margin-bottom: -7.5rem; }
  .grid--stretch-t-12x\@desktop {
    margin-top: -7.5rem; }
  .grid--stretch-r-12x\@desktop {
    margin-right: -7.5rem; }
  .grid--stretch-b-12x\@desktop {
    margin-bottom: -7.5rem; }
  .grid--stretch-l-12x\@desktop {
    margin-left: -7.5rem; }
  .grid--stretch-13x\@desktop {
    margin-left: -8.125rem;
    margin-right: -8.125rem; }
  .grid--stretch-h-13x\@desktop {
    margin-left: -8.125rem;
    margin-right: -8.125rem; }
  .grid--stretch-v-13x\@desktop {
    margin-top: -8.125rem;
    margin-bottom: -8.125rem; }
  .grid--stretch-t-13x\@desktop {
    margin-top: -8.125rem; }
  .grid--stretch-r-13x\@desktop {
    margin-right: -8.125rem; }
  .grid--stretch-b-13x\@desktop {
    margin-bottom: -8.125rem; }
  .grid--stretch-l-13x\@desktop {
    margin-left: -8.125rem; }
  .grid--stretch-14x\@desktop {
    margin-left: -8.75rem;
    margin-right: -8.75rem; }
  .grid--stretch-h-14x\@desktop {
    margin-left: -8.75rem;
    margin-right: -8.75rem; }
  .grid--stretch-v-14x\@desktop {
    margin-top: -8.75rem;
    margin-bottom: -8.75rem; }
  .grid--stretch-t-14x\@desktop {
    margin-top: -8.75rem; }
  .grid--stretch-r-14x\@desktop {
    margin-right: -8.75rem; }
  .grid--stretch-b-14x\@desktop {
    margin-bottom: -8.75rem; }
  .grid--stretch-l-14x\@desktop {
    margin-left: -8.75rem; }
  .grid--stretch-15x\@desktop {
    margin-left: -9.375rem;
    margin-right: -9.375rem; }
  .grid--stretch-h-15x\@desktop {
    margin-left: -9.375rem;
    margin-right: -9.375rem; }
  .grid--stretch-v-15x\@desktop {
    margin-top: -9.375rem;
    margin-bottom: -9.375rem; }
  .grid--stretch-t-15x\@desktop {
    margin-top: -9.375rem; }
  .grid--stretch-r-15x\@desktop {
    margin-right: -9.375rem; }
  .grid--stretch-b-15x\@desktop {
    margin-bottom: -9.375rem; }
  .grid--stretch-l-15x\@desktop {
    margin-left: -9.375rem; } }

.section {
  position: relative; }

.section--gapless {
  padding: 0; }

.section--gap-bottom {
  margin-bottom: 3.75rem; }

.section--fullscreen-bg {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: calc(100vh - 78px);
  background-position: top;
  background-attachment: fixed;
  padding-bottom: 2.5rem; }

.section__inner {
  width: 100%;
  padding: 0.625rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 90rem;
  position: relative; }

.section__inner--small {
  max-width: 39.5rem; }

.section__inner--medium {
  max-width: 47rem; }

.section__line-divided {
  margin: 2.5rem 0;
  width: 100%;
  height: 7px;
  background-image: url(../assets/images/icons/background-menu-underline-white.svg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% 7px; }

@media screen and (min-width: 60rem) {
  .section--fullscreen-bg {
    min-height: calc(100vh - 118px); } }

.content-box {
  padding: 0.625rem; }

.content-box--0x {
  padding: 0rem; }

.content-box--h-0x {
  padding-left: 0rem;
  padding-right: 0rem; }

.content-box--v-0x {
  padding-top: 0rem;
  padding-bottom: 0rem; }

.content-box--t-0x {
  padding-top: 0rem; }

.content-box--r-0x {
  padding-right: 0rem; }

.content-box--b-0x {
  padding-bottom: 0rem; }

.content-box--l-0x {
  padding-left: 0rem; }

.content-box--1x {
  padding: 0.625rem; }

.content-box--h-1x {
  padding-left: 0.625rem;
  padding-right: 0.625rem; }

.content-box--v-1x {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem; }

.content-box--t-1x {
  padding-top: 0.625rem; }

.content-box--r-1x {
  padding-right: 0.625rem; }

.content-box--b-1x {
  padding-bottom: 0.625rem; }

.content-box--l-1x {
  padding-left: 0.625rem; }

.content-box--2x {
  padding: 1.25rem; }

.content-box--h-2x {
  padding-left: 1.25rem;
  padding-right: 1.25rem; }

.content-box--v-2x {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem; }

.content-box--t-2x {
  padding-top: 1.25rem; }

.content-box--r-2x {
  padding-right: 1.25rem; }

.content-box--b-2x {
  padding-bottom: 1.25rem; }

.content-box--l-2x {
  padding-left: 1.25rem; }

.content-box--3x {
  padding: 1.875rem; }

.content-box--h-3x {
  padding-left: 1.875rem;
  padding-right: 1.875rem; }

.content-box--v-3x {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem; }

.content-box--t-3x {
  padding-top: 1.875rem; }

.content-box--r-3x {
  padding-right: 1.875rem; }

.content-box--b-3x {
  padding-bottom: 1.875rem; }

.content-box--l-3x {
  padding-left: 1.875rem; }

.content-box--4x {
  padding: 2.5rem; }

.content-box--h-4x {
  padding-left: 2.5rem;
  padding-right: 2.5rem; }

.content-box--v-4x {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem; }

.content-box--t-4x {
  padding-top: 2.5rem; }

.content-box--r-4x {
  padding-right: 2.5rem; }

.content-box--b-4x {
  padding-bottom: 2.5rem; }

.content-box--l-4x {
  padding-left: 2.5rem; }

.content-box--5x {
  padding: 3.125rem; }

.content-box--h-5x {
  padding-left: 3.125rem;
  padding-right: 3.125rem; }

.content-box--v-5x {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem; }

.content-box--t-5x {
  padding-top: 3.125rem; }

.content-box--r-5x {
  padding-right: 3.125rem; }

.content-box--b-5x {
  padding-bottom: 3.125rem; }

.content-box--l-5x {
  padding-left: 3.125rem; }

.content-box--6x {
  padding: 3.75rem; }

.content-box--h-6x {
  padding-left: 3.75rem;
  padding-right: 3.75rem; }

.content-box--v-6x {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem; }

.content-box--t-6x {
  padding-top: 3.75rem; }

.content-box--r-6x {
  padding-right: 3.75rem; }

.content-box--b-6x {
  padding-bottom: 3.75rem; }

.content-box--l-6x {
  padding-left: 3.75rem; }

.content-box--7x {
  padding: 4.375rem; }

.content-box--h-7x {
  padding-left: 4.375rem;
  padding-right: 4.375rem; }

.content-box--v-7x {
  padding-top: 4.375rem;
  padding-bottom: 4.375rem; }

.content-box--t-7x {
  padding-top: 4.375rem; }

.content-box--r-7x {
  padding-right: 4.375rem; }

.content-box--b-7x {
  padding-bottom: 4.375rem; }

.content-box--l-7x {
  padding-left: 4.375rem; }

.content-box--8x {
  padding: 5rem; }

.content-box--h-8x {
  padding-left: 5rem;
  padding-right: 5rem; }

.content-box--v-8x {
  padding-top: 5rem;
  padding-bottom: 5rem; }

.content-box--t-8x {
  padding-top: 5rem; }

.content-box--r-8x {
  padding-right: 5rem; }

.content-box--b-8x {
  padding-bottom: 5rem; }

.content-box--l-8x {
  padding-left: 5rem; }

.content-box--9x {
  padding: 5.625rem; }

.content-box--h-9x {
  padding-left: 5.625rem;
  padding-right: 5.625rem; }

.content-box--v-9x {
  padding-top: 5.625rem;
  padding-bottom: 5.625rem; }

.content-box--t-9x {
  padding-top: 5.625rem; }

.content-box--r-9x {
  padding-right: 5.625rem; }

.content-box--b-9x {
  padding-bottom: 5.625rem; }

.content-box--l-9x {
  padding-left: 5.625rem; }

.content-box--10x {
  padding: 6.25rem; }

.content-box--h-10x {
  padding-left: 6.25rem;
  padding-right: 6.25rem; }

.content-box--v-10x {
  padding-top: 6.25rem;
  padding-bottom: 6.25rem; }

.content-box--t-10x {
  padding-top: 6.25rem; }

.content-box--r-10x {
  padding-right: 6.25rem; }

.content-box--b-10x {
  padding-bottom: 6.25rem; }

.content-box--l-10x {
  padding-left: 6.25rem; }

.content-box--11x {
  padding: 6.875rem; }

.content-box--h-11x {
  padding-left: 6.875rem;
  padding-right: 6.875rem; }

.content-box--v-11x {
  padding-top: 6.875rem;
  padding-bottom: 6.875rem; }

.content-box--t-11x {
  padding-top: 6.875rem; }

.content-box--r-11x {
  padding-right: 6.875rem; }

.content-box--b-11x {
  padding-bottom: 6.875rem; }

.content-box--l-11x {
  padding-left: 6.875rem; }

.content-box--12x {
  padding: 7.5rem; }

.content-box--h-12x {
  padding-left: 7.5rem;
  padding-right: 7.5rem; }

.content-box--v-12x {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem; }

.content-box--t-12x {
  padding-top: 7.5rem; }

.content-box--r-12x {
  padding-right: 7.5rem; }

.content-box--b-12x {
  padding-bottom: 7.5rem; }

.content-box--l-12x {
  padding-left: 7.5rem; }

.content-box--13x {
  padding: 8.125rem; }

.content-box--h-13x {
  padding-left: 8.125rem;
  padding-right: 8.125rem; }

.content-box--v-13x {
  padding-top: 8.125rem;
  padding-bottom: 8.125rem; }

.content-box--t-13x {
  padding-top: 8.125rem; }

.content-box--r-13x {
  padding-right: 8.125rem; }

.content-box--b-13x {
  padding-bottom: 8.125rem; }

.content-box--l-13x {
  padding-left: 8.125rem; }

.content-box--14x {
  padding: 8.75rem; }

.content-box--h-14x {
  padding-left: 8.75rem;
  padding-right: 8.75rem; }

.content-box--v-14x {
  padding-top: 8.75rem;
  padding-bottom: 8.75rem; }

.content-box--t-14x {
  padding-top: 8.75rem; }

.content-box--r-14x {
  padding-right: 8.75rem; }

.content-box--b-14x {
  padding-bottom: 8.75rem; }

.content-box--l-14x {
  padding-left: 8.75rem; }

.content-box--15x {
  padding: 9.375rem; }

.content-box--h-15x {
  padding-left: 9.375rem;
  padding-right: 9.375rem; }

.content-box--v-15x {
  padding-top: 9.375rem;
  padding-bottom: 9.375rem; }

.content-box--t-15x {
  padding-top: 9.375rem; }

.content-box--r-15x {
  padding-right: 9.375rem; }

.content-box--b-15x {
  padding-bottom: 9.375rem; }

.content-box--l-15x {
  padding-left: 9.375rem; }

@media screen and (min-width: 48rem) {
  .content-box\@desktop {
    padding: 0.625rem; }
  .content-box--0x\@desktop {
    padding: 0rem; }
  .content-box--h-0x\@desktop {
    padding-left: 0rem;
    padding-right: 0rem; }
  .content-box--v-0x\@desktop {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .content-box--t-0x\@desktop {
    padding-top: 0rem; }
  .content-box--r-0x\@desktop {
    padding-right: 0rem; }
  .content-box--b-0x\@desktop {
    padding-bottom: 0rem; }
  .content-box--l-0x\@desktop {
    padding-left: 0rem; }
  .content-box--1x\@desktop {
    padding: 0.625rem; }
  .content-box--h-1x\@desktop {
    padding-left: 0.625rem;
    padding-right: 0.625rem; }
  .content-box--v-1x\@desktop {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem; }
  .content-box--t-1x\@desktop {
    padding-top: 0.625rem; }
  .content-box--r-1x\@desktop {
    padding-right: 0.625rem; }
  .content-box--b-1x\@desktop {
    padding-bottom: 0.625rem; }
  .content-box--l-1x\@desktop {
    padding-left: 0.625rem; }
  .content-box--2x\@desktop {
    padding: 1.25rem; }
  .content-box--h-2x\@desktop {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .content-box--v-2x\@desktop {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .content-box--t-2x\@desktop {
    padding-top: 1.25rem; }
  .content-box--r-2x\@desktop {
    padding-right: 1.25rem; }
  .content-box--b-2x\@desktop {
    padding-bottom: 1.25rem; }
  .content-box--l-2x\@desktop {
    padding-left: 1.25rem; }
  .content-box--3x\@desktop {
    padding: 1.875rem; }
  .content-box--h-3x\@desktop {
    padding-left: 1.875rem;
    padding-right: 1.875rem; }
  .content-box--v-3x\@desktop {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem; }
  .content-box--t-3x\@desktop {
    padding-top: 1.875rem; }
  .content-box--r-3x\@desktop {
    padding-right: 1.875rem; }
  .content-box--b-3x\@desktop {
    padding-bottom: 1.875rem; }
  .content-box--l-3x\@desktop {
    padding-left: 1.875rem; }
  .content-box--4x\@desktop {
    padding: 2.5rem; }
  .content-box--h-4x\@desktop {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .content-box--v-4x\@desktop {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .content-box--t-4x\@desktop {
    padding-top: 2.5rem; }
  .content-box--r-4x\@desktop {
    padding-right: 2.5rem; }
  .content-box--b-4x\@desktop {
    padding-bottom: 2.5rem; }
  .content-box--l-4x\@desktop {
    padding-left: 2.5rem; }
  .content-box--5x\@desktop {
    padding: 3.125rem; }
  .content-box--h-5x\@desktop {
    padding-left: 3.125rem;
    padding-right: 3.125rem; }
  .content-box--v-5x\@desktop {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem; }
  .content-box--t-5x\@desktop {
    padding-top: 3.125rem; }
  .content-box--r-5x\@desktop {
    padding-right: 3.125rem; }
  .content-box--b-5x\@desktop {
    padding-bottom: 3.125rem; }
  .content-box--l-5x\@desktop {
    padding-left: 3.125rem; }
  .content-box--6x\@desktop {
    padding: 3.75rem; }
  .content-box--h-6x\@desktop {
    padding-left: 3.75rem;
    padding-right: 3.75rem; }
  .content-box--v-6x\@desktop {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .content-box--t-6x\@desktop {
    padding-top: 3.75rem; }
  .content-box--r-6x\@desktop {
    padding-right: 3.75rem; }
  .content-box--b-6x\@desktop {
    padding-bottom: 3.75rem; }
  .content-box--l-6x\@desktop {
    padding-left: 3.75rem; }
  .content-box--7x\@desktop {
    padding: 4.375rem; }
  .content-box--h-7x\@desktop {
    padding-left: 4.375rem;
    padding-right: 4.375rem; }
  .content-box--v-7x\@desktop {
    padding-top: 4.375rem;
    padding-bottom: 4.375rem; }
  .content-box--t-7x\@desktop {
    padding-top: 4.375rem; }
  .content-box--r-7x\@desktop {
    padding-right: 4.375rem; }
  .content-box--b-7x\@desktop {
    padding-bottom: 4.375rem; }
  .content-box--l-7x\@desktop {
    padding-left: 4.375rem; }
  .content-box--8x\@desktop {
    padding: 5rem; }
  .content-box--h-8x\@desktop {
    padding-left: 5rem;
    padding-right: 5rem; }
  .content-box--v-8x\@desktop {
    padding-top: 5rem;
    padding-bottom: 5rem; }
  .content-box--t-8x\@desktop {
    padding-top: 5rem; }
  .content-box--r-8x\@desktop {
    padding-right: 5rem; }
  .content-box--b-8x\@desktop {
    padding-bottom: 5rem; }
  .content-box--l-8x\@desktop {
    padding-left: 5rem; }
  .content-box--9x\@desktop {
    padding: 5.625rem; }
  .content-box--h-9x\@desktop {
    padding-left: 5.625rem;
    padding-right: 5.625rem; }
  .content-box--v-9x\@desktop {
    padding-top: 5.625rem;
    padding-bottom: 5.625rem; }
  .content-box--t-9x\@desktop {
    padding-top: 5.625rem; }
  .content-box--r-9x\@desktop {
    padding-right: 5.625rem; }
  .content-box--b-9x\@desktop {
    padding-bottom: 5.625rem; }
  .content-box--l-9x\@desktop {
    padding-left: 5.625rem; }
  .content-box--10x\@desktop {
    padding: 6.25rem; }
  .content-box--h-10x\@desktop {
    padding-left: 6.25rem;
    padding-right: 6.25rem; }
  .content-box--v-10x\@desktop {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem; }
  .content-box--t-10x\@desktop {
    padding-top: 6.25rem; }
  .content-box--r-10x\@desktop {
    padding-right: 6.25rem; }
  .content-box--b-10x\@desktop {
    padding-bottom: 6.25rem; }
  .content-box--l-10x\@desktop {
    padding-left: 6.25rem; }
  .content-box--11x\@desktop {
    padding: 6.875rem; }
  .content-box--h-11x\@desktop {
    padding-left: 6.875rem;
    padding-right: 6.875rem; }
  .content-box--v-11x\@desktop {
    padding-top: 6.875rem;
    padding-bottom: 6.875rem; }
  .content-box--t-11x\@desktop {
    padding-top: 6.875rem; }
  .content-box--r-11x\@desktop {
    padding-right: 6.875rem; }
  .content-box--b-11x\@desktop {
    padding-bottom: 6.875rem; }
  .content-box--l-11x\@desktop {
    padding-left: 6.875rem; }
  .content-box--12x\@desktop {
    padding: 7.5rem; }
  .content-box--h-12x\@desktop {
    padding-left: 7.5rem;
    padding-right: 7.5rem; }
  .content-box--v-12x\@desktop {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem; }
  .content-box--t-12x\@desktop {
    padding-top: 7.5rem; }
  .content-box--r-12x\@desktop {
    padding-right: 7.5rem; }
  .content-box--b-12x\@desktop {
    padding-bottom: 7.5rem; }
  .content-box--l-12x\@desktop {
    padding-left: 7.5rem; }
  .content-box--13x\@desktop {
    padding: 8.125rem; }
  .content-box--h-13x\@desktop {
    padding-left: 8.125rem;
    padding-right: 8.125rem; }
  .content-box--v-13x\@desktop {
    padding-top: 8.125rem;
    padding-bottom: 8.125rem; }
  .content-box--t-13x\@desktop {
    padding-top: 8.125rem; }
  .content-box--r-13x\@desktop {
    padding-right: 8.125rem; }
  .content-box--b-13x\@desktop {
    padding-bottom: 8.125rem; }
  .content-box--l-13x\@desktop {
    padding-left: 8.125rem; }
  .content-box--14x\@desktop {
    padding: 8.75rem; }
  .content-box--h-14x\@desktop {
    padding-left: 8.75rem;
    padding-right: 8.75rem; }
  .content-box--v-14x\@desktop {
    padding-top: 8.75rem;
    padding-bottom: 8.75rem; }
  .content-box--t-14x\@desktop {
    padding-top: 8.75rem; }
  .content-box--r-14x\@desktop {
    padding-right: 8.75rem; }
  .content-box--b-14x\@desktop {
    padding-bottom: 8.75rem; }
  .content-box--l-14x\@desktop {
    padding-left: 8.75rem; }
  .content-box--15x\@desktop {
    padding: 9.375rem; }
  .content-box--h-15x\@desktop {
    padding-left: 9.375rem;
    padding-right: 9.375rem; }
  .content-box--v-15x\@desktop {
    padding-top: 9.375rem;
    padding-bottom: 9.375rem; }
  .content-box--t-15x\@desktop {
    padding-top: 9.375rem; }
  .content-box--r-15x\@desktop {
    padding-right: 9.375rem; }
  .content-box--b-15x\@desktop {
    padding-bottom: 9.375rem; }
  .content-box--l-15x\@desktop {
    padding-left: 9.375rem; } }

.page {
  position: relative;
  transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
  transform-origin: -350% center;
  backface-visibility: hidden;
  overflow: hidden; }
  .page:before {
    content: '';
    position: absolute;
    top: 500px;
    left: 0;
    width: 100%;
    bottom: 0;
    background-image: linear-gradient(to bottom, white 35%, rgba(255, 255, 255, 0) 65%); }
  .page:after {
    content: '';
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../assets/images/bg-white-chalk.png");
    background-repeat-y: no-repeat;
    background-position: bottom;
    background-size: 120%;
    z-index: -2; }

.page__content {
  padding-top: 78px;
  backface-visibility: hidden;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none !important; }
  .page__content::-webkit-scrollbar {
    display: none; }

@media screen and (min-width: 64em) {
  .page__content {
    padding-top: 130px; } }

.nestle-footer {
  position: relative;
  background-color: #FFF;
  font-size: 0.9em; }

.nestle-footer__wrapper {
  max-width: 97.5rem;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column; }
  .nestle-footer__wrapper:after {
    content: '';
    display: block;
    border-bottom: solid 1px #d1d1d1;
    margin-bottom: 10px; }

.nestle-footer li {
  display: block;
  padding: 0 0.625rem;
  margin-right: 0; }

.nestle-footer li a {
  text-decoration: none;
  color: #000; }

.nestle-footer__logo {
  display: inline-block;
  position: static;
  width: 80px;
  z-index: 2;
  bottom: 0; }
  .nestle-footer__logo:after {
    content: none; }

.nestle-footer__logo img {
  margin: 0; }

@media screen and (min-width: 48rem) {
  .nestle-footer__wrapper {
    padding-left: 190px;
    flex-direction: row; }
    .nestle-footer__wrapper:after {
      display: none; }
  .nestle-footer li {
    float: left;
    width: 50%; }
  .nestle-footer__logo {
    width: auto;
    position: absolute; }
  .nestle-footer li {
    margin-right: 1.25rem;
    width: auto;
    padding-bottom: 0; }
  .nestle-footer__social-list {
    margin-left: auto; } }

a.card {
  display: block;
  position: relative;
  padding-bottom: 100%;
  border-radius: 4px;
  overflow: hidden;
  color: #286542;
  font-weight: 500;
  background-size: cover;
  background-position: center center; }
  a.card:hover, a.card:focus, a.card:active {
    background-color: #fff;
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.1), 0 2px 15px rgba(0, 0, 0, 0.05); }
    a.card:hover img, a.card:focus img, a.card:active img {
      transform: scale(1.1, 1.1) rotate(-2deg) translateZ(0); }
    a.card:hover .svg__product-bg, a.card:focus .svg__product-bg, a.card:active .svg__product-bg {
      transform: scale(1.1);
      opacity: 1; }
    a.card:hover .svg__product-underline, a.card:focus .svg__product-underline, a.card:active .svg__product-underline {
      opacity: 1; }

a.card img {
  margin: 0.625rem auto; }

a.card--rect {
  padding-bottom: 66.66%; }
  a.card--rect:hover, a.card--rect:active, a.card--rect:focus {
    background-color: transparent; }

.card--border {
  box-shadow: 0 0 1px #737373;
  margin-top: 1px; }

.card__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: inherit; }

.card__inner--flex-column {
  flex-direction: column; }

.card__inner img {
  transition: transform 0.2s ease-in-out;
  width: 35%;
  margin: 0; }

.card__icon {
  position: absolute;
  top: 0.625rem;
  left: 0.625rem; }

.card__new {
  background-color: #286542;
  border-radius: 5px;
  position: relative;
  padding: 0.3125rem 0.625rem;
  text-transform: uppercase; }
  .card__new:after {
    content: 'new'; }

a.card--fill {
  color: #fff;
  text-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5); }
  a.card--fill:after {
    content: '';
    background: url("../assets/images/icons/close.svg");
    position: absolute;
    top: 50%;
    left: 50%;
    transform: scale(0.1) translate(-50%, -50%);
    opacity: 0;
    height: 70px;
    width: 70px;
    transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
    transform-origin: 0 0; }
  a.card--fill:before {
    content: '';
    background: linear-gradient(180deg, transparent 0%, #000000 100%);
    position: absolute;
    border-radius: 4px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5; }
  a.card--fill:hover:after, a.card--fill:focus:after, a.card--fill:active:after {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%); }

a.card--icon:after {
  content: none; }

a.card--icon:hover .card__icon-text, a.card--icon:focus .card__icon-text, a.card--icon:active .card__icon-text {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1); }

.card__icon-text {
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.1);
  opacity: 0;
  color: #286542;
  fill: #286542;
  z-index: 2; }

.card--fill .card__inner {
  justify-content: flex-end;
  align-items: flex-end; }

.card__item {
  position: relative; }

.card__image {
  width: 110px;
  z-index: 1;
  position: relative;
  margin: 0 auto;
  transition: transform 0.2s ease-in-out; }

.card__text {
  position: relative;
  text-align: center; }

.card__tag {
  position: absolute;
  left: 1.25rem;
  top: 1.25rem;
  padding: 0.3125rem 0.625rem;
  text-transform: uppercase;
  color: #fff;
  background-color: #286542; }

.card__bg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 0;
  margin-top: 5px; }

.svg__product-bg {
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  width: 100%;
  transform: scale(0.1);
  opacity: 0; }

.svg__product-underline {
  transition: opacity 0.4s ease-in-out;
  opacity: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0); }

@media only screen and (min-width: 48rem) {
  .card__image {
    width: 130px; }
  .card--border {
    box-shadow: none;
    border: 1px solid #aaaaaa; }
  a.card img {
    margin-bottom: 1.25rem; } }

@media only screen and (min-width: 90em) {
  a.card:hover .svg__product-bg, a.card:focus .svg__product-bg, a.card:active .svg__product-bg {
    transform: scale(1.3); } }

.hero {
  position: relative;
  color: #fff;
  overflow: hidden; }

.hero__image {
  position: relative;
  height: 500px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; }
  .hero__image:after {
    content: '';
    background-image: url("../assets/images/skew-line-reverse.svg");
    position: absolute;
    bottom: -30px;
    left: 50%;
    right: 0;
    width: 101%;
    height: 140px;
    background-repeat: no-repeat;
    background-size: cover;
    transform: translateX(-50%); }
  .hero__image:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.25) 25%, rgba(46, 46, 46, 0) 85%); }

.hero .label-category {
  width: 225px;
  margin-bottom: 0.625rem; }

.hero__header {
  position: absolute;
  left: 1.25rem;
  right: 1.25rem;
  bottom: 100%;
  text-shadow: 1px 2px rgba(0, 0, 0, 0.5); }

.hero .section__inner {
  margin-top: -8.75rem; }

.hero__detail {
  margin: 0.625rem;
  background-repeat: no-repeat;
  background-size: cover; }

.hero__detail__preperation .icon {
  margin-right: 0.3125rem;
  fill: #fff; }

.hero__detail__preperation p,
.hero__detail__ingredients p {
  display: inline-block;
  vertical-align: middle; }

.hero--middle .section__inner {
  height: 100%;
  display: table; }

.hero--middle .section__inner .content-box {
  display: table-cell;
  vertical-align: middle; }

@media only screen and (min-width: 60rem) {
  .hero__header {
    max-width: 900px; }
  .hero__image {
    height: 640px; }
    .hero__image:after {
      bottom: 0px; } }

.footer {
  color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  mask: url("../assets/images/mask-top.png");
  mask-size: 100% 100%;
  mask-position: top;
  mask-repeat: no-repeat;
  margin-top: -1.25rem;
  z-index: 2;
  padding-top: 0.625rem; }

.footer__column--social .btn,
.footer__column--social li {
  margin-top: 0; }

.footer__column--social .btn svg {
  margin-right: 0.625rem; }

.footer__column:first-child {
  padding-bottom: 1.25rem; }

.footer__grid--col-max-3 {
  width: 100%; }

.footer__column--products,
.footer__column--links {
  padding-right: 0.625rem; }
  .footer__column--products a,
  .footer__column--links a {
    color: #fff; }
    .footer__column--products a:hover, .footer__column--products a:active, .footer__column--products a:focus,
    .footer__column--links a:hover,
    .footer__column--links a:active,
    .footer__column--links a:focus {
      color: #6DA527; }

.footer__column:nth-child(2) {
  padding-top: 1.25rem;
  position: relative; }

.footer h3 {
  margin-bottom: 1.25rem; }

.footer__mail {
  margin-top: 1.25rem; }

.footer__mail fieldset {
  position: relative;
  width: 100%;
  height: 50px; }

.footer__mail input {
  width: 100%;
  display: inline-block;
  background-color: transparent;
  color: #fff;
  height: 100%;
  border: 1px solid #6DA527;
  padding-right: 6.25rem; }

.footer__mail .btn {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  z-index: 1;
  background-color: #6DA527;
  white-space: nowrap; }
  .footer__mail .btn:before {
    background-color: #fff; }
  .footer__mail .btn:hover, .footer__mail .btn:active, .footer__mail .btn:focus {
    color: #6DA527;
    background-color: #fff; }

@media screen and (min-width: 60rem) {
  .footer__column:first-child {
    padding-bottom: 0;
    padding-right: 2.5rem; }
  .footer__column--social .btn svg {
    margin-right: 0; }
  .footer__column--products li,
  .footer__column--links li {
    margin-top: 0.15625rem;
    margin-bottom: 0.15625rem; }
  .footer__column:nth-child(2) {
    padding-top: 0;
    padding-left: 2.5rem; }
    .footer__column:nth-child(2):before {
      content: "";
      height: 100%;
      width: 7px;
      background-image: url("../assets/images/icons/background-menu-underline-white-vert.svg");
      background-size: 7px 100%;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      background-position: top;
      background-repeat: no-repeat; } }

.tabs {
  font-size: 1rem;
  margin-top: 0.625rem; }

.tabs__scroll {
  position: relative; }
  .tabs__scroll:before {
    content: '';
    position: absolute;
    top: 56px;
    left: 0;
    right: 0;
    height: 4px;
    overflow: auto;
    background-color: #aaaaaa; }

.tabs__list {
  overflow: auto;
  list-style-type: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch; }

.tabs__list li {
  display: inline-block;
  margin: 0;
  position: relative;
  white-space: normal; }
  .tabs__list li:not(:first-of-type) {
    margin-left: -6px; }

.tabs__link {
  display: flex;
  color: #286542;
  height: 60px;
  width: 140px;
  line-height: 1;
  padding: 1.25rem;
  text-decoration: none;
  position: relative;
  font-weight: 300;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  box-sizing: content; }
  .tabs__link:before {
    content: '';
    transition: background-color 0.2s ease-in-out;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 100%;
    position: absolute;
    border-radius: 8px; }
  .tabs__link:hover, .tabs__link:active, .tabs__link:focus {
    color: #286542; }
    .tabs__link:hover:before, .tabs__link:active:before, .tabs__link:focus:before {
      background-color: #286542; }

.tabs__link--active,
.tabs > input:checked + .tabs__link {
  color: #286542; }
  .tabs__link--active:before,
  .tabs > input:checked + .tabs__link:before {
    bottom: 0;
    left: 0;
    content: '';
    height: 4px;
    width: 100%;
    position: absolute;
    background-color: #286542;
    border-radius: 8px; }
  .tabs__link--active:after,
  .tabs > input:checked + .tabs__link:after {
    bottom: -6px;
    left: 50%;
    content: '';
    height: 14px;
    width: 14px;
    position: absolute;
    margin: 0;
    background-color: #286542;
    border-radius: 2px;
    transform: rotate(45deg) translate(-50%, 0%);
    transform-origin: 0% 50%; }

.tabs > input:not(:checked) + .tabs__content {
  display: none; }

.tabs__content {
  width: 100%;
  margin: 0.625rem 0 0 0; }

.share-nav {
  display: inline-block;
  color: #FFF;
  font-weight: 500;
  vertical-align: top;
  margin-right: 0.625rem;
  margin-top: 0.625rem; }

.share-nav__inner {
  padding: 0.5rem 1rem;
  background-color: #6DA527;
  border-radius: 4px; }

.share-nav__text:last-of-type {
  margin-top: 0;
  margin-right: 1.25rem; }

.share-nav__text,
.share-nav__list {
  display: inline-block;
  vertical-align: middle; }

.share-nav__list {
  line-height: 0; }

.share-nav__link {
  fill: #FFF; }
  .share-nav__link:hover, .share-nav__link:focus, .share-nav__link:active {
    fill: #286542; }

.share-nav li {
  margin: 0 0.625rem 0 0; }
  .share-nav li:last-of-type {
    margin-right: 0; }

.share-nav svg {
  height: 19px;
  width: 19px; }

.menu {
  font-weight: 300;
  margin: 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  font-size: .875rem; }

.menu a {
  color: #000000; }

.section--primary {
  position: relative;
  background-color: #fff;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.1), 0 2px 15px rgba(0, 0, 0, 0.05); }

.section--secondary {
  position: relative;
  font-size: .75rem; }
  .section--secondary:before, .section--secondary:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
  .section--secondary:before {
    background-repeat: repeat;
    background-image: url("../assets/images/bg-main-green.png");
    background-position: center center;
    background-size: cover;
    mask-image: url("../assets/images/mask-bottom.png");
    mask-size: 100% 100%;
    mask-position: center; }
  .section--secondary:after {
    background-color: #FFF;
    z-index: -1; }

.menu__brand {
  position: absolute;
  top: 0.625rem;
  left: 1.25rem;
  transform: scale(1);
  transform-origin: 0 center;
  transition: transform 0.2s ease-in-out;
  z-index: 10; }
  .menu__brand:before {
    content: '';
    background-color: #6DA527;
    width: 7px;
    height: 7px;
    transform: translate(-50%, 0) rotate(45deg);
    position: absolute;
    bottom: 35px;
    left: 50%;
    opacity: 0;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out; }
  .menu__brand:after {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
    content: attr(title);
    background-color: #6DA527;
    border-radius: 4px;
    color: #fff;
    padding: 0.41667rem 0.625rem 0.625rem;
    opacity: 0;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out; }
  .menu__brand:hover:before, .menu__brand:focus:before, .menu__brand:active:before {
    transform: translate(-50%, 15px) rotate(45deg);
    opacity: 1; }
  .menu__brand:hover:after, .menu__brand:focus:after, .menu__brand:active:after {
    transform: translate(-50%, 15px);
    opacity: 1; }

.menu__brand img {
  margin-bottom: 0;
  width: 110px;
  height: 84px; }

.menu__nav-inner {
  display: flex;
  align-items: center;
  padding: 0 1.25rem;
  height: 78px;
  transition: height 0.2s ease-in-out; }

.menu__nav-inner--meta {
  display: none;
  height: 55px;
  color: #fff;
  font-weight: 300; }

.user-scrolled .menu__nav-inner--meta {
  height: 0;
  overflow: hidden; }

.menu__nav-inner--meta a {
  color: #FFF; }

.menu__nav-inner--meta .menu__list {
  position: relative;
  z-index: 1;
  margin-left: auto; }

.menu__nav-inner--meta .menu__list li {
  display: inline-block; }

.menu__list-fixed {
  display: inline-flex;
  align-items: flex-start;
  margin-left: auto; }

.menu__list-fixed li {
  margin: 0; }

.menu__search {
  margin: auto 0.83333rem;
  background-color: #F4F4F4;
  padding: 0.75758rem;
  color: #000000;
  display: inline-flex;
  align-items: center;
  position: relative; }

.menu__search__text {
  display: none; }

.menu__search .icon {
  margin-right: 0;
  width: 18px;
  height: 18px; }

.menu__nav-inner-item-sub {
  margin: 0; }

.menu__sub-header {
  display: inline-block;
  font-size: 1.25rem; }

.menu__list {
  display: none;
  position: relative; }

.menu__list--meta li:not(:last-of-type) {
  margin-right: 1.25rem; }

.menu__list--meta .icon {
  margin-right: 0.625rem; }

.menu__list--meta a:hover, .menu__list--meta a:focus, .menu__list--meta a:active {
  opacity: .7; }

.menu__nav-item {
  margin: 0.83333rem;
  position: relative; }

.menu__nav-item .menu__sub__list {
  padding: 0.625rem 0.625rem 0 0.625rem; }

.menu__nav-item .box--solid-primary .menu__sub__list {
  padding: 1.25rem; }

.menu__link {
  display: block;
  line-height: 1;
  transform: translateZ(0);
  border-radius: 4px;
  padding: 0.73529rem 0.83333rem 0.96154rem 0.83333rem;
  margin: -0.625rem;
  font-size: 1.25rem;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out; }
  .menu__link:hover, .menu__link:focus, .menu__link:active, .menu__link.menu__link--active {
    color: #fff;
    background-color: #286542; }

.menu__link .icon {
  width: 10px;
  height: 10px; }

.menu__sub {
  position: absolute;
  left: 50%;
  top: 100%;
  margin: 0.83333rem 0 0;
  padding: 0;
  opacity: 0;
  width: 100%;
  transform: translate(-50%, -10px);
  transition: transform 0.4s ease-in-out, visibility 0s 0.4s, opacity 0.4s ease-in-out;
  visibility: hidden;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.1), 0 2px 15px rgba(0, 0, 0, 0.05);
  width: 180px;
  overflow-y: auto;
  height: 100%; }
  .menu__sub:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #286542; }

.menu .box--solid-secondary {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 5px);
  top: auto;
  max-height: none;
  width: 100%;
  max-width: 85rem;
  padding: 0.625rem; }
  .menu .box--solid-secondary:before {
    content: none; }

.menu__search-box input {
  border: none;
  background-color: #F4F4F4; }

.menu__sub .btn--wide {
  border-radius: 0; }
  .menu__sub .btn--wide:hover, .menu__sub .btn--wide:active, .menu__sub .btn--wide:focus {
    color: #286542; }

.menu__sub .btn--wide .icon {
  margin-left: 0.625rem; }

.menu__sub__list {
  padding: 0 1.25rem; }

.menu__sub__list li {
  margin: 0; }

.menu__sub__list a {
  color: #fff;
  display: block; }

.menu__sub__list .grid--col,
.menu__sub__list a {
  height: 100%; }

.box--solid-primary .menu__sub__list a {
  font-size: 17px; }

.box--solid-primary .menu__sub__item-meta {
  text-align: center; }

.box--solid-primary .menu__sub__item-meta a {
  padding: 0.625rem; }

.menu__sub__item-meta a {
  padding: 0;
  display: inline-block;
  vertical-align: middle; }

.menu__sub__list a .box--bg-primary {
  text-align: left; }

.menu .category__avatar {
  height: auto; }

.menu .category__label {
  max-width: 200px;
  position: static;
  transform: none;
  margin-bottom: 0.625rem; }

.menu__close {
  background-color: transparent; }

.menu__toggle {
  display: flex;
  font-weight: 300;
  align-middle: middle;
  margin: 0;
  padding: 0.75758rem; }

.menu__toggle .icon {
  margin-left: 0.625rem;
  margin-right: 0;
  width: 16px;
  height: 16px; }

.menu__target:checked ~ .page {
  transform: scale(0.8);
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.5); }

.menu__target:checked ~ .page .page__content--active {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none; }
  .menu__target:checked ~ .page .page__content--active::-webkit-scrollbar {
    display: none; }

.menu__target:checked ~ .page .menu {
  position: absolute; }

.menu__target:checked ~ .background-menu__overlay {
  z-index: 2; }

.menu__target:checked ~ .background-menu,
.menu__target:checked ~ .background-menu .background-menu__list,
.menu__target:checked ~ .background-menu .background-menu__nav {
  opacity: 1;
  visibility: visible; }

.menu--item-active .menu__sub {
  transition: transform 0.4s ease-in-out, visibility 0s, opacity 0.4s ease-in-out;
  transform: translate(-50%, 14px);
  opacity: 1;
  visibility: visible; }

.menu--item-active .menu__link .icon {
  transition: transform 0.4s ease-in-out;
  transform: rotate(-180deg) translateZ(0); }

.menu--item-active .menu__search-box {
  transform: translate(-50%, 5px); }

.menu--item-active .background-menu__slide-in {
  transform: translateX(15%); }

.menu--item-active .background-menu__overlay {
  z-index: 1; }

@media screen and (min-width: 30rem) {
  /*
    .menu__list {
        display: block;
        margin-left: 130px;
        transition: margin-left $time-fast ease-in-out;
    }

    .menu__list .menu__nav-item {
        display: none;

        &:first-of-type,
        &:nth-child(2) {
            display: inline-block;
        }
    } 
    */ }

@media screen and (min-width: 48rem) {
  /* .menu__sub {
        max-height: 300px;
    }

    .menu__search-box {
        max-height: none;
    }

    .menu__nav-inner--meta {
        display: flex;
    }*/ }

@media screen and (min-width: 64em) {
  .menu__sub {
    max-height: 300px; }
  .menu__search-box {
    max-height: none; }
  .menu__nav-inner--meta {
    display: flex; }
  .menu__brand {
    top: -1.25rem; }
  .menu__brand img {
    width: 180px;
    height: 116px; }
  .menu__list {
    display: block;
    margin-left: 200px;
    transition: margin-left 0.2s ease-in-out; }
  .user-scrolled .menu__brand {
    transform: scale(0.72);
    top: -0.625rem; }
  .user-scrolled .menu__list {
    margin-left: 150px; }
  .menu__nav-inner--meta .menu__list {
    margin-left: auto; }
  .menu__link {
    font-size: 1.2em;
    padding: 0.625rem; }
  .menu__list .menu__nav-item {
    display: inline-block;
    margin-left: 0.625rem;
    margin-right: 0.625rem; } }

@media screen and (min-width: 90em) {
  .menu__list {
    margin-left: 220px; }
  .menu__list .menu__nav-item {
    display: inline-block;
    margin: 0.83333rem; }
  .menu__link {
    padding: 0.73529rem 0.83333rem 0.96154rem 0.83333rem;
    font-size: 1.25em; }
  .menu__search span {
    display: inline-block; }
  .menu__sub {
    height: auto;
    max-height: none; }
  .menu__brand img {
    width: 200px;
    height: 129px; }
  .menu__search__text {
    display: inline; }
  .menu__search .icon {
    margin-right: 0.625rem; } }

.user-login-form {
  margin-top: 5rem !important; }

.carousel-slider {
  position: relative; }

.carousel-scroller__direction-btn--prev,
.carousel-scroller__direction-btn--next {
  position: absolute;
  z-index: 1;
  top: 50%; }
  .carousel-scroller__direction-btn--prev:hover .carousel-scroller__direction-icon--prev,
  .carousel-scroller__direction-btn--prev:hover .carousel-scroller__direction-icon--next, .carousel-scroller__direction-btn--prev:focus .carousel-scroller__direction-icon--prev,
  .carousel-scroller__direction-btn--prev:focus .carousel-scroller__direction-icon--next,
  .carousel-scroller__direction-btn--next:hover .carousel-scroller__direction-icon--prev,
  .carousel-scroller__direction-btn--next:hover .carousel-scroller__direction-icon--next,
  .carousel-scroller__direction-btn--next:focus .carousel-scroller__direction-icon--prev,
  .carousel-scroller__direction-btn--next:focus .carousel-scroller__direction-icon--next {
    fill: #fff; }

.carousel-scroller__direction-btn--prev {
  left: 1.25rem; }

.carousel-scroller__direction-btn--next {
  right: 1.25rem; }

.carousel-scroller__direction-icon--prev,
.carousel-scroller__direction-icon--next {
  transition: none;
  fill: #286542; }

.carousel__direction-btn--prev img,
.carousel__direction-btn--next img {
  width: 1rem;
  height: 1rem; }

.carousel-list {
  display: block;
  overflow: auto;
  width: 100%;
  white-space: nowrap;
  z-index: 0;
  -webkit-overflow-scrolling: touch; }

html:not(.no-js) .carousel-list {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none; }
  html:not(.no-js) .carousel-list::-webkit-scrollbar {
    display: none; }

.carousel-list > li {
  vertical-align: middle;
  display: inline-block;
  width: 100%;
  white-space: normal; }

.carousel-list > li a {
  display: block; }

@media screen and (min-width: 30rem) {
  .carousel-list > li {
    width: 50%; } }

@media screen and (min-width: 48rem) {
  .no-js .carousel-list {
    height: 60%;
    top: 25%;
    transform: none; } }

@media screen and (min-width: 60rem) {
  .carousel-list > li {
    width: 25%; }
  .carousel-list.carousel-list--odd {
    white-space: normal; }
  .carousel-list--odd > li {
    width: 33.333%; } }

.background-menu {
  font-weight: 500;
  margin: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: table;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  background-image: url("../assets/images/bg-white-chalk.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }

.background-menu .item-hover:hover:before, .background-menu .item-hover:active:before, .background-menu .item-hover:focus:before {
  transform: skewY(-2deg) translateY(-20%); }

.background-menu .accordion--is-destroyed .accordion__trigger {
  display: inline-block; }

.accordion--active .icon {
  transform: rotate(180deg); }

.background-menu__slide-in,
.background-menu__list,
.background-menu__nav {
  opacity: 0;
  width: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out; }

.background-menu .menu__link,
.background-menu .menu__link--active {
  position: relative;
  background-color: transparent;
  font-weight: 300;
  font-size: 24px;
  display: inline-block;
  padding-left: 0.625rem;
  color: #000000; }
  .background-menu .menu__link:hover, .background-menu .menu__link:focus, .background-menu .menu__link:active,
  .background-menu .menu__link--active:hover,
  .background-menu .menu__link--active:focus,
  .background-menu .menu__link--active:active {
    background-color: transparent;
    color: #286542; }

.background-menu .menu__link--active {
  color: #286542; }
  .background-menu .menu__link--active:after {
    content: '';
    background-image: url("../assets/images/icons/background-menu-underline.svg");
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 7px; }

.background-menu__nav {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  padding-right: 0;
  width: auto;
  z-index: 2; }

.background-menu__slide-in {
  display: table;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f2f2f2;
  z-index: 2;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out, visibility 0s 0.2s ease-in-out;
  transform: translateX(100%);
  padding-right: 20%;
  visibility: visible; }

.background-menu__list {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
  padding-left: 15%; }

.background-menu__list .menu__nav-item {
  font-family: "flama", sans-serif;
  position: static; }

.background-menu__sub-item {
  margin: 0; }

.background-menu__cell {
  font-size: 16px;
  width: 100%;
  display: table-cell;
  vertical-align: middle; }

.background-menu__cell button .icon {
  margin-right: 0.625rem; }

.background-menu__sub-list {
  font-weight: 500;
  margin-bottom: 1.25rem;
  border-top: 2px solid #aaaaaa;
  font-size: 11px; }

.background-menu__sub-list img {
  max-width: 60px;
  margin: 0 auto 0.625rem auto; }

.background-menu__sub-list li {
  text-align: center;
  border-bottom: 2px solid #aaaaaa; }
  .background-menu__sub-list li:nth-child(odd) {
    border-right: 2px solid #aaaaaa; }

.background-menu__sub-list a {
  display: block;
  padding: 1.25rem;
  height: 100%; }

.background-menu__overlay {
  position: fixed;
  transform: translateX(-88%);
  width: 100%;
  height: 100%;
  speak: none; }

.background-menu__meta__social a:last-of-type .icon {
  margin-right: 0; }

/* states background menu */
.menu--item-active.background-menu__slide-in {
  transform: translateX(15%);
  opacity: 1;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out, visibility 0.2s ease-in-out; }

.menu--item-active.background-menu__overlay {
  z-index: 1; }

.background-menu__meta {
  display: table-footer-group; }

.background-menu .section--secondary:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: repeat;
  background-image: url("../assets/images/bg-main-green.png");
  background-position: center center;
  background-size: cover;
  mask: url("../assets/images/mask-top.png");
  mask-size: 100% 100%;
  mask-position: center; }

.background-menu .menu__nav-inner--meta {
  display: flex;
  height: auto; }

.country .menu__sub {
  max-height: none; }

.user-scrolled .background-menu .menu__nav-inner--meta {
  height: auto;
  overflow: visible; }

.background-menu .menu__nav-inner--meta .menu__list {
  display: block; }

@media screen and (min-width: 960px) {
  .background-menu .accordion__trigger .icon {
    display: none; } }

@media screen and (min-width: 60rem) {
  /* states background menu */
  .menu--item-active.background-menu__sub-list {
    transform: translate(-100%, -100%); } }

.box {
  border-radius: 4px;
  background-repeat: repeat; }

.box--border-primary {
  box-shadow: 0 0 0 1px inset #286542; }

.box--border-secondary {
  box-shadow: 0 0 0 1px inset #fff; }

.box--border-blue {
  box-shadow: 0 0 0 1px inset #4094A8; }

.box--border-green {
  box-shadow: 0 0 0 1px inset #6DA527; }

.box--border-red {
  box-shadow: 0 0 0 1px inset #E63C2F; }

.box--border-purple {
  box-shadow: 0 0 0 1px inset #8B165E; }

.box--solid-primary {
  background-color: #286542;
  color: #fff; }

.box--solid-secondary {
  background-color: #fff; }

.box--solid-blue {
  background-color: #4094A8; }

.box--solid-green {
  background-color: #6DA527; }

.box--solid-red {
  background-color: #E63C2F; }

.box--solid-purple {
  background-color: #8B165E; }

.box--bg-primary {
  background-image: url("../assets/images/bg-main-green.png");
  color: #fff;
  box-shadow: 0 15px 20px transparent, 0 2px 15px transparent;
  transition: box-shadow 0.4s; }
  .box--bg-primary:hover, .box--bg-primary:focus, .box--bg-primary:active {
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.1), 0 2px 15px rgba(0, 0, 0, 0.1); }

.box--bg-secondary {
  background-image: url("../assets/images/bg-secondary.png");
  color: #000000; }

.box--bg-blue {
  background-image: url("../assets/images/bg-blue.png"); }

.box--bg-green {
  background-image: url("../assets/images/bg-green.png"); }

.box--bg-red {
  background-image: url("../assets/images/bg-red.png"); }

.box--bg-purple {
  background-image: url("../assets/images/bg-purple.png"); }

.breadcrumb {
  list-style: none;
  padding: 0;
  line-height: 1;
  font-size: 1rem; }

.breadcrumb li {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  color: #286542;
  position: relative;
  padding: 0.3125rem 1.5625rem 0.3125rem 0;
  margin-right: 0.625rem; }
  .breadcrumb li:not(:last-of-type):after {
    content: '\e901';
    position: absolute;
    vertical-align: middle;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    display: inline-block;
    color: #D8D8D8; }

.breadcrumb li:first-of-type a {
  padding-left: 0; }

.breadcrumb a {
  text-decoration: none;
  color: #286542; }
  .breadcrumb a:hover, .breadcrumb a:active, .breadcrumb a:focus {
    text-decoration: underline; }

.fray {
  width: 100%;
  height: 300px;
  transform: rotate(-3deg);
  background-size: cover;
  mask: url("../assets/images/mask-square.png");
  mask-size: 100% 100%;
  mask-position: center; }

@media screen and (min-width: 48rem) {
  .fray {
    height: 350px; } }

@media screen and (min-width: 60rem) {
  .fray {
    height: 400px; } }

.breadcrumb:last-of-type {
  margin-top: 0;
  margin-bottom: 1.875rem; }

.product-info__video {
  padding-top: 56%;
  position: relative;
  display: block;
  transform: rotate(-3deg);
  margin: 2.5rem 0;
  mask: url("../assets/images/mask-square.png");
  mask-size: 100% 100%;
  mask-position: center; }
  .product-info__video:after {
    content: '';
    background: url("../assets/images/icons/play-button-circle.svg");
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 70px;
    width: 70px;
    transform-origin: 0 0;
    transition: transform 0.2s ease-in-out; }
  .product-info__video:hover:after, .product-info__video:focus:after, .product-info__video:active:after {
    transform: rotate(-6deg) translate(-50%, -50%); }

.product-info__product-titel {
  position: relative;
  max-width: 366px;
  padding-right: 100px;
  margin-bottom: 3.75rem; }

.product-info__product-titel .arrow {
  position: absolute;
  top: 1.25rem;
  right: 3.75rem;
  transform: rotate(50deg); }

.product-info__packshot {
  position: absolute;
  right: 2.5rem;
  top: 50%;
  max-width: 80px;
  transform: translate(50%, -50%) rotate(4deg); }

.product-info h2,
.product-info__core h3 {
  color: #286542;
  text-transform: uppercase; }

.product-info__core {
  position: relative; }
  .product-info__core:after {
    content: '';
    display: block;
    background-image: url("../assets/images/dots-underline.svg");
    height: 10px;
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
    background-size: 275px 10px;
    background-repeat: repeat-x;
    background-position: top left;
    background-repeat-y: no-repeat; }

.detail-sticker {
  width: 120px;
  float: right;
  margin-left: 1.25rem;
  margin-bottom: 1.25rem; }

.product-info--nutrition-bg {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  position: relative;
  color: #fff; }
  .product-info--nutrition-bg:before {
    content: '';
    top: 0;
    left: -3.75rem;
    bottom: 0;
    width: 200%;
    position: absolute;
    mask: url("../assets/images/mask-square.png");
    mask-size: 100% 100%;
    mask-position: center;
    background-image: url("../assets/images/bg-main-green.png");
    background-size: 100% 100%;
    background-repeat: no-repeat; }

.product-info--nutrition-bg h3 {
  margin-bottom: 1.25rem; }

.product-info .image-leaf {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: -33px; }

.product-info--nutrition-bg > * {
  position: relative; }

.scroll-x {
  position: relative; }

.scroll-x table {
  display: block; }

.scroll-x h3 {
  margin: 1.875rem 0; }

.scroll-btn--left,
.scroll-btn--right {
  visibility: hidden;
  opacity: 0.2;
  transition: opacity 0.2s ease-in-out;
  padding: 0.625rem; }

.btn-visible {
  visibility: visible; }

.btn-active {
  opacity: 1; }

.scroll-buttons {
  position: relative;
  z-index: 1; }

.scroll-buttons__position {
  position: absolute;
  right: 0;
  top: 2.5rem;
  transform: translateY(-50%); }

.product-info .grid--col {
  flex-direction: column-reverse; }

.product-info .image__leafs {
  right: 0;
  bottom: -65px; }

@media only screen and (min-width: 48rem) {
  .product-info__column:first-child {
    padding-right: 1.25rem; }
  .product-info {
    margin-bottom: 2.5rem; }
  .product-info--nutrition-bg {
    padding: 2.5rem;
    padding-right: 0; }
    .product-info--nutrition-bg:before {
      left: 0; }
  .product-info__product-titel {
    padding-right: 110px; }
  .product-info__packshot {
    right: 2.5rem;
    max-width: 100%; } }

@media only screen and (min-width: 60rem) {
  .product-info__column:first-child {
    padding-right: 2.5rem; }
  .product-info--nutrition-bg {
    padding: 3.75rem;
    padding-right: 0; }
  .product-info .grid--col {
    flex-direction: row-reverse; } }

.accordion__content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-in-out; }

.no-js .accordion__trigger,
.accordion--is-destroyed .accordion__trigger {
  display: none; }

.no-js .accordion__content,
.accordion--is-destroyed .accordion__content {
  max-height: none;
  overflow: visible; }

.accordion--active .accordion__trigger--more {
  display: none; }

.accordion:not(.accordion--active) .accordion__trigger--less {
  display: none; }

/* entire container, keeps perspective */
.flip {
  perspective: 1000px;
  position: absolute;
  right: 0.625rem;
  margin-bottom: -1.25rem;
  top: 100%;
  left: 50%;
  transform: translateX(-50%); }

.lightbox .flip {
  position: relative;
  width: 100%;
  max-width: 350px; }

.flip,
.flip__front,
.flip__back {
  width: 100%;
  height: 430px; }

.flip__front,
.flip__back {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end; }

.flip img {
  max-width: 345px;
  max-height: 400px; }

.flip__container {
  transition: transform 0.6s ease-in-out;
  transform-style: preserve-3d;
  backface-visibility: visible;
  position: absolute;
  bottom: 0;
  width: 100%; }

.flip__front,
.flip__back {
  transform-style: preserve-3d;
  backface-visibility: hidden;
  -ms-backface-visibility: visible;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: opacity 0.1s 0.3s; }

.flip__front {
  opacity: 1;
  transform: rotateY(0deg); }

.flip__back {
  opacity: 0;
  transform: rotateY(180deg); }

.flip__nav button,
.flip__nav .btn {
  text-align: center;
  margin: 0 0.41667rem; }

.flip__nav svg {
  margin-right: 0.625rem; }

.flip__nav {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -3.75rem;
  left: 0;
  right: 0;
  max-width: 345px;
  margin: 0  auto;
  width: 100%; }

#flip-toggle:checked ~ .flip__container {
  transform: rotateY(180deg); }
  #flip-toggle:checked ~ .flip__container .flip__front {
    opacity: 0; }
  #flip-toggle:checked ~ .flip__container .flip__back {
    opacity: 1; }

label[for="flip-toggle"]:after {
  content: '';
  display: inline-block;
  vertical-align: middle; }

#flip-toggle:checked ~ .page label[for="flip-toggle"]:after,
#flip-toggle:checked ~ .lightbox label[for="flip-toggle"]:after {
  content: ''; }

.flip-close__btn {
  margin-right: auto;
  margin-left: auto;
  display: block; }

@media screen and (min-width: 60rem) {
  body:after {
    content: "60rem"; }
  .flip {
    perspective: 1000px;
    right: 2.5rem;
    bottom: -1.25rem;
    top: inherit; }
  .flip,
  .flip__front,
  .flip__back {
    width: 345px;
    height: 450px; }
  .lightbox .flip,
  .lightbox .flip__front,
  .lightbox .flip__back {
    width: 100%; }
  .lightbox .flip img {
    max-width: none;
    max-height: 520px; }
  label[for="flip-toggle"]:after {
    content: ''; }
  #flip-toggle:checked ~ .page label[for="flip-toggle"]:after,
  #flip-toggle:checked ~ .lightbox label[for="flip-toggle"]:after {
    content: ''; } }

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  z-index: 5;
  transition: max-height 0s 0.4s; }

.lightbox--active {
  max-height: 100%;
  overflow: auto;
  transition: max-height 0s; }

.lightbox__bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  opacity: 0;
  z-index: -1;
  background-color: #000;
  transition: opacity 0.4s, height 0s 0.4s; }

.lightbox--active .lightbox__bg {
  height: 100%;
  opacity: 0.5;
  transition: opacity 0.4s ease-in-out; }

.lightbox__table {
  height: 100%;
  width: 100%;
  max-width: 77.5rem;
  margin: 0 auto;
  display: table; }

.lightbox--video .lightbox__table {
  max-width: 65.5rem; }

.lightbox__cell {
  display: table-cell;
  vertical-align: middle;
  padding: 12.5rem 1.25rem 7.5rem;
  transition: padding-top 0.2s ease-in-out; }

.user-scrolled .lightbox__cell {
  padding: 7.5rem 1.25rem; }

.lightbox__content {
  position: relative;
  transform: translateY(-50px);
  opacity: 0;
  transition: opacity 0.4s 0.1s, transform 0.4s 0.1s; }

.lightbox--active .lightbox__content {
  transform: translateY(0);
  opacity: 1; }

.lightbox .tile-box {
  padding: 0; }

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  clear: both; }

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden; }

.carousel {
  padding: 5rem 0.625rem 2.5rem 0.625rem; }

.carousel__inner {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto; }

.carousel__item {
  color: #fff;
  background-image: url("../assets/images/bg-main-green.png");
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  transform: scale(1) rotate(25deg) translateY(100%);
  opacity: 0;
  background-size: cover;
  transform-origin: left top;
  transition: 0.4s ease-in-out;
  transition-property: transform, left, top, opacity;
  will-change: transform; }

.carousel__inner .carousel__item--active {
  z-index: 3;
  opacity: 1;
  transform: scale(1) rotate(0) translateY(0);
  position: relative; }

.carousel__inner .carousel__item--active + li,
.carousel-item--lineup-first {
  top: -15px;
  left: 12px;
  transform: scale(0.98) rotate(-1deg) translateY(0);
  opacity: .5;
  z-index: 2; }

.carousel__inner .carousel__item--active + li + li,
.carousel-item--lineup-second {
  top: -20px;
  left: 25px;
  transform: scale(0.96) rotate(-2deg) translateY(0);
  opacity: .2;
  z-index: 1; }

.carousel__text {
  opacity: 0;
  padding: 0.625rem; }

.carousel__text .btn {
  margin-top: 1.25rem; }

.carousel .button {
  background-color: transparent;
  border: 1px solid #fff;
  margin-bottom: 0.625rem;
  text-shadow: none; }
  .carousel .button:hover, .carousel .button:focus, .carousel .button:active {
    color: #286542;
    background-color: #fff; }

.carousel__img-container {
  position: relative;
  margin-bottom: 1.875rem; }

.carousel__images {
  position: relative;
  padding-bottom: 107%;
  background-size: cover;
  margin: -2.5rem -1.25rem 0;
  transform: rotate(-6deg);
  mask: url("../assets/images/mask-square.png");
  mask-size: 100% 100%;
  mask-position: center; }

.carousel__images img {
  opacity: 0;
  margin: 0;
  position: absolute; }

.carousel__images--1 {
  position: absolute;
  top: -1.875rem;
  right: 0.625rem;
  width: 130px; }

.carousel__images--2 {
  width: 150px;
  top: 1.875rem;
  left: 1.875rem; }

.carousel__dot-list {
  position: absolute;
  z-index: 6;
  text-align: center;
  top: 18rem;
  left: -1.25rem;
  width: auto;
  transform: rotate(90deg); }

.carousel__dot-list li {
  display: inline-block;
  border: 2px solid #fff;
  margin: 0 0.625rem;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  position: relative;
  cursor: pointer; }
  .carousel__dot-list li:before {
    content: '';
    background-color: #fff;
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    border-radius: 100%;
    transform: scale(0);
    transition: transform 0.4s ease-in-out; }

.carousel__dot-list .carousel__item--active:before {
  transform: scale(1); }

.carousel__direction {
  position: absolute;
  top: 10rem;
  right: 0.625rem;
  z-index: 6;
  display: flex;
  justify-content: space-between;
  top: 16rem;
  left: auto;
  transform: rotate(90deg); }

.carousel__direction button {
  position: relative;
  background-color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 1.25rem; }

.carousel__direction button .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%) rotate(-90deg); }

.carousel__direction-btn--prev .icon,
.carousel__direction-btn--next .icon {
  fill: #286542; }

.carousel__direction-btn--prev:hover .icon, .carousel__direction-btn--prev:active .icon, .carousel__direction-btn--prev:focus .icon,
.carousel__direction-btn--next:hover .icon,
.carousel__direction-btn--next:active .icon,
.carousel__direction-btn--next:focus .icon {
  fill: #fff; }

.carousel-scroller__direction-icon--next,
.carousel-scroller__direction-icon--prev {
  width: 15px;
  height: 15px; }

/* NO JAVASCRIPT OVERWRITE */
.no-js .carousel {
  padding: 1.25rem; }

.no-js .carousel__dot-list,
.no-js .carousel__direction {
  display: none; }

.no-js .carousel__item {
  position: static;
  height: auto;
  width: 100%;
  transform: scale(1) rotate(0) translateY(0);
  opacity: 1;
  margin-bottom: 2.5rem; }

.no-js .carousel__text {
  position: relative;
  opacity: 1; }

.no-js .carousel__images img {
  opacity: 1; }

@media only screen and (min-width: 480px) {
  .carousel__images {
    padding-bottom: 0;
    height: 445px;
    margin: -1.25rem auto 0; } }

@media only screen and (min-width: 48rem) {
  .carousel {
    padding-left: 0.625rem;
    padding-right: 1.875rem;
    position: relative; }
  .carousel .content-box {
    padding: 0; }
  .carousel__text {
    padding: 1.25rem 3.75rem 1.25rem 1.25rem;
    width: 47%;
    float: right;
    height: 530px;
    display: flex;
    justify-content: center;
    flex-direction: column; }
  .carousel__img-container {
    width: 48%;
    height: 103%; }
  .carousel__images {
    position: absolute;
    left: 1.25rem;
    width: 100%;
    height: 570px;
    top: -2.5rem;
    margin: 0; }
  .carousel__images--1 {
    width: 232px;
    top: -3.75rem; }
  .carousel__images--2 {
    width: 240px;
    top: auto;
    bottom: 1.875rem;
    left: 1.875rem; }
  .carousel__dot-list {
    padding-top: 2.5rem;
    width: 15px;
    top: 50%;
    left: 2.5rem;
    transform: translateY(-50%); }
  .carousel__dot-list li {
    margin: 0.3125rem 0; }
  .carousel__direction button {
    width: 60px;
    height: 60px;
    margin: 0.3125rem 0; }
  .carousel__direction {
    padding-top: 2.5rem;
    position: absolute;
    top: 50%;
    left: auto;
    right: 7px;
    flex-direction: column;
    transform: translateY(-50%); }
  .carousel__direction button .icon {
    transform: translate(-50%, -50%) rotate(0); } }

.categories {
  margin-bottom: 2.5rem; }

.categories h1 {
  text-align: center;
  margin-bottom: 2.5rem;
  color: #286542; }

.categories ul,
.categories .content-box {
  padding: 0; }

.category {
  position: relative;
  list-style: none;
  color: #fff;
  padding: 0.625rem;
  margin-bottom: 1.25rem;
  margin-top: 1.875rem; }

.category a {
  text-decoration: none;
  color: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.2s ease-in-out; }
  .category a:hover, .category a:active, .category a:focus {
    box-shadow: 0px 0px 35px #858585; }
    .category a:hover .category__label, .category a:active .category__label, .category a:focus .category__label {
      transform: rotate(-6deg) translate(-50%, -50%); }

.category__label {
  width: 100%;
  max-width: 225px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 0;
  transition: transform 0.2s ease-in-out; }

.category__avatar {
  background-size: cover;
  background-repeat: no-repeat;
  height: 250px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }

.category p {
  width: 100%;
  background-image: url("../assets/images/bg-main-green.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 1.25rem;
  margin-top: -20px;
  flex: 1 0 auto;
  mask: url("../assets/images/mask-top.png");
  mask-position: top;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  max-height: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 6;
  transition: opacity 0.4s ease-in-out, max-height 0s 0.4s; }

.modal-active .overlay {
  opacity: 1;
  max-height: 100%;
  transition: opacity 0.4s ease-in-out, max-height 0s; }

.footer-cta {
  background-size: cover;
  mask: url("../assets/images/mask-top.png");
  mask-position: top; }

.footer-cta__content {
  color: #fff;
  max-width: 470px;
  text-align: center;
  width: 100%;
  margin: auto; }

.footer-cta__content a {
  margin-top: 1.25rem; }

.footer-cta h1,
.footer-cta h2,
.footer-cta p {
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5); }

.footer-cta p {
  font-weight: bold; }

.list-link {
  display: flex;
  position: relative;
  color: black;
  cursor: pointer; }
  .list-link:before {
    display: block;
    position: absolute;
    content: "";
    left: -2.5rem;
    top: -1.25rem;
    bottom: -1.25rem;
    right: -2.5rem;
    background-color: #6DA527;
    mask: url("../assets/images/mask-square.png");
    mask-size: 100% 100%;
    mask-position: center;
    z-index: 1;
    opacity: 0;
    border-radius: 4px;
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out; }
  .list-link:hover, .list-link:focus, .list-link:active {
    color: #fff;
    fill: #fff; }
    .list-link:hover p, .list-link:focus p, .list-link:active p {
      color: #fff;
      position: relative;
      z-index: 1; }
    .list-link:hover:before, .list-link:focus:before, .list-link:active:before {
      opacity: 1; }
    .list-link:hover .list-link__text li:before, .list-link:focus .list-link__text li:before, .list-link:active .list-link__text li:before {
      color: #fff; }

.list--links {
  margin-top: 0.625rem;
  margin-bottom: 1.25rem; }
  .list--links:last-of-type {
    margin-bottom: 0; }

.list--links > li {
  position: relative;
  background-image: url(../assets/images/icons/background-menu-underline-grey.svg);
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: 100% 7px; }
  .list--links > li:first-of-type {
    background-image: none; }

.list--links li {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem; }

.list-link__icon {
  display: block;
  position: relative;
  width: 50px;
  flex-basis: 50px;
  padding-right: 1.25rem;
  margin-right: 0.625rem; }

.list-link__icon svg {
  transition: fill 0.2s ease-in-out; }

.list-link__icon--card {
  width: 60px;
  height: 60px;
  margin-right: 1.25rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  flex-shrink: 0;
  text-align: center; }
  .list-link__icon--card img {
    height: 100%; }

.list-link__icon .icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  transition: none; }

.list-link__icon--card,
.list-link__icon,
.list-link__text {
  z-index: 1; }

.list-link__text:last-of-type {
  margin: 0; }

.list-link--accordion {
  display: block; }
  .list-link--accordion:hover .list-link__icon .icon, .list-link--accordion:focus .list-link__icon .icon, .list-link--accordion:active .list-link__icon .icon {
    fill: #fff; }

.list-link--accordion .list-link__text {
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center; }

.list-link--accordion .list-link__icon {
  position: relative;
  right: 0;
  padding-right: 0;
  padding-left: 1.25rem; }

.list-link--accordion .list-link__icon .icon {
  fill: #286542;
  transition: transform 0.4s ease-in-out; }

.list-link--accordion .accordion__content {
  position: relative;
  z-index: 1; }
  .list-link--accordion .accordion__content:before {
    content: '';
    display: block;
    background-image: url("../assets/images/dots-underline.svg");
    height: 10px;
    width: 100%;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    background-size: 275px 10px;
    background-repeat: repeat-x;
    background-position: top left;
    background-repeat-y: no-repeat; }

.accordion--active {
  color: #fff;
  fill: #fff; }
  .accordion--active:before {
    opacity: 1; }
  .accordion--active .list-link__icon .icon {
    fill: #fff; }
  .accordion--active .list-link__icon .icon {
    transform: rotate(-180deg) translateY(50%); }

.country {
  position: relative; }

.country .menu__sub {
  position: absolute;
  width: 250px;
  right: 0;
  left: auto;
  transform: translateY(5px); }
  .country .menu__sub:before {
    content: "";
    position: absolute;
    bottom: 100%;
    right: 1.25rem;
    left: auto;
    transform: none;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #fff; }

.country .menu__sub__list {
  padding: 0; }

.country .menu__sub__list li {
  color: #000;
  display: block;
  margin-right: 0; }
  .country .menu__sub__list li:not(:last-of-type) a {
    margin-bottom: 0.625rem; }

.country .menu__sub__list a {
  color: #000;
  text-align: left;
  padding: 0.3125rem; }

.country .menu__sub__list > li > a {
  padding: 0; }
  .country .menu__sub__list > li > a:not(:first-of-type) {
    margin-left: 30px; }

.country input:checked ~ .menu__sub {
  transition: transform 0.4s ease-in-out, visibility 0s, opacity 0.4s ease-in-out;
  transform: translateY(0);
  opacity: 1;
  visibility: visible; }

.background-menu .country .menu__sub {
  padding: 1.25rem;
  bottom: 100%;
  top: auto;
  transform: translateY(-20px); }
  .background-menu .country .menu__sub:before {
    content: "";
    position: absolute;
    top: 100%;
    bottom: auto;
    right: 1.25rem;
    left: auto;
    transform: none;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid #fff;
    border-bottom: 0; }

.background-menu .country input:checked ~ .menu__sub {
  transform: translateY(-10px); }

.country .avatar--small {
  margin-right: 0.625rem;
  border-color: #286542; }

.avatar {
  display: inline-block;
  vertical-align: middle;
  border-radius: 100%;
  border: 2px solid #FFF;
  margin-right: 0.3125rem; }

.avatar--medium {
  width: 30px;
  height: 30px; }

.avatar--small {
  width: 20px;
  height: 20px; }

.paralax__visual {
  display: none; }

.paralax__holder {
  position: relative; }

.paralax__holder--nutrition {
  right: -30%; }
  .paralax__holder--nutrition img {
    margin-left: auto; }

@media only screen and (min-width: 48rem) {
  .paralax__visual {
    display: block;
    position: absolute;
    z-index: 5;
    width: 200px; }
  .paralax__visual--large {
    width: 300px; }
  .paralax__visual--small {
    width: 150px; } }

.hero-main {
  color: #fff; }

.hero-article .hero__image .hero-main .hero__image {
  height: initial; }

.hero-main h1,
.hero-main h2,
.hero-main p {
  text-shadow: 1px 1px rgba(0, 0, 0, 0.8); }

.hero-main p {
  margin-bottom: 0.625rem; }

@media only screen and (min-width: 48rem) {
  .hero-main h1,
  .hero-main h2,
  .hero-main p {
    text-shadow: 1px 2px rgba(0, 0, 0, 0.5); }
  .hero-main .content-box {
    width: 50%; }
  .hero-main .btn--arrow {
    position: absolute;
    left: 50%;
    bottom: 12%;
    z-index: 1; } }

.hero--recipe .hero__header {
  left: 1.25rem; }

.product--purple .svg__product-bg {
  fill: #901558; }

.product--purple .svg__product-underline {
  fill: #901558; }

.product--red .svg__product-bg {
  fill: #C8102E; }

.product--red .svg__product-underline {
  fill: #C8102E; }

.product--blue .svg__product-bg {
  fill: #5EA6C6; }

.product--blue .svg__product-underline {
  fill: #5EA6C6; }

.product--green .svg__product-bg {
  fill: #92C020; }

.product--green .svg__product-underline {
  fill: #92C020; }

.svg__product-bg {
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  transform: scale(0.1); }

.svg__product-underline {
  transition: opacity 0.4s ease-in-out;
  opacity: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0); }

.search-submit--green {
  background-color: transparent;
  border-color: #fff;
  color: #fff; }

.pull-up {
  margin-top: -50px; }

@media screen and (min-width: 60rem) {
  .pull-up {
    margin-top: -120px; }
  .reverse-even > [class*='grid--col'] {
    align-items: center; }
    .reverse-even > [class*='grid--col']:nth-child(even) {
      flex-direction: row-reverse; }
  .reverse-odd > [class*='grid--col'] {
    align-items: center; }
    .reverse-odd > [class*='grid--col']:nth-child(odd) {
      flex-direction: row-reverse; } }

.hero-product .hero__detail__preperation {
  margin-top: 15rem;
  margin-bottom: 1.25rem; }

.hero-product .hero__detail__preperation li {
  display: block; }
  .hero-product .hero__detail__preperation li:first-child {
    margin-top: 0; }

.hero-product .hero__detail__ingredients {
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 0.625rem; }

.hero-product .hero__detail__ingredients li {
  border: 1px solid #fff;
  padding: 0.25rem 0.625rem; }

.hero-product .hero__detail__ingredients span {
  vertical-align: initial; }

.hero-product .hero__detail .btn {
  margin-top: 1.25rem; }
  .hero-product .hero__detail .btn:hover .icon, .hero-product .hero__detail .btn:focus .icon, .hero-product .hero__detail .btn:active .icon {
    fill: #fff; }

.hero-product .hero__detail .btn .icon {
  margin-right: 0.3125rem; }

.hero-product .svg__product-bg {
  fill: #fff; }

.hero-product .hero__detail__product-image {
  transition: transform 0.2s ease-in-out;
  position: absolute;
  width: 170px;
  top: 0.625rem;
  left: 2.5rem; }
  .hero-product .hero__detail__product-image:hover, .hero-product .hero__detail__product-image:focus, .hero-product .hero__detail__product-image:active {
    transform: scale(1.1, 1.1) rotate(-2deg); }
    .hero-product .hero__detail__product-image:hover .svg__product-bg, .hero-product .hero__detail__product-image:focus .svg__product-bg, .hero-product .hero__detail__product-image:active .svg__product-bg {
      transform: scale(2.5);
      opacity: 1; }
    .hero-product .hero__detail__product-image:hover .icon--eye-circle, .hero-product .hero__detail__product-image:focus .icon--eye-circle, .hero-product .hero__detail__product-image:active .icon--eye-circle {
      transform: rotate(4deg) translate(20%, 30%); }

.hero-product .hero__detail__product-image .arrow {
  position: absolute;
  bottom: 0;
  transform: rotate(170deg);
  right: 50px;
  width: 50%; }

.hero-product .product-image {
  margin: 0;
  z-index: 100;
  margin-bottom: 0.625rem; }

.hero-product .card__bg {
  z-index: -1; }

.hero-product .hero__detail__product-image .icon--eye-circle {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(30%, 20%);
  transform-origin: 40% 20%; }

.hero-product .icon--zoomer {
  display: none; }

.hero-product .btn--secondary {
  margin-top: 1.25rem; }

.hero-product .hero__detail button .icon {
  transition: none; }

@media only screen and (min-width: 440px) {
  .hero-product .hero__detail__product-image {
    right: 2.5rem;
    left: auto; }
  .hero-product .hero__detail__preperation {
    margin-top: 0;
    margin-bottom: 3.75rem; }
  .hero-product .hero__header {
    padding-right: 200px; }
  .hero-product .hero__detail__product-image {
    top: -5rem; } }

@media only screen and (min-width: 48rem) {
  .hero-product .hero__detail__preperation li {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 1.25rem;
    margin-right: 1.25rem; }
  .hero-product .hero__detail__preperation {
    margin-bottom: 1.25rem; }
  .hero-product .hero__detail__product-image {
    top: -7.5rem; } }

@media only screen and (min-width: 60rem) {
  .hero-product .content-box--4x\@desktop {
    padding-right: 350px; }
  .hero-product .hero__detail__product-image {
    width: 260px;
    right: 3.75rem; }
  .hero-product .hero__detail__preperation {
    margin-bottom: 0; }
  .hero-product .hero__detail__ingredients,
  .hero-product .hero__detail__preperation {
    display: inline-block;
    vertical-align: top; }
  .hero-product .hero__detail__preperation li:first-child,
  .hero-product .hero__detail__preperation li {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  .hero-product .hero__detail__product-image .arrow {
    width: 80px;
    bottom: -20px; }
  .hero-product .hero__detail__product-image {
    top: -8.75rem; } }

.footer-navigation {
  background-repeat: no-repeat;
  background-size: cover;
  mask: url("../assets/images/mask-top.png");
  mask-size: 100% 100%;
  mask-position: center;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem; }

.footer-navigation__image {
  display: block;
  position: relative;
  padding-bottom: 70%;
  transition: none; }
  .footer-navigation__image .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.1);
    opacity: 0;
    opacity: 0;
    z-index: 100;
    transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
    color: #286542;
    fill: #286542; }
  .footer-navigation__image:focus .icon, .footer-navigation__image:active .icon, .footer-navigation__image:hover .icon {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1); }
  .footer-navigation__image:focus .label-category, .footer-navigation__image:active .label-category, .footer-navigation__image:hover .label-category {
    transform: rotate(6deg) translate(-50%, -50%); }
  .footer-navigation__image .image-container {
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #fff; }
    .footer-navigation__image .image-container:before {
      content: '';
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, rgba(46, 46, 46, 0) 35%);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 0 0 4px 4px; }
  .footer-navigation__image .footer-navigation__title {
    width: 100%;
    position: absolute;
    bottom: 0;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.9); }

.visible {
  display: none; }

.footer-navigation .label-category {
  position: absolute;
  margin: 0;
  left: 50%;
  top: 0;
  transform-origin: 0;
  transform: translate(-50%, -50%);
  width: 180px;
  transition: transform 0.4s ease-in-out; }

.footer-navigation__container {
  position: relative; }

.footer-navigation__container > *:nth-of-type(even) {
  margin-top: -1.25rem; }

.footer-navigation .btn-all {
  margin: 0;
  width: 100%;
  text-align: center;
  position: relative;
  padding: 1.25rem; }

.footer-navigation .btn-all .icon {
  width: 100%;
  margin-bottom: 0.625rem; }

.footer-navigation .btn-prev,
.footer-navigation .btn-next,
.footer-navigation .btn-all {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); }

.footer-navigation .btn-prev,
.footer-navigation .btn-next {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100; }

.footer-navigation .btn-prev {
  left: 0.625rem; }

.footer-navigation .btn-next {
  right: 0.625rem; }

@media screen and (min-width: 48rem) {
  .visible {
    display: block; }
  .footer-navigation .content-box {
    position: relative; }
  .footer-navigation__image {
    margin: 0; }
  .footer-navigation .btn-all {
    position: absolute;
    width: 165px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2.75rem 0; }
  .footer-navigation__container > *:nth-of-type(even) {
    margin-top: 0; } }

@media only screen and (min-width: 60rem) {
  .footer-navigation .label-category {
    width: 225px; } }

.theme--red button {
  border: 1px solid #fff;
  color: #E63C2F; }
  .theme--red button:before {
    background-color: #E63C2F; }
  .theme--red button:focus, .theme--red button:active, .theme--red button:hover {
    background-color: #E63C2F;
    color: #fff; }

.theme--red .icon--shopping-basket,
.theme--red .icon--eye-circle {
  fill: #E63C2F; }

.theme--red .hero__detail {
  background-image: url("../assets/images/bg-red.png"); }

/*.theme--green h1 {
	color: $color-primary;
}

.theme--green h2 {
	color: $color-primary;
}*/
.theme--green button {
  border: 1px solid #fff;
  color: #6DA527; }
  .theme--green button:before {
    background-color: #6DA527; }
  .theme--green button:focus, .theme--green button:active, .theme--green button:hover {
    background-color: #6DA527;
    color: #fff; }

.theme--green .icon--shopping-basket,
.theme--green .icon--eye-circle {
  fill: #6DA527; }

.theme--green .hero__detail {
  background-image: url("../assets/images/bg-green.png"); }

.theme--purple button {
  border: 1px solid #fff;
  color: #8B165E; }
  .theme--purple button:before {
    background-color: #8B165E; }
  .theme--purple button:focus, .theme--purple button:active, .theme--purple button:hover {
    background-color: #8B165E;
    color: #fff; }

.theme--purple .icon--shopping-basket,
.theme--purple .icon--eye-circle {
  fill: #8B165E; }

.theme--purple .hero__detail {
  background-image: url("../assets/images/bg-purple.png"); }

.theme--blue button {
  border: 1px solid #fff;
  color: #4094A8; }
  .theme--blue button:before {
    background-color: #4094A8; }
  .theme--blue button:focus, .theme--blue button:active, .theme--blue button:hover {
    background-color: #4094A8;
    color: #fff; }

.theme--blue .icon--shopping-basket,
.theme--blue .icon--eye-circle {
  fill: #4094A8; }

.theme--blue .hero__detail {
  background-image: url("../assets/images/bg-blue.png"); }

.theme--white h2 {
  color: #fff; }

.hero-news {
  position: relative;
  color: #fff; }

.hero-news .hero__image .content-box {
  max-width: 530px; }

.hero-news .card {
  display: inline-block;
  width: 100%;
  mask: url("../assets/images/mask-square.png");
  mask-size: 100% 100%;
  mask-position: center;
  transform: rotate(3deg);
  z-index: 1;
  padding-bottom: 50%;
  max-width: 600px; }

.hero-news .card-container {
  position: relative;
  margin-top: -5rem; }

.hero-news .image-leaf {
  position: absolute;
  bottom: -61px;
  right: 0;
  z-index: 1;
  max-width: 200px; }

.hero-news p {
  margin-bottom: 0.625rem; }

@media only screen and (min-width: 48rem) {
  .hero-news .card-container {
    margin-top: -10rem; } }

@media only screen and (min-width: 60rem) {
  .hero-news article {
    padding-right: 1.25rem; }
  .hero-news .hero__image .content-box {
    width: 53%;
    max-width: none; }
  .hero-news .section__inner--collapse {
    position: absolute;
    top: 0;
    left: 0;
    right: 0; }
  .hero-news .hero__image .content-box {
    position: absolute;
    top: 0.625rem;
    left: 0.625rem;
    z-index: 2; }
  .hero-news .card-container {
    margin-top: 0;
    float: right;
    width: 50%; }
  .hero-news .card {
    max-width: none;
    width: 650px;
    height: 520px; }
  .hero-news .image-leaf {
    position: absolute;
    bottom: 40px;
    right: auto;
    left: -131px;
    z-index: 1;
    max-width: 300px; } }

.hero-product--recipe-detail .icon--product-info {
  fill: #286542; }

.hero-product--recipe-detail .hero__detail__preperation {
  margin: 0; }

.hero-product--recipe-detail .hero__detail__preperation li {
  margin-top: 0;
  margin-right: 1.25rem;
  margin-bottom: 1.25rem;
  display: inline-block; }

.hero-product--recipe-detail .hero__detail__ingredients {
  border: 1px solid rgba(40, 101, 66, 0.5); }

.hero-product--recipe-detail .hero__detail__ingredients span {
  color: #286542; }

.hero-product--recipe-detail .icon {
  fill: #286542; }

@media only screen and (min-width: 60rem) {
  .hero-product--recipe-detail .hero__detail__preperation li {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  .hero-product--recipe-detail .hero__detail__ingredients {
    display: inline-block;
    width: inherit;
    vertical-align: top; } }

.section--fullscreen-bg h1 {
  color: #fff;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.5); }

.section--fullscreen-bg .fullscreen-bg__container {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  margin-bottom: 1.25rem; }

.press .list-link__icon {
  fex-schrink: 0; }

.press .list-link__icon svg {
  left: 0;
  bottom: initial;
  margin-right: 1.25rem; }

.error-404 {
  display: flex;
  align-items: center; }

.error-404 .btn {
  text-transform: uppercase; }

.error-404 .fullscreen-bg__container {
  position: relative; }
  .error-404 .fullscreen-bg__container:after {
    position: absolute;
    right: -35px;
    bottom: -50px;
    content: none;
    background-image: url("../assets/images/detail-leaf.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 220px;
    height: 125px; }

.faq h3 {
  color: #286542;
  margin-bottom: 0; }

.faq .box {
  margin-bottom: 1.25rem; }

.search-results p {
  margin-top: 0; }

.search-results h3 {
  color: #286542; }

.search-results sup {
  padding-left: 0.3125rem;
  color: #000000; }

.search-results .list-link {
  flex-direction: column; }

.search-results .list--ingredients li {
  padding: 0;
  padding-left: 1.875rem;
  margin-right: 1.25rem; }
  .search-results .list--ingredients li:before {
    color: #286542; }

.search-results .list-link__text li:before {
  color: #D8D8D8; }

.search-results .list--links__link:after {
  content: none;
  display: none; }

.search-results .list--links__link:last-of-type:after {
  content: "";
  display: block; }

.search-results .list-link__icon--card {
  margin-bottom: 0.625rem; }

@media only screen and (min-width: 30rem) {
  .search-results .list-link {
    flex-direction: row; }
  .search-results .list-link__icon--card {
    margin-bottom: 0; } }

@media only screen and (min-width: 48rem) {
  .error-404 .fullscreen-bg__container:after {
    content: ""; } }

.articles .card--fill:after {
  content: none; }

.recipe-filter-range .range {
  line-height: 1.1; }

.recipe-filter-range label {
  font-size: .9rem; }

@media screen and (min-width: 60rem) {
  .recipe-filter-range label {
    width: 35%;
    vertical-align: middle;
    text-align: right;
    padding-right: 1.25rem; }
  .recipe-filter-range > *:first-child label {
    width: auto; }
  .recipe-filter-range > *:last-child {
    padding-right: 1.875rem; }
  .recipe-filter-range .range {
    width: calc(75% - 112px);
    display: inline-block;
    vertical-align: middle; } }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: #000 !important;
    text-shadow: none !important; }
  aside,
  audio,
  button,
  canvas,
  embed,
  footer,
  form,
  header,
  menu,
  object,
  video,
  label.card .card__inner:after,
  .section--jagged:after,
  .no-print {
    display: none !important; }
  .hero {
    height: auto !important;
    min-height: auto !important;
    max-height: none; }
  .hero__image {
    height: 200px;
    margin-top: 20px;
    max-width: 250px; }
  .arrow {
    display: none; }
  .product-info .grid--col {
    flex-direction: column; }
  .product-info--nutrition-bg {
    margin-top: -3.75rem;
    padding-left: 20px; }
  .product-info__product-titel {
    padding: 0;
    margin-bottom: 20px; }
  .product-info__packshot {
    position: static;
    transform: none;
    width: 150px;
    padding-top: 20px; }
  .product-info__core {
    padding-left: 10px; }
  .section--jagged {
    padding: 0 !important; }
  .grid--col-max-4 > * {
    max-width: 25%;
    flex-basis: 25%; }
  a[href]:after {
    content: " (" attr(href) ") ";
    font-size: 90%; }
  a[href^="/"]:after {
    content: " (http://" attr(href) ") "; }
  abbr:after {
    content: " (" attr(title) ")"; }
  thead {
    display: table-header-group; }
  tr, img, pre, blockquote {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  h1, h2, h3, h4, h5, h6 {
    page-break-after: avoid; }
  h2, h3, p {
    orphans: 3;
    widows: 3; }
  @page {
    margin: .5cm; } }
