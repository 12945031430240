// ========================================================
// Card
// ========================================================

a.card {
    display: block;
    position: relative;
    padding-bottom: 100%;
    border-radius: $radius-border;
    overflow: hidden;
    color: $color-primary;
    font-weight: 500;
    background-size: cover;
    background-position: center center;

    &:hover,
    &:focus,
    &:active {
    	background-color: #fff;
    	box-shadow: $box-shadow;

        img {
            transform: scale(1.1, 1.1) rotate(-2deg) translateZ(0);
        }

        .svg__product-bg {
            transform: scale(1.1);
            opacity: 1;
        }
        
        .svg__product-underline {
            opacity: 1;
        }
    }
}

a.card img {
	margin: $gutter/2 auto;
}

a.card--rect {
    padding-bottom: 66.66%;
    
    &:hover,
    &:active,
    &:focus {
        background-color: transparent;
    }
}

.card--border {
    box-shadow: 0 0 1px #737373;
    margin-top: 1px;
}

.card__inner {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: inherit;
}

.card__inner--flex-column {
    flex-direction: column;

}

.card__inner img {
    transition: transform $time-fast ease-in-out;
    width: 35%;
    margin: 0;
}

.card__icon {
    position: absolute;
    top: $gutter/2;
    left: $gutter/2;
}

.card__new {
    background-color: $color-primary;
    border-radius: 5px;
    position: relative;
    padding: $gutter/4 $gutter/2;
    text-transform: uppercase;

    &:after {
        content: 'new';
    }
}

a.card--fill {
	color: #fff;
    text-shadow: 0 1px 2px 0 rgba(0,0,0,0.5);
    
    &:after {
        content: '';
        background: url('../assets/images/icons/close.svg');
        position: absolute;
        top: 50%;
        left: 50%;
        transform: scale(0.1) translate(-50%, -50%);
        opacity: 0;
        height: 70px;   
        width: 70px;
        transition: transform $time ease-in-out, opacity $time ease-in-out;
        transform-origin: 0 0;
    }

    &:before {
        content: '';
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%);
        position: absolute;
        border-radius: $radius-border;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
    }

    &:hover,
    &:focus,
    &:active {

        &:after {
            opacity: 1;
            transform: scale(1) translate(-50%, -50%);
        }
    }
}

a.card--icon {

    &:after {
        content: none;
    }


    &:hover,
    &:focus,
    &:active {

        .card__icon-text {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
        }
    }
}


.card__icon-text {
    transition: transform $time ease-in-out, opacity $time ease-in-out;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.1) ;
    opacity: 0;
    color: $color-primary;
    fill: $color-primary;
    z-index: 2;
}


.card--fill .card__inner {
	justify-content: flex-end;
	align-items: flex-end;
}


.card__item {
    position: relative;
}

.card__image {
    width: 110px;
    z-index: 1;
    position: relative;
    margin: 0 auto;
    transition: transform $time-fast ease-in-out;
}

.card__text {
    position: relative;
    text-align: center;
}

.card__tag {
    position: absolute;
    left: $gutter;
    top: $gutter;
    padding: $gutter/4 $gutter/2;
    text-transform: uppercase;
    color: $color-secondary;
    background-color: $color-primary;
}

.card__bg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 0;
    margin-top: 5px;
}

.svg__product-bg {
    transition: opacity $time-fast ease-in-out, transform $time-fast ease-in-out;
    width: 100%;
    transform: scale(0.1);
    opacity: 0;
}

.svg__product-underline {
    transition: opacity $time ease-in-out;
    opacity: 0;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    //clip: rect(0px,60%,200%,0px);
}


@media only screen and (min-width: $view-tablet-landscape) {
    .svg__product-bg {
        //width: 120px;   
    }

    .card__image {
        width: 130px;
    }

    .card--border {
        box-shadow: none;
        border: 1px solid $color-border;
    }

    a.card img {
        margin-bottom: $gutter;
    }
}

@media only screen and (min-width: 90em) {

    a.card {

        &:hover,
        &:focus,
        &:active {

            .svg__product-bg {
                transform: scale(1.3);
            }
        }
    }
}