// ========================================================
// HERO RECIPE DETAIL
// ========================================================

.hero-product--recipe-detail .icon--product-info {
	fill: $color-primary;
}

.hero-product--recipe-detail .hero__detail__preperation {
	margin: 0;
}

.hero-product--recipe-detail .hero__detail__preperation li {
	margin-top: 0;
	margin-right: $gutter;
	margin-bottom: $gutter;
	display: inline-block;
}

.hero-product--recipe-detail .hero__detail__ingredients {
	border: 1px solid rgba(40, 101, 66, .5);
}

.hero-product--recipe-detail .hero__detail__ingredients span {
	color: $color-primary;
}

.hero-product--recipe-detail .icon {
	fill: $color-primary;
}



@media only screen and (min-width: $view-desktop) {

	.hero-product--recipe-detail .hero__detail__preperation li {
		margin-top: $gutter/2;
		margin-bottom: $gutter/2;
	}

	.hero-product--recipe-detail .hero__detail__ingredients {
		display: inline-block;
		width: inherit;
		vertical-align: top;
	}
}