// ========================================================
// HERO NEWS
// ========================================================

.hero-news {
	position: relative;
	color: $color-secondary;
}

.hero-news .hero__image .content-box {
	max-width: 530px;
}

.hero-news .card {
	display: inline-block;
	width: 100%;
	mask: url('../assets/images/mask-square.png');
	mask-size: 100% 100%;
	mask-position: center;
	transform: rotate(3deg);
	z-index: 1;
	padding-bottom: 50%;
	max-width: 600px;
}

.hero-news .card-container {
	position: relative;
	margin-top: -$gutter * 4;
}

.hero-news .image-leaf {
	position: absolute;
    bottom: -61px;
    right: 0;
    z-index: 1;
    max-width: 200px;
}

.hero-news p {
	margin-bottom: $gutter/2;
}

@media only screen and (min-width: $view-tablet-landscape) {

	.hero-news .card-container {
		margin-top: -$gutter * 8;
	}
}

@media only screen and (min-width: $view-desktop) {

	.hero-news article {
		padding-right: $gutter;
	}

	.hero-news .hero__image .content-box {
		width: 53%;
		max-width: none;
	}

	.hero-news .section__inner--collapse {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}
	
	.hero-news .hero__image .content-box {
		position: absolute;
		top: $gutter/2;
		left: $gutter/2;
		z-index: 2;
	}
	
	.hero-news .card-container {
		margin-top: 0;
		float: right;
		width: 50%;
	}
	
	.hero-news .card {
		max-width: none;
		width: 650px;
    	height: 520px;
	}

	.hero-news .image-leaf {
	    position: absolute;
	    bottom: 40px;
	    right: auto;
	    left: -131px;
	    z-index: 1;
	    max-width: 300px;
	}
}


