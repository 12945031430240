// ========================================================
// # LIGHTBOX

.lightbox {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	max-height: 0;
	overflow: hidden;
	z-index: 5;
	transition: max-height 0s $time;
}

.lightbox--active {
	max-height: 100%;
	overflow: auto;
	transition: max-height 0s;
}

.lightbox__bg {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 0;
	opacity: 0;
	z-index: -1;
	background-color: #000;
	transition: opacity $time, height 0s $time;
}

.lightbox--active .lightbox__bg {
	height: 100%;
	opacity: 0.5;
	transition: opacity $time ease-in-out;
}

.lightbox__table {
	height: 100%;
	width: 100%;
	max-width: 77.5rem; // 1240px
	margin: 0 auto;
	display: table;
}

.lightbox--video .lightbox__table {
	max-width: 65.5rem;
}


.lightbox__cell {
	display: table-cell;
	vertical-align: middle;
	padding: $gutter*10 $gutter $gutter*6;
	transition: padding-top $time-fast ease-in-out;
}

.user-scrolled .lightbox__cell {
	padding: $gutter*6 $gutter;
}

.lightbox__content {
    position: relative;
    transform: translateY(-50px);
    opacity: 0;
    transition: opacity $time 0.1s, transform $time 0.1s;
}

.lightbox--active .lightbox__content {
	transform: translateY(0);
    opacity: 1;
}

.lightbox .tile-box {
	padding: 0;
}