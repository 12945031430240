// ========================================================
// FOOTER NAVIGATION
// ========================================================

.footer-navigation {
	background-repeat: no-repeat;
	background-size: cover;
	mask: url('../assets/images/mask-top.png');
  	mask-size: 100% 100%;
  	mask-position: center;
  	padding-top: $gutter*1.5;
  	padding-bottom: $gutter*1.5;
}

.footer-navigation__image {
	display: block;
	position: relative;
	padding-bottom: 70%;
	transition: none;

	.icon {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%) scale(0.1);
    	opacity: 0;
		opacity: 0;
		z-index: 100;
    	transition: transform $time ease-in-out, opacity $time ease-in-out;
		color: $color-primary;
		fill: $color-primary;
	}

	&:focus,
	&:active,
	&:hover {
		.icon {
			opacity: 1;
            transform: translate(-50%, -50%) scale(1);
		}

		.label-category {
			transform: rotate(6deg) translate(-50%, -50%);
		}
	}

	.image-container {
		position: absolute;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		width: 100%;
		height: 100%;
		text-align: center;
		color: $color-secondary;

		&:before {
			content: '';
			background-image: linear-gradient(to top,rgba(0,0,0,.5) 0%, rgba(46,46,46,0) 35%);
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			border-radius: 0 0 $radius-border $radius-border;
		}
	}

	.footer-navigation__title {
		width: 100%;
		position: absolute;
		bottom: 0;
		text-shadow: 0px 0px 5px rgba(0,0,0,0.9);
	}
}

.visible {
	display: none;
}

.footer-navigation .label-category {
	position: absolute;
	margin: 0;
	left: 50%;
	top: 0;
	transform-origin: 0;
	transform: translate(-50%, -50%);
	width: 180px;
	transition: transform $time ease-in-out;
}

.footer-navigation__container {
	position: relative;
}


.footer-navigation__container > * {
	&:nth-of-type(even) {
		margin-top: -$gutter;
	}
}

.footer-navigation .btn-all {
	margin: 0;
	width: 100%;
	text-align: center;
	position: relative;
	padding: $gutter;

}

.footer-navigation .btn-all .icon {
	width: 100%;
	margin-bottom: $gutter/2;
}
.footer-navigation .btn-prev,
.footer-navigation .btn-next,
.footer-navigation .btn-all {
	box-shadow: 0 0 20px rgba(0,0,0,0.3);
}

.footer-navigation .btn-prev,
.footer-navigation .btn-next {
	margin: 0;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 100;
}

.footer-navigation .btn-prev {
	left: $gutter/2;
}

.footer-navigation .btn-next {
	right: $gutter/2;
}

@media screen and (min-width: $view-tablet-landscape) {

	.visible {
		display: block;
	}

	.footer-navigation .content-box {
		position: relative;
	}

	.footer-navigation__image {
		margin: 0;
	}

	.footer-navigation .btn-all {
		position: absolute;
		width: 165px;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		padding: $gutter*2.2 0;
	} 


	.footer-navigation__container > * {
		&:nth-of-type(even) {
			margin-top: 0;
		}
	}
}


@media only screen and (min-width: $view-desktop) {
	
	.footer-navigation .label-category {
		width: 225px;
	}

}





