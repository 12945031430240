// ========================================================
// HTML and Body
// ========================================================
html,
button,
datalist,
input,
select,
textarea {
    color: $color-text;
}

html {
    font-style: normal;
    font-weight: 300;
    letter-spacing: normal;
    line-height: 1.5;
    box-sizing: border-box;
    overflow-x: hidden;
}

* {
    box-sizing: inherit;

    &:before,
    &:after {
        box-sizing: inherit;
    }
}

body {
    font-family: $font-primary;
    font-size: globalPerc($text-size);

    &:after {
        content: "0em";
        display: block;
        height: 0;
        position: absolute;
        overflow: hidden;
        width: 0;
    }
}


// ========================================================
// Headers
// ========================================================

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    margin: 0 0 ($gutter/2) 0;
    line-height: .8;
}

h1,
.title-primary {
    font-family: $font-secondary;
    font-size: 2.2em;
    text-transform: uppercase;
}

h2,
.title-secondary  {
    font-family: $font-secondary;
    font-size: 2em;
    text-transform: uppercase;
}

h3,
.title-tertiary {
    margin-top: $gutter/2;
    font-weight: 500;
}


sup {
    font-weight: 400;
    color: #737373;
    font-size: .875rem;
}

time,
time span {
    font-size: .875rem;
    line-height: 1.4;
}

span {
    vertical-align: middle;
}

// ========================================================
// Default spacings
// ========================================================
h2,
h3,
p,
img,
table,
form,
fieldset,
.text-label,
pre {
    &:first-child {
        margin-top: 0;
    }

    &:only-child {
        margin-bottom: 0;
    }
}

h2 + p,
h3 + p,
h2 + ol,
h3 + ol,
h2 + ul,
h3 + ul {
    margin-top: 0;
}

p,
ol,
ul,
img,
table,
form,
fieldset,
pre,
object,
embed,
video,
audio,
figure,
figcaption,
.paragraph {
    margin-top: $gutter/2;

    &:last-of-type {
        margin-bottom: 0;
    }
}

p {
    &:last-of-type {
        margin-top: $gutter/2;
    } 

    &:only-child {
        margin: 0;
    }
}


p,
ol,
ul,
blockquote,
img,
table,
form,
fieldset,
pre,
object,
embed,
video,
audio,
figure,
figcaption,
.paragraph {
    margin-bottom: $gutter/2;
}

ol,
ul {
    padding-left: $gutter;
}

ol ol,
ul ul {
    padding-left: $gutter;
}

li {
    margin-bottom: $gutter/2;
    margin-top: $gutter/2;
}

datalist,
input,
select,
textarea {
    padding: $gutter/2 $gutter;
    background-color: transparent;
    color: inherit;

    &:focus,
    &:active {
        color: inherit;
    }
}



// ========================================================
// Links and buttons
// ========================================================

a,
p a {
    color: $color-primary;
    fill: currentColor;
    text-decoration: underline;
    position: relative;
    transition: $time-fast ease-in-out;

    &:active,
    &:focus,
    &:hover {
        color: $color-border;
    }
}

a {
    transition: color $time-fast ease-in-out, background-color $time-fast ease-in-out;
    text-decoration: none;
    display: inline-block;
}

blockquote {
    position: relative;
    z-index: 0;
    color: $color-secondary;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -$gutter * 2;
        width: 150%;
        height: 100%;
        background-image: url('../assets/images/bg-main-green.png');
        background-size: cover;
        z-index: -1;
        transform: rotate(-2deg);
        mask: url('../assets/images/mask-square.png');
        mask-size: 100% 100%;
        mask-position: center;
    }

    &:after {
        content: "";
        display: none;
    }
}


blockquote p {
    width: 100%;
    quotes: '\201c' '\201d';
    font-family: $font-secondary;
    font-size: 1.9rem;
    line-height: 1.5;
    //padding: $gutter * 2.5;
    padding: $gutter * 2 0;
    margin: 0;
    text-transform: uppercase;

    &:before {
        content: open-quote;
        margin-right: $gutter/4;
    }

    &:after {
        content: close-quote;
        margin-left: 0;
    }
}



button {
    background-color: #000;
    color: #fff;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition: background-color $time-fast ease-in-out, color $time-fast ease-in-out;
    font-weight: 500;

    &:focus,
    &:hover,
    &:active {
        color: #FFF;
    }
}

::-webkit-input-placeholder {
    opacity: 1;
}

// ========================================================
// tablet-landscape
// ========================================================

@include respond-min-width($view-tablet-landscape){

    h1,
    .title-primary {
        font-size: 2em; // 32px
    }

    h2,
    .title-secondary {
        font-size: 1.75em; // 28px
    }

    h3,
    .title-tertiary {
        font-size: 1.25em; // 20px;
    }

    blockquote {

        &:before {
            left: 0;
            width: 100%;
        }

        p {
            padding: $gutter * 2.5;
        }
    }

} // END MEDIA


// ========================================================
// DESKTOP
// ========================================================

@include respond-min-width($view-desktop){


    body {
        font-size: globalPerc(17);
    }

    h1,
    .title-primary {
        font-size: 3.7645em;
        // line-height: 60px;
    }

    .hero-title {
        font-size: 5.8em;
    }

    h2,
    .title-secondary {
        font-size: 2.824em;
    }

    h3,
    .title-tertiary {
        font-size: 1.411em;
    }

    h4,
    .title-quaternary {
        font-size: 1.125em; // 18px;
    }

} // END MEDIA
