// ========================================================
// VIDEO IFRAME
// ========================================================
.video-container {
    position: relative;
    padding-bottom: 56.25%;
    clear: both;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: $radius-border;
    overflow: hidden;
}