// ========================================================

// #COLORS

$color-text: #000000;
$color-border: #aaaaaa;
$color-primary: #286542;
$color-secondary: #fff;
$color-red: #E63C2F;
$color-green: #6DA527;
$color-purple: #8B165E;
$color-blue: #4094A8;
$color-grey: #F4F4F4;
$color-dark-grey: #D8D8D8;