// ========================================================
// # ACCORDION

.accordion {
   
}

.accordion__content {
    max-height: 0;
    overflow: hidden;
    transition: max-height $time ease-in-out;
}

.no-js .accordion__trigger,
.accordion--is-destroyed .accordion__trigger {
    display: none;
}

.no-js .accordion__content,
.accordion--is-destroyed .accordion__content {
    max-height: none;
    overflow: visible;
}

.accordion--active .accordion__trigger--more {
	display: none;
}

.accordion:not(.accordion--active) .accordion__trigger--less {
	display: none;
}