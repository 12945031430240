// ========================================================
// # BACKGROUND MENU

.background-menu {
    font-weight: 500;
    margin: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    display: table;
    opacity: 0;
    visibility: hidden;
    transition: opacity $time-fast ease-in-out, visibility $time-fast ease-in-out;
    
    background-image: url('../assets/images/bg-white-chalk.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.background-menu .item-hover {
    &:hover,
    &:active,
    &:focus {

        &:before {
            transform: skewY(-2deg) translateY(-20%);
        }
    }
}

.background-menu .accordion--is-destroyed .accordion__trigger {
    display: inline-block;   
}

.accordion--active .icon {
    transform: rotate(180deg);
}


.background-menu__slide-in,
.background-menu__list,
.background-menu__nav {
    opacity: 0;
    width: 0;
    visibility: hidden;
    transition: opacity $time-fast ease-in-out, visibility $time-fast ease-in-out;
}

.background-menu .menu__link,
.background-menu .menu__link--active {
    position: relative;
    background-color: transparent;
    font-weight: 300;
    font-size: 24px;
    display: inline-block;
    padding-left: $gutter/2;
    color: $color-text;

    &:hover,
    &:focus,
    &:active {
        background-color: transparent;
        color: $color-primary;
    }
}

.background-menu .menu__link--active {
    color: $color-primary;
    
    &:after {
        content: '';
        background-image: url('../assets/images/icons/background-menu-underline.svg');
        background-repeat: no-repeat;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 7px;
    }
}

.background-menu__nav {
    position: absolute;
    top: $gutter;
    right: $gutter;
    padding-right: 0;
    width: auto;
    z-index: 2;
}

.background-menu__slide-in {
    display: table;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f2f2f2;
    z-index: 2;
    transition: transform $time-fast ease-in-out, opacity $time-fast ease-in-out, visibility 0s $time-fast ease-in-out;
    transform: translateX(100%);
    padding-right: 20%;
    visibility: visible;
}

.background-menu__list {
    display: table-cell;
    width: 100%;
    vertical-align: middle;
    padding-left: 15%;
}

.background-menu__list .menu__nav-item {
    font-family: $font-primary;
    position: static;
}

.background-menu__sub-item {
    margin: 0;
}

.background-menu__cell {
    font-size: 16px;
	width: 100%;
    display: table-cell;
    vertical-align: middle;
}

.background-menu__cell button .icon {
    margin-right: $gutter/2;
}

.background-menu__sub-list {
    font-weight: 500;
    margin-bottom: $gutter;
    border-top: 2px solid $color-border;
    font-size: 11px;
}

    .background-menu__sub-list img {
        max-width: 60px;
        margin: 0 auto $gutter/2 auto;
    }

    .background-menu__sub-list li {
        text-align: center;
        border-bottom: 2px solid $color-border;

        &:nth-child(odd) {
            border-right: 2px solid $color-border;
        }
    }

    .background-menu__sub-list a {
        display: block;
        padding: $gutter;
        height: 100%;
    }

.background-menu__overlay {
	position: fixed;
	transform: translateX(-88%);
	width: 100%;
	height: 100%;
	speak: none;
}

.background-menu__meta__social a {
    &:last-of-type {
        .icon {
            margin-right: 0;
        }
    }
}

/* states background menu */
.menu--item-active.background-menu__slide-in {
    transform: translateX(15%);
    opacity: 1; 
    transition: transform $time-fast ease-in-out, opacity $time-fast ease-in-out, visibility $time-fast ease-in-out;  
}

.menu--item-active.background-menu__overlay {
    z-index: 1;
}

.background-menu__meta {
    display: table-footer-group;
}

.background-menu .section--secondary:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: repeat;
    background-image: url('../assets/images/bg-main-green.png');
    background-position: center center;
    background-size: cover;
    mask: url('../assets/images/mask-top.png');
    mask-size: 100% 100%;
    mask-position: center;
}

.background-menu .menu__nav-inner--meta {
    display: flex;
    height: auto;
}

.country .menu__sub {
    max-height: none;    
}

.user-scrolled .background-menu .menu__nav-inner--meta {
    height: auto;
    overflow: visible;
}

.background-menu .menu__nav-inner--meta .menu__list {
    display: block;
}

@media screen and (min-width: 960px) {
    .background-menu .accordion__trigger .icon {
        display: none;
    }
}

@media screen and (min-width: $view-desktop) {
    /* states background menu */
    .menu--item-active.background-menu__sub-list {
        transform: translate(-100%, -100%);
    }
}
