.fray {
	width: 100%;
	height: 300px;
	transform: rotate(-3deg);
	background-size: cover;

	mask: url('../assets/images/mask-square.png');
  	mask-size: 100% 100%;
  	mask-position: center;
}

@media screen and (min-width: $view-tablet-landscape) {
	.fray {
		height: 350px;
	}
}

@media screen and (min-width: $view-desktop) {
	.fray {
		height: 400px;
	}
}