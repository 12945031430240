// ========================================================
// GRID
// ========================================================
[class*='grid--col'],
.grid--fill {
    display: flex;
    margin: 0;
}

[class*='grid--col'] {
    flex-wrap: wrap;
}

[class*='grid--col'] > * {
    margin: 0;
}

.grid--fill > * {
    margin: 0;
    width: 100%;
}

.footer__grid--col-max-3 > * {
    max-width: 50%;
    flex-basis: 50%;
}

.grid--col-max-2 > *,
.grid--col-max-3 > *,
.grid--col-max-4 > * {
    max-width: 100%;
    flex-basis: 100%;
}

.recipe-overview__grid--col-max-4 > * {
    max-width: 50%;
    flex-basis: 50%;
}

@for $i from 0 through $multiplyGutter {
    .grid--stretch-#{$i}x {
        margin: (-$gutter/2) * $i;
    }

    // stretch-h = horizontal negative margins
    .grid--stretch-h-#{$i}x {
        margin-left: (-$gutter/2) * $i;
        margin-right: (-$gutter/2) * $i; 
    }

    // stretch-v = vertical negative margins
    .grid--stretch-v-#{$i}x {
        margin-top: (-$gutter/2) * $i;
        margin-bottom: (-$gutter/2) * $i; 
    }

    // stretch-t = top negative margins
    .grid--stretch-t-#{$i}x {
        margin-top: (-$gutter/2) * $i;
    }

    // stretch-r = right negative margins
    .grid--stretch-r-#{$i}x {
        margin-right: (-$gutter/2) * $i;
    }

    // stretch-b = bottom negative margins
    .grid--stretch-b-#{$i}x {
        margin-bottom: (-$gutter/2) * $i;
    }

    // stretch-l = left negative margins
    .grid--stretch-l-#{$i}x {
        margin-left: (-$gutter/2) * $i;
    }
}

@for $col from 2 through $maxCols {
    @for $part from 1 through $maxCols {
        .col-#{$part}\/#{$col} {
            max-width: 100%;
            flex-basis: 100%;
        }

    }
}

// ========================================================
// TABLET PORTRAIT
// ========================================================
@media screen and (min-width: $view-tablet-portrait) {
    .grid--col-max-3 > *,
    .grid--col-max-4 > * {
        max-width: 50%;
        flex-basis: 50%;
    }

    .footer__grid--col-max-3 > * {
        max-width: 33.333%;
        flex-basis: 33.333%;
    }
}


// ========================================================
// TABLET LANDSCAPE
// ========================================================
@media screen and (min-width: $view-tablet-landscape) {

    .grid--col-max-4 > * {
        max-width: 33.333%;
        flex-basis: 33.333%;
    }

    .grid--col-max-2 > * {
        max-width: 50%;
        flex-basis: 50%;
    }
}



// ========================================================
// DESKTOP
// ========================================================
@media screen and (min-width: $view-desktop) {
    
    .footer__grid--col-max-2 > * {
        max-width: 50%;
        flex-basis: 50%;
    }

    .grid--col-max-3 > * {
        max-width: 33.333%;
        flex-basis: 33.333%;
    }

    .grid--col-max-4 > * {
        max-width: 25%;
        flex-basis: 25%;
    }

    @for $col from 2 through $maxCols {
        @for $part from 1 through $maxCols {
            .col-#{$part}\/#{$col} {
                max-width: percentage($part / $col);
                flex-basis: percentage($part / $col);
            }
        }
    }

    @for $i from 0 through $multiplyGutter {
    .grid--stretch-#{$i}x\@desktop {
        margin-left: (-$gutter/2) * $i;
        margin-right: (-$gutter/2) * $i; 
    }

    // stretch-h = horizontal negative margins
    .grid--stretch-h-#{$i}x\@desktop {
        margin-left: (-$gutter/2) * $i;
        margin-right: (-$gutter/2) * $i; 
    }

    // stretch-v = vertical negative margins
    .grid--stretch-v-#{$i}x\@desktop {
        margin-top: (-$gutter/2) * $i;
        margin-bottom: (-$gutter/2) * $i; 
    }

    // stretch-t = top negative margins
    .grid--stretch-t-#{$i}x\@desktop {
        margin-top: (-$gutter/2) * $i;
    }

    // stretch-r = right negative margins
    .grid--stretch-r-#{$i}x\@desktop {
        margin-right: (-$gutter/2) * $i;
    }

    // stretch-b = bottom negative margins
    .grid--stretch-b-#{$i}x\@desktop {
        margin-bottom: (-$gutter/2) * $i;
    }

    // stretch-l = left negative margins
    .grid--stretch-l-#{$i}x\@desktop {
        margin-left: (-$gutter/2) * $i;
    }
}
}