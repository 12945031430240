// ========================================================
// RECIPE TEMPLATES
// ========================================================

.recipe-filter-range .range {
	line-height: 1.1;
}

.recipe-filter-range label {
	font-size: .9rem;
}

@media screen and (min-width: $view-desktop) {
	.recipe-filter-range label {
		width: 35%;
		vertical-align: middle;
		text-align: right;
    	padding-right: $gutter;
	}

	.recipe-filter-range > *:first-child label {
		width: auto;
	}

	.recipe-filter-range > *:last-child {
		padding-right: $gutter*1.5;
	}


	.recipe-filter-range .range {
		width: calc(75% - 112px);
		display: inline-block;
		vertical-align: middle;
	}
}