// ========================================================

// # PRINT


@media print {
    * { background: transparent !important; box-shadow: none !important; color: #000 !important; text-shadow: none !important; }
	aside, 
	audio, 
	button, 
	canvas, 
	embed, 
	footer, 
	form, 
	header, 
	menu, 
	object, 
	video,
	label.card .card__inner:after,
	.section--jagged:after,
	.no-print { 
		display: none !important; 
	}


	.hero {
		height: auto !important;
		min-height: auto !important;
		max-height: none;
	}

	.hero__image {
		height: 200px;
		margin-top: 20px;
    	max-width: 250px;
	}
	
	.arrow {
		display: none;
	}

	.product-info .grid--col {
		flex-direction: column;
	}

	.product-info--nutrition-bg {
		margin-top: -$gutter*3;    
		padding-left: 20px;
	}

	.product-info__product-titel {
		padding: 0;	
		margin-bottom: 20px;
	}

	.product-info__packshot {
		position: static;
		transform: none;
		width: 150px;
		padding-top: 20px;
	}

	.product-info__core {
		padding-left: 10px;
	}

	.section--jagged {
		padding: 0 !important;
	}

	.grid--col-max-4>* {
		max-width: 25%;
        flex-basis: 25%;
	}

	a[href]:after { content: " (" attr(href) ") "; font-size: 90%; }
	a[href^="/"]:after { content: " (http://" attr(href) ") "; }
	abbr:after { content: " (" attr(title) ")"; }
	thead { display: table-header-group; }
	tr, img, pre, blockquote { page-break-inside: avoid; }
	img { max-width: 100% !important; }
	h1, h2, h3, h4, h5, h6 { page-break-after: avoid; }
	h2, h3, p { orphans: 3; widows: 3; }
	@page { margin: .5cm; }
}