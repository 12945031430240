// ========================================================
// THEME
// ========================================================


// theme red

.theme--red	button {
	border: 1px solid $color-secondary;

	color: $color-red;

	&:before {
		background-color: $color-red;
	}

	&:focus,
	&:active,
	&:hover {
		background-color: $color-red;
		color: $color-secondary;
	}
}

.theme--red .icon--shopping-basket,
.theme--red	.icon--eye-circle {
	fill: $color-red;
}

.theme--red .hero__detail {
	background-image: url('../assets/images/bg-red.png');
}


// theme green

/*.theme--green h1 {
	color: $color-primary;
}

.theme--green h2 {
	color: $color-primary;
}*/


.theme--green button {
	border: 1px solid $color-secondary;

	color: $color-green;

	&:before {
		background-color: $color-green;
	}

	&:focus,
	&:active,
	&:hover {
		background-color: $color-green;
		color: $color-secondary;
	}
}

.theme--green .icon--shopping-basket,
.theme--green .icon--eye-circle {
	fill: $color-green;
}

.theme--green .hero__detail {
	background-image: url('../assets/images/bg-green.png');
}

// theme purple

.theme--purple button {
	border: 1px solid $color-secondary;

	color: $color-purple;

	&:before {
		background-color: $color-purple;
	}

	&:focus,
	&:active,
	&:hover {
		background-color: $color-purple;
		color: $color-secondary;
	}
}
.theme--purple .icon--shopping-basket,
.theme--purple .icon--eye-circle {
	fill: $color-purple;
}

.theme--purple .hero__detail {
	background-image: url('../assets/images/bg-purple.png');
}

// theme blue

.theme--blue button {
	border: 1px solid $color-secondary;

	color: $color-blue;

	&:before {
		background-color: $color-blue;
	}

	&:focus,
	&:active,
	&:hover {
		background-color: $color-blue;
		color: $color-secondary;
	}
}

.theme--blue .icon--shopping-basket,
.theme--blue .icon--eye-circle {
	fill: $color-blue;
}

.theme--blue .hero__detail {
	background-image: url('../assets/images/bg-blue.png');
}

// theme white

.theme--white h2 {
	color: $color-secondary;
}