.pull-up {
	margin-top: -50px;
}

@media screen and (min-width: $view-desktop) {
	.pull-up {
		margin-top: -120px;
	}
	
	.reverse-even > [class*='grid--col'] {
		align-items: center;

		&:nth-child(even) {
			flex-direction: row-reverse;
		}
	}

	.reverse-odd > [class*='grid--col'] {
		align-items: center;

		&:nth-child(odd) {
			flex-direction: row-reverse;
		}
	}
}