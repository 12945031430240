// ========================================================
// LINK LIST
// ========================================================

.list-link {
	display: flex;
	position: relative;
	color: black;
	cursor: pointer;

	&:before {
		display: block;
		position: absolute;
		content: "";
		left: -$gutter*2;
		top: -$gutter;
		bottom: -$gutter;
		right: -$gutter*2;
		background-color: $color-green;
		mask: url('../assets/images/mask-square.png');
		mask-size: 100% 100%;
		mask-position: center; 
		z-index: 1;
		opacity: 0;
		border-radius: 4px;
		transition: opacity $time-fast ease-in-out, transform $time-fast ease-in-out;
	}

	&:hover,
	&:focus,
	&:active {
		color: $color-secondary;
		fill: $color-secondary;

		p {
			color: $color-secondary;
			position: relative;
			z-index: 1;
		}

		&:before {
			opacity: 1;
		}

		.list-link__text li {
			&:before {
				color: $color-secondary;
			} 
		}
	}
}

.list--links {
	margin-top: $gutter/2;
	margin-bottom: $gutter;

	&:last-of-type { 
		margin-bottom: 0;
	}
}

.list--links > li {
	position: relative;
	background-image: url(../assets/images/icons/background-menu-underline-grey.svg);
	background-repeat: no-repeat;
	background-position: center 0;
	background-size: 100% 7px;

	&:first-of-type { 
		background-image: none;
	}
}

.list--links li { 
	padding-top: $gutter*.75;
	padding-bottom: $gutter*.75;
}

.list-link__icon {
	display: block;
	position: relative;
	width: 50px;
	flex-basis: 50px;
	padding-right: $gutter;
	margin-right: $gutter/2;
}

.list-link__icon svg {
	transition: fill $time-fast ease-in-out;
}

.list-link__icon--card {
	width: 60px;
	height: 60px;
	margin-right: $gutter;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	flex-shrink: 0;
	text-align: center;

	img {
		height: 100%;
	}
}

.list-link__icon .icon {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	transition: none;
}

.list-link__icon--card,
.list-link__icon,
.list-link__text {
	z-index: 1;
}

.list-link__text:last-of-type {
	margin: 0;
}

// ========================================================
// LINK LIST ACCORDION
// ========================================================

.list-link--accordion {
	display: block;

	&:hover,
	&:focus,
	&:active {
		.list-link__icon .icon {
			fill: $color-secondary;
		}
	}
}

.list-link--accordion .list-link__text {
	display: flex;
	justify-content: space-between;
	position: relative;
	align-items: center;
}

.list-link--accordion .list-link__icon {
	position: relative;
	right: 0;
	padding-right: 0;
	padding-left: $gutter;
}

.list-link--accordion .list-link__icon .icon {
	fill: $color-primary;
	transition: transform $time ease-in-out;
}

.list-link--accordion .accordion__content {
	position: relative;
	z-index: 1;

	&:before {
		content: '';
		display: block;
		background-image: url('../assets/images/dots-underline.svg');
		height: 10px;
		width: 100%;
		margin-top: $gutter;
		margin-bottom: $gutter;
		background-size: 275px 10px;
		background-repeat: repeat-x;
		background-position: top left;
		background-repeat-y: no-repeat;
	}
}

.accordion--active {
	color: $color-secondary;
	fill: $color-secondary;

	&:before {
		opacity: 1;
	}

	.list-link__icon .icon {
		fill: $color-secondary;
	}

	.list-link__icon .icon {
		transform: rotate(-180deg) translateY(50%);
	}
}

