// ========================================================
// GRADIENT
// ========================================================

.gradient {
    &:before {
        content: '';
        background-image: linear-gradient(360deg, rgba(46,46,46,.5) 0,rgba(46,46,46,0) 50%);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}
