.product--purple {
	.svg__product-bg {
		fill: #901558;
	}

	.svg__product-underline {
		fill: #901558;
	}
}

.product--red {
	.svg__product-bg {
		fill: #C8102E;
	}

	.svg__product-underline {
		fill: #C8102E;
	}
}

.product--blue {
	.svg__product-bg {
		fill: #5EA6C6;
	}

	.svg__product-underline {
		fill: #5EA6C6;
	}
}

.product--green {
	.svg__product-bg {
		fill: #92C020;
	}

	.svg__product-underline {
		fill: #92C020;
	}
}

.svg__product-bg {
    transition: opacity $time-fast ease-in-out, transform $time-fast ease-in-out;
    transform: scale(0.1);
}

.svg__product-underline {
    transition: opacity $time ease-in-out;
    opacity: 0;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    //clip: rect(0px,60%,200%,0px);
}

