.breadcrumb {
    list-style: none;
    padding: 0;
    line-height: 1;
    font-size: 1rem;    
}

.breadcrumb li {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    color: $color-primary;
    position: relative;
    padding: $gutter/4 $gutter*1.25 $gutter/4 0; 
    margin-right: $gutter/2;

    
    &:not(:last-of-type):after { 
        content: '\e901';
        @extend .icon-font;
        position: absolute;
        vertical-align: middle;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        display: inline-block;
        color: $color-dark-grey; 
    }
}

.breadcrumb li:first-of-type a {
    padding-left: 0;;
}

.breadcrumb a {
    text-decoration: none;
    color: $color-primary;

    &:hover,
    &:active,
    &:focus {
        text-decoration: underline;
    }
}
