// ========================================================
// LIST
// ========================================================


.list {
    list-style: none;
    list-style-image: none;
    margin: 0;
    padding: 0;
}

.list--ingredients {
	margin-bottom: $gutter*2;
}

.list--ingredients li {
	position: relative;
	padding-left: $gutter*2;

	&:before {
		@extend .icon-font;
		content: '\e901';
		color: white;
		display: inline-block;
		position: absolute;
		left: 0;
		top: $gutter/4;
	}
}

.list--inline li {
	display: inline-block;
	vertical-align: middle;
}


.list--links li {
	margin: 0;
}