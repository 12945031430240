// ========================================================
// ICON
// ========================================================

.icon {
    display: inline-block;
    vertical-align: middle;
    height: 22px;
    width: 22px;
    transition: fill $time-fast ease-in-out, transform $time-fast ease-in-out;
}

.icon--inline {
    margin-right: $gutter/2;
}

.icon--facebook-footer,
.icon--close,
.icon--prev-arrow,
.icon--next-arrow {
    height: 20px;
    width: 20px;
}

.icon--facebook-menu {
    height: 15px;
    width: 15px;
    margin-top: -3px;
}

.icon--prev-arrow {
    transform: rotate(180deg);
}

.icon--turn {
    height: 25px;
    width: 25px;
}

.icon--youtube-footer {
    height: 21px;
    width: 30px;
}

.icon--product-info {
    margin-right: $gutter/2;
    fill: $color-secondary;
}

.icon--eye {
    width: 50px;
    height: 50px;
}

.icon--eye-circle {
    height: 70px;
    width: 70px;
    font-family: $font-primary;
    backface-visibility: hidden;
}

.icon--shopping-basket {
    height: 25px;
    width: 25px;
}

.icon--zoomer {
    width: 65px;
    height: 65px;
}

.icon--overview {
    width: 30px;
    height: 30px;
}