// ========================================================
// # FOOTER CTA

.footer-cta {
	background-size: cover;
	mask: url('../assets/images/mask-top.png');
  	//mask-size: 100% 100%;
  	mask-position: top;
}

.footer-cta__content {
	color: #fff;
	max-width: 470px;
	text-align: center;
	width: 100%;
	margin: auto;
}

.footer-cta__content a {
	margin-top: $gutter;
}

.footer-cta h1,
.footer-cta h2,
.footer-cta p {
	text-shadow: 1px 1px rgba(0,0,0,.5);
}

.footer-cta p {
	font-weight: bold;
}