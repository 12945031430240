// ========================================================
// CATEGORY
// ========================================================

.categories {
	margin-bottom: $gutter *2;
}
.categories h1 {
	text-align: center;
	margin-bottom: $gutter *2;
	color: $color-primary;
}

.categories ul,
.categories .content-box {
	padding: 0;
}


.category {
	position: relative;
	list-style: none;
	color: #fff;
	padding: $gutter/2;
	margin-bottom: $gutter;
	margin-top: $gutter * 1.5;

}

.category a {
	text-decoration: none;
	color: #fff;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	transition: box-shadow $time-fast ease-in-out;

	&:hover,
	&:active,
	&:focus {
		box-shadow: 0px 0px 35px #858585;

		.category__label {
			transform: rotate(-6deg) translate(-50%, -50%);
		}
	}
}

.category__label {
	width: 100%;
	max-width: 225px;
	position: absolute;
	left: 50%;
	transform : translate(-50%, -50%);
	transform-origin: 0;
	transition: transform $time-fast ease-in-out;
}

.category__avatar {
	background-size: cover;
	background-repeat: no-repeat;
	height: 250px;
	border-top-left-radius: 4px;
  	border-bottom-left-radius: 4px;
}

.category p {
	width: 100%;
	background-image: url('../assets/images/bg-main-green.png');
	background-size: cover;
	background-repeat: no-repeat;
	padding: $gutter;
	margin-top: -20px;
	flex: 1 0 auto;
	mask: url('../assets/images/mask-top.png');
  	mask-position: top;
  	border-bottom-left-radius: 4px;
  	border-bottom-right-radius: 4px;
}
