// ========================================================
// NESTLE FOOTER
// ========================================================

.nestle-footer {
    position: relative;
	background-color: #FFF;
	font-size: 0.9em;
}

.nestle-footer__wrapper {
	max-width: 97.5rem;
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;

	&:after {
		content: '';
		display: block;
		border-bottom: solid 1px #d1d1d1;
		margin-bottom: 10px;
	}
}

.nestle-footer li {
	display: block;
	padding: 0 $gutter/2;
	// float: left;
	// width: 50%;
	// padding-bottom: $gutter/2;
	margin-right: 0;
}

.nestle-footer li a {
	text-decoration: none;
	color: #000;
}

.nestle-footer__logo {
	display: inline-block;
	position: static;
	width: 80px;
	z-index: 2;
	bottom: 0;

    &:after {
        content: none;
    }
}

.nestle-footer__logo img {
	margin: 0;
}


@media screen and (min-width: $view-tablet-landscape) {
    .nestle-footer__wrapper {
        padding-left: 190px;
        flex-direction: row;

        &:after {
	        display: none;
	    }
    }

    .nestle-footer li {
		float: left;
		width: 50%;
	}

    .nestle-footer__logo {
        width: auto;
        position: absolute;
    }

    .nestle-footer li {
    	margin-right: $gutter;
        width: auto;
        padding-bottom: 0;
    }

    .nestle-footer__social-list {
		margin-left: auto;
	}
}
