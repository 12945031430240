// ========================================================
// # BUTTONS

.button,
button,
.btn {

    font-weight: 500;
    display: inline-block;
    width: auto;
    color: #FFF;
    font-size: .9375rem;
    padding: $gutter/1.25;
    text-decoration: none;
    border: none;
    border-radius: $radius-border;
    transition: $time-fast ease-in-out !important;
    transition-property: background-color box-shadow color transform border-radius border-color;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-right: $gutter/2;
    margin-top: $gutter/2;
    line-height: 1.2;
    text-transform: uppercase;
    will-change: transform;
    -webkit-font-smoothing: antialiased; 
    -webkit-backface-visibility: hidden;
    letter-spacing: .1em;


    &:after {
        content: none;
    }

    &:before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        padding-bottom: 100%;
        transform: scale(0) translate(-50%, -50%);
        transform-origin: 0 0;
        background-color: $color-green;
        left: 50%;
        top: 50%;
        border-radius: 100%;
        transition: transform $time-fast ease-in-out;
        z-index: -1;
    }

    &:focus,
    &:active,
    &:hover {
        background-color: $color-green;
        box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
        color: #fff;
        transform: scale(1) rotate(0) translateZ(0);
        fill: currentColor;
        -webkit-font-smoothing: antialiased; 
        
        &:before {
            transform: scale(1) translate(-50%, -50%);
        }
    }
}

.btn .icon {
    fill: currentColor;
}

button,
.btn--primary {
    background-color: $color-primary;

    &:focus,
    &:active,
    &:hover {
        color: #fff;
    }
}

.btn--secondary {
    background-color: $color-secondary;
    color: $color-primary;
    

    .icon {
        fill: $color-primary;
    }

    &:before {
        background-color: $color-primary;
    }

    &:focus,
    &:active,
    &:hover {
        background-color: $color-primary;
        
        .icon {
            fill: #FFF;
        }

    }
}

.btn--tertiary {
    border: 1px solid $color-secondary;
    color: $color-secondary;
    fill: $color-secondary;
    background-color: rgba(0,0,0,0);
    text-transform: none;
    padding: $gutter/2;
    vertical-align: middle;

    svg {
        width: 20px;
        height: 20px;
    }

    &:hover,
    &:active,
    &:focus {
        border: 1px solid $color-green;
    }
}

.btn--ghost {
    border: 1px solid $color-green;
    color:$color-green;
    fill: currentColor;
    background-color: rgba(0,0,0,0);
    text-transform: none;
    padding: $gutter/2;
    vertical-align: middle;

    svg {
        width: 20px;
        height: 20px;
    }

    &:focus,
    &:active,
    &:hover {
        color: $color-secondary;
        background-color: $color-green;
    }
}


.btn--wide {
    font-weight: 500;
    width: 100%;
    display: block;
    text-align: center;

    &:before {
        content: none;
    }

    &:hover,
    &:active,
    &:focus {
        transform: scale(1) rotate(0) translateZ(0);
    }
}

.btn--pulse {
    width: 30px;
    height: 30px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    margin: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &:hover,
    &:focus,
    &:active {
        &:before,
        &:after {
            content: '';
            border: 4px solid #fff;
            border-radius: 100%;
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            animation: pulsate $time-slow*2 infinite ease-out;
            opacity: 0;
        }

        &:after {
            animation: pulsate $time-slow*2 $time*2 infinite ease-out;
        }
    }
}

.btn--lazy-loader {
    margin: 0 auto;
    display: flex;
    margin-top: $gutter;
}

@keyframes pulsate {
    0% { transform: scale(.8); opacity: 0;  }
    50% { opacity: 1; }
    100% { transform: scale(1.8); opacity: 0; border: 0 solid #fff; }
}

.btn--arrow {
    background-image: url('../assets/images/white-sketch-arrow.png');
    height: 57px;
    width: 83px;
    transition: transform $time-fast ease-in-out, bottom $time-fast ease-in-out;

    &:hover,
    &:focus,
    &:active {
        transform: translateY(20%) rotate(5deg); 
    }
}


// ========================================================
// DESKTOP
// ========================================================

@include respond-min-width($view-desktop){

    button,
    .btn {

        &:hover,
        &:active,
        &:focus {
            transform: scale(1.1, 1.1) rotate(-2deg) translateZ(0);

            &:before {
                transform: scale(1.5) translate(-50%, -50%);
            }
        }
    }

    .btn--wide {

        &:hover,
        &:active,
        &:focus {
            transform: scale(1) rotate(0) translateZ(0);
        }
    }

} // END MEDIA
