// ========================================================
// SHARE NAV
// ========================================================

.share-nav {
	display: inline-block;
	color: #FFF;
	font-weight: 500;
	vertical-align: top;
	margin-right: $gutter/2;
    margin-top: $gutter/2;
}

.share-nav__inner {
	padding: $gutter/2.5 $gutter/1.25;
	background-color: $color-green;
	border-radius: $radius-border;
}

.share-nav__text:last-of-type {
	margin-top: 0;
	margin-right: $gutter;
}

.share-nav__text,
.share-nav__list {
	display: inline-block;
	vertical-align: middle;
}

.share-nav__list {
	line-height: 0;
}

.share-nav__link {
	fill: #FFF;

	&:hover,
	&:focus,
	&:active {
		fill: $color-primary;
	}
}

.share-nav li {
	margin: 0 $gutter/2 0 0;
	
	&:last-of-type {
		margin-right: 0;
	}
}

.share-nav svg {
	height: 19px;
    width: 19px;
}