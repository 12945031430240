// ========================================================
// IMAGE
// ========================================================

.image__leafs {
	display: none;
}


.image--large {
 	width: 300px;
} 

@media only screen and (min-width: $view-tablet-landscape) {
	.image__leafs {
		display: block;
	    position: absolute;
	    z-index: 5;
	    width: 200px;
	}
}