// ========================================================
// FOOTER
// ========================================================

.footer {
	color: $color-secondary;
	background-repeat: no-repeat;
	background-size: cover;
	mask: url('../assets/images/mask-top.png');
	mask-size: 100% 100%;
	mask-position: top;
	mask-repeat: no-repeat;
	margin-top: -$gutter;
	z-index: 2;
	padding-top: $gutter/2;
}

.footer__column--social .btn,
.footer__column--social li {
	margin-top: 0;
}

.footer__column--social .btn svg {
	margin-right: $gutter/2;
}

.footer__column:first-child {
	padding-bottom: $gutter;
}

.footer__grid--col-max-3 {
	width: 100%;
}

.footer__column--products,
.footer__column--links {
	padding-right: $gutter/2;

	a {
		color: $color-secondary;

		&:hover,
		&:active,
		&:focus {
			color: $color-green;
		}
	}
}

.footer__column:nth-child(2) {
	padding-top: $gutter;
	position: relative;
	//border-top: 1px solid white;
}

.footer h3 {
	margin-bottom: $gutter;
}

.footer__mail {
	margin-top: $gutter;
}

.footer__mail fieldset {
	position: relative;
	width: 100%;
	height: 50px;
}

.footer__mail input {
	width: 100%;
	display: inline-block;
	background-color: rgba(0,0,0,0);
	color: $color-secondary;
	height: 100%;
	border: 1px solid $color-green;
	padding-right: $gutter * 5;
}


.footer__mail .btn {
	position: absolute;
	right: 0;
	top: 0;
	margin: 0;
	z-index: 1;
	background-color: $color-green;
	white-space: nowrap;

	&:before {
		background-color: $color-secondary;
	}

	&:hover,
	&:active,
	&:focus {
		color: $color-green;
		background-color: $color-secondary;
	}
}


@media screen and (min-width: $view-desktop) {

	.footer__column:first-child {
		padding-bottom: 0;
		padding-right: $gutter * 2;
	}


	.footer__column--social .btn svg {
		margin-right: 0;
	}

	.footer__column--products li,
	.footer__column--links li {
		margin-top: $gutter/8;
		margin-bottom: $gutter/8;
	}

	.footer__column:nth-child(2) {
		padding-top: 0;
		padding-left: $gutter * 2;

		&:before {
			content: "";
			height: 100%;
			width: 7px;
			background-image: url('../assets/images/icons/background-menu-underline-white-vert.svg');
			background-size: 7px 100%;
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			background-position: top;
			background-repeat: no-repeat;
		}
	}

}