// ========================================================
// # PAGE

.page {
    position: relative;
    transition: transform $time ease-in-out, box-shadow $time ease-in-out;
    transform-origin: -350% center;
    backface-visibility: hidden;
    // background-color: #fff;
    overflow: hidden;
    
    &:before {
        content: '';
        position: absolute;
        top: 500px;
        left: 0;
        width: 100%;
        bottom: 0;
        background-image: linear-gradient(to bottom, rgba(255,255,255,1) 35%,rgba(255,255,255,0) 65%);
    }

    &:after {
        content: '';
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/images/bg-white-chalk.png');
        background-repeat-y: no-repeat;
        background-position: bottom;
        background-size: 120%;
        z-index: -2;
    }
}

.page__content {
    padding-top: 78px;
    backface-visibility: hidden;
    

    -ms-overflow-style: none;  // IE 10+
    overflow: -moz-scrollbars-none !important;  // Firefox

    &::-webkit-scrollbar { 
        display: none;  // Safari and Chrome
    }
}

// ========================================================
// DESKTOP
// ========================================================

@media screen and (min-width: 64em) {
    .page__content {
        padding-top: 130px;
    }
}
