// ========================================================
// # COUNTRY SWITCH

.country {
	position: relative;
}

.country .menu__sub {
	position: absolute;	
	width: 250px;
	right: 0;
	left: auto;
	transform: translateY(5px);

	&:before {
		content: "";
		position: absolute;
		bottom: 100%;
		right: $gutter;
		left: auto;
		transform: none;
		width: 0;
		height: 0;
		border-left: 9px solid transparent;
		border-right: 9px solid transparent;
		border-bottom: 9px solid $color-secondary;
	}
}

.country .menu__sub__list {
	padding: 0;
}

.country .menu__sub__list li {
	color: #000;
	display: block;
	margin-right: 0;
	
	&:not(:last-of-type) {
		a {
			margin-bottom: $gutter/2;
		}
	}
}


.country .menu__sub__list a {
	color: #000;
	text-align: left;
	padding: $gutter/4;
}

.country .menu__sub__list > li > a  {
	padding: 0;
	
	&:not(:first-of-type) {
		margin-left: 30px;
	}
}

.country input:checked ~ .menu__sub {
	transition: transform $time ease-in-out, visibility 0s, opacity $time ease-in-out;
	transform: translateY(0);
	opacity: 1;
	visibility: visible;
}

.background-menu .country .menu__sub {
	padding: $gutter;
	bottom: 100%;
	top: auto;
	transform: translateY(-20px);

	&:before {
		content: "";
		position: absolute;
		top: 100%;
		bottom: auto;
		right: $gutter;
		left: auto;
		transform: none;
		width: 0;
		height: 0;
		border-left: 9px solid transparent;
		border-right: 9px solid transparent;
		border-top: 9px solid $color-secondary;
		border-bottom: 0;
	}
}

.background-menu .country input:checked ~ .menu__sub {
	transform: translateY(-10px);
}


.country .avatar--small {
	margin-right: $gutter/2;
	border-color: $color-primary;
}


