// ========================================================
// Carousel
// ========================================================

.carousel {
    // overflow: hidden;
    padding: $gutter*4 $gutter/2 $gutter*2 $gutter/2;
}

.carousel__inner {
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.carousel__item {
    color: #fff;
    background-image: url('../assets/images/bg-main-green.png');
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 6px;
    transform: scale(1) rotate(25deg) translateY(100%);
    opacity: 0;
    background-size: cover;
    transform-origin: left top;
    transition: $time ease-in-out;
    transition-property: transform, left, top, opacity;
    will-change: transform;
}

.carousel__inner .carousel__item--active {
    z-index: 3;
    opacity: 1;
    transform: scale(1) rotate(0) translateY(0);
    position: relative;
}

.carousel__inner .carousel__item--active + li,
.carousel-item--lineup-first {
    top: -15px;
    left: 12px;
    transform: scale(0.98) rotate(-1deg) translateY(0);
    opacity: .5;
    z-index: 2;
}

.carousel__inner .carousel__item--active + li + li,
.carousel-item--lineup-second {
    top: -20px;
    left: 25px;
    transform: scale(0.96) rotate(-2deg) translateY(0);
    opacity: .2;
    z-index: 1;
}

.carousel__text {
    opacity: 0;
    padding: $gutter/2;
    //text-shadow: 1px 2px rgba(0, 0, 0, 0.5);
}

.carousel__text .btn {
    margin-top: $gutter;;
}

.carousel .button {
    background-color: transparent;
    border: 1px solid #fff;
    margin-bottom: $gutter/2;
    text-shadow: none;

    &:hover,
    &:focus,
    &:active {
        color: $color-primary;
        background-color: #fff;
    }
}

.carousel__img-container {
    position: relative;
    margin-bottom: $gutter * 1.5;
}

.carousel__images {
    position: relative;
    padding-bottom: 107%;
    background-size: cover;
    margin: (-$gutter*2) (-$gutter) 0;
    transform: rotate(-6deg);
    mask: url('../assets/images/mask-square.png');
    mask-size: 100% 100%;
    mask-position: center;
}

.carousel__images img {
    opacity: 0;
    margin: 0;
    position: absolute;
}

.carousel__images--1 {
    position: absolute;
    top: -$gutter * 1.5;
    right: $gutter/2;
    width: 130px;
}

.carousel__images--2 {
    width: 150px;
    top: $gutter*1.5;
    left: $gutter*1.5;
}

.carousel__dot-list {
    position: absolute;
    // top: 30rem;
    z-index: 6;
    // width: 100%;
    text-align: center;
    
    top: 18rem;
    left: -$gutter;
    width: auto;
    transform: rotate(90deg);
}

.carousel__dot-list li {
    display: inline-block;
    border: 2px solid #fff;
    margin: 0 $gutter/2;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    position: relative;
    cursor: pointer;

    &:before {
        content: '';
        background-color: #fff;
        position: absolute;
        top: 2px;
        right: 2px;
        bottom: 2px;
        left: 2px;
        border-radius: 100%;
        transform: scale(0);
        transition: transform $time ease-in-out;
    }
}

.carousel__dot-list .carousel__item--active:before {
    transform: scale(1);
}

.carousel__direction {
    position: absolute;
    top: $gutter*8;
    // left: $gutter;
    right: $gutter/2;
    z-index: 6;
    display: flex;
    justify-content: space-between;
    
    top: 16rem;
    left: auto;
    transform: rotate(90deg);

}

.carousel__direction button {
    position: relative;
    background-color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: $gutter;
}

.carousel__direction button .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    width: 20px;
    height: 20px;
    transform: translate(-50%, -50%) rotate(-90deg);
}


.carousel__direction-btn--prev,
.carousel__direction-btn--next {

    .icon {
        fill: $color-primary;
    }

    &:hover,
    &:active,
    &:focus {
        .icon {
            fill: $color-secondary;
        }
    }
}

.carousel-scroller__direction-icon--next,
.carousel-scroller__direction-icon--prev {
    width: 15px;
    height: 15px;
}


/* NO JAVASCRIPT OVERWRITE */
.no-js .carousel {
    padding: $gutter;
}

.no-js .carousel__dot-list,
.no-js .carousel__direction {
    display: none;
}

.no-js .carousel__item {
    position: static;
    height: auto;
    width: 100%;
    transform: scale(1) rotate(0) translateY(0);
    opacity: 1;
    margin-bottom: $gutter*2;
}

.no-js .carousel__text {
    position: relative;
    opacity: 1;
}

.no-js .carousel__images img {
    opacity: 1;
}


@media only screen and (min-width: 480px) {
    .carousel__images {
        padding-bottom: 0;
        height: 445px;
        margin: (-$gutter) auto 0;
    }
}


@media only screen and (min-width: $view-tablet-landscape) {
    .carousel {
        padding-left: $gutter/2;
        padding-right: $gutter*1.5;
        position: relative;
    }

    .carousel .content-box {
        padding: 0;
    }

    .carousel__text {
        padding: $gutter $gutter*3 $gutter $gutter;
        width: 47%;
        float: right;
        height: 530px;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .carousel__img-container {
        width: 48%;
        height: 103%;
    }

    .carousel__images {
        position: absolute;
        left: $gutter;
        width: 100%;
        height: 570px;
        top: -$gutter*2;
        margin: 0;
    }

    .carousel__images--1 {
        width: 232px;
        top: -$gutter * 3;
    }

    .carousel__images--2 {
        width: 240px;
        top: auto;
        bottom: $gutter*1.5;
        left: $gutter*1.5;
    }

    .carousel__dot-list {
        padding-top: $gutter*2;
        width: 15px;
        top: 50%;
        left: $gutter*2;
        transform: translateY(-50%);
    }

    .carousel__dot-list li {
        margin: $gutter/4 0;
    }

    .carousel__direction button {
        width: 60px;
        height: 60px;
        margin: $gutter/4 0;
    }

    .carousel__direction {
        padding-top: $gutter*2;
        position: absolute;
        top: 50%;
        left: auto;
        right: 7px;
        flex-direction: column;
        transform: translateY(-50%);
    }

    .carousel__direction button .icon {
        // width: auto;
        // height: auto;
        transform: translate(-50%, -50%) rotate(0);
    }
}
