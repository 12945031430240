// ========================================================
// PRODUCT TABLE
// ========================================================

table {
    position: relative;
    min-width: 500px;
}

th {
    text-transform: capitalize;
    padding: $gutter/2 $gutter;
    border-right: 1px solid #fff;
    width: 125px;

    &:first-of-type {
        padding-left: 0;
        white-space: no-wrap;
    }

    &:last-of-type {
        border-right: none;
    }
}

tr {
    padding-right: $gutter;
    border-bottom: 1px solid #fff;

    &:last-of-type {
        border-bottom: none;
    }
}

td {
    border-right: 1px solid #fff;
    padding: $gutter/2 $gutter;

    h3 {
        margin-bottom: $gutter/2;
    }

    &:first-of-type {
        padding-left: 0;
        font-weight: 700;
    }

    &:last-of-type {
        border-right: none;
    }
}
