// ========================================================
// PARALAX
// ========================================================

.paralax__visual {
	display: none;
}

.paralax__holder {
	position: relative;
}


.paralax__holder--nutrition {
	right: -30%;
	
	img {
		margin-left: auto;
	}
}


@media only screen and (min-width: $view-tablet-landscape) {

	.paralax__visual {
		display: block;
	    position: absolute;
	    z-index: 5;
	    width: 200px;
	}

	.paralax__visual--large {
		width: 300px;
	}


	.paralax__visual--small {
		width: 150px;
	}

}
