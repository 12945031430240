// ========================================================
// # FLIP

/* entire container, keeps perspective */
.flip {
	perspective: 1000px;
	position: absolute;
	right: $gutter/2;
	margin-bottom: -$gutter;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
}

.lightbox .flip {
	position: relative;
	width: 100%;
	max-width: 350px;

}


.flip,
.flip__front,
.flip__back {
    width: 100%;
	height: 430px;
}

.flip__front,
.flip__back {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
}

.flip img {
    max-width: 345px;
    max-height: 400px;
}

.flip__container {
	transition: transform $time-slow ease-in-out;
	transform-style: preserve-3d;
	backface-visibility: visible;
	position: absolute;
	bottom: 0;
	width: 100%;
}

.flip__front,
.flip__back {
	transform-style: preserve-3d;
	backface-visibility: hidden;
	-ms-backface-visibility: visible;
	position: absolute;
	bottom: 0;
	left: 0;
	transition: opacity 0.1s $time-slow/2;
}

.flip__front {
	opacity: 1;
	transform: rotateY(0deg);
}

.flip__back {
	opacity: 0;
	transform: rotateY(180deg);
}


.flip__nav button,
.flip__nav .btn {
    text-align: center;
    margin: 0 $gutter/3;
}

.flip__nav svg {
	margin-right: $gutter/2;
}

.flip__nav {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: -$gutter*3;
    left: 0;
    right: 0;
    max-width: 345px;
    margin: 0  auto;
    width: 100%;
}

#flip-toggle:checked ~ .flip__container {
	transform: rotateY(180deg);

	.flip__front {
		opacity: 0;
	}

	.flip__back {
		opacity: 1;
	}
}

label[for="flip-toggle"]:after {
	content: '';
    display: inline-block;
    vertical-align: middle;
}

#flip-toggle:checked ~ .page label[for="flip-toggle"]:after,
#flip-toggle:checked ~ .lightbox label[for="flip-toggle"]:after {
	content: '';
}

.flip-close__btn {
    margin-right: auto;
    margin-left: auto;
    display: block;
}

@include respond-min-width($view-desktop) {


	.flip {
		perspective: 1000px;
		right: $gutter*2;
		bottom: -$gutter;
        top: inherit;
	}

	.flip,
	.flip__front,
	.flip__back {
		width: 345px;
		height: 450px;
	}

	.lightbox .flip,
	.lightbox .flip__front,
	.lightbox .flip__back {
		width: 100%;
	}

	.lightbox .flip img {
		max-width: none;
		max-height: 520px;
	}

    label[for="flip-toggle"]:after {
        content: '';
    }

    #flip-toggle:checked ~ .page label[for="flip-toggle"]:after,
    #flip-toggle:checked ~ .lightbox label[for="flip-toggle"]:after {
        content: '';
    }
}
