// ========================================================
// OVERLAY
// ========================================================

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    max-height: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 6;
    transition: opacity $time ease-in-out, max-height 0s $time;
}


// SUB-MENU
.modal-active .overlay {
    opacity: 1;
    max-height: 100%;
    transition: opacity $time ease-in-out, max-height 0s;
}
