// ========================================================

// # FONTS

// ========================================================

// # FONTS

@font-face {
	font-family: 'a_song_for_jennifer';
	src: url('../assets/fonts/a_song_for_jennifer.eot');
	src: url('../assets/fonts/a_song_for_jennifer.woff') format('woff'),
	     url('../assets/fonts/a_song_for_jennifer.ttf') format('truetype'),
	     url('../assets/fonts/a_song_for_jennifer.svg') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'a_song_for_jennifer';
	src: url('../assets/fonts/a_song_for_jennifer_bold.eot');
	src: url('../assets/fonts/a_song_for_jennifer_bold.woff') format('woff'),
	     url('../assets/fonts/a_song_for_jennifer_bold.ttf') format('truetype'),
	     url('../assets/fonts/a_song_for_jennifer_bold.svg') format('svg');
	font-weight: 700;
	font-style: normal;
}

@font-face {
    font-family: 'flama';
    src: url('../assets/fonts/flama-light.eot');
    src: url('../assets/fonts/flama-light.woff') format('woff'),
         url('../assets/fonts/flama-light.ttf') format('truetype'),
         url('../assets/fonts/flama-light.svg') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'flama';
    src: url('../assets/fonts/flama-basic.eot');
    src: url('../assets/fonts/flama-basic.woff') format('woff'),
         url('../assets/fonts/flama-basic.ttf') format('truetype'),
         url('../assets/fonts/flama-basic.svg') format('svg');
    font-weight: 300;
    font-style: normal;
}


@font-face {
    font-family: 'flama';
    src: url('../assets/fonts/flama-medium.eot');
    src: url('../assets/fonts/flama-medium.woff') format('woff'),
         url('../assets/fonts/flama-medium.ttf') format('truetype'),
         url('../assets/fonts/flama-medium.svg') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'flama';
    src: url('../assets/fonts/flama-semibold.eot');
    src: url('../assets/fonts/flama-semibold.woff') format('woff'),
         url('../assets/fonts/flama-semibold.ttf') format('truetype'),
         url('../assets/fonts/flama-semibold.svg') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'iconfont';
    src:url('../assets/fonts/iconfont.eot?pgvi0t');
    src:url('../assets/fonts/iconfont.eot?#iefixpgvi0t') format('embedded-opentype'),
        url('../assets/fonts/iconfont.woff?pgvi0t') format('woff'),
        url('../assets/fonts/iconfont.ttf?pgvi0t') format('truetype'),
        url('../assets/fonts/iconfont.svg?pgvi0t#iconfont') format('svg');
    font-weight: bold;
    font-style: normal;
}

.icon-font {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'iconfont' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
// Typography
$font-primary: 'flama', sans-serif;
$font-secondary: 'a_song_for_jennifer', sans-serif;
