// ========================================================
// PRODUCT INFO
// ========================================================

.breadcrumb:last-of-type {
	margin-top: 0;
	margin-bottom: $gutter * 1.5;
}

.product-info__video {
	padding-top: 56%;
	position: relative;
	display: block;
	transform: rotate(-3deg);
	margin: $gutter*2 0;

	mask: url('../assets/images/mask-square.png');
  	mask-size: 100% 100%;
  	mask-position: center;

	&:after {
		content: '';
		background: url('../assets/images/icons/play-button-circle.svg');
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		height: 70px;   
		width: 70px;
		transform-origin: 0 0;
		transition: transform $time-fast ease-in-out;
	}
	
	&:hover,
    &:focus,
    &:active {
    	&:after {
    		transform: rotate(-6deg) translate(-50%, -50%);
    	}
    }
}

.product-info__product-titel {
	position: relative;
	max-width: 366px;
	padding-right: 100px;
	margin-bottom: $gutter * 3;
}

.product-info__product-titel .arrow {
	position: absolute;
	top: $gutter;
	right: $gutter * 3;
	transform: rotate(50deg);
}

.product-info__packshot {
	position: absolute;
	right: $gutter * 2;
	top: 50%;
	max-width: 80px;
	transform: translate(50%, -50%) rotate(4deg);
}

.product-info h2,
.product-info__core h3 {
	color: $color-primary;
	text-transform: uppercase;
}

.product-info__core {
	position: relative;

	&:after {
		content: '';
		display: block;
		background-image: url('../assets/images/dots-underline.svg');
		height: 10px;
		margin-top: $gutter * 1.5;
		margin-bottom: $gutter * 1.5;
		background-size: 275px 10px;
		background-repeat: repeat-x;
		background-position: top left;
		background-repeat-y: no-repeat;
	}

}

.detail-sticker {
	width: 120px;
	float: right;
	margin-left: $gutter;
	margin-bottom: $gutter;
}

.product-info--nutrition-bg {
	padding-top: $gutter * 2;
	padding-bottom: $gutter * 2;
    position: relative;
    color: $color-secondary;

    &:before  {
        content: '';
        top: 0;
        left: -$gutter * 3;
        bottom: 0;
        width: 200%;
        position: absolute;
        mask: url('../assets/images/mask-square.png');
  		mask-size: 100% 100%;
  		mask-position: center;
        background-image: url('../assets/images/bg-main-green.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
}

.product-info--nutrition-bg h3 {
	margin-bottom: $gutter;
}

.product-info .image-leaf {
	position: absolute;
	top: 100%;
	right: 0;
    margin-top: -33px;
}

.product-info--nutrition-bg > * {
	position: relative;
}

.scroll-x {
	position: relative;
}

.scroll-x table {
	display: block;
}

.scroll-x h3 {
	margin: $gutter*1.5 0;
}

.scroll-btn--left,
.scroll-btn--right {
	visibility: hidden;
	opacity: 0.2;
	transition: opacity $time-fast ease-in-out;
	padding: $gutter/2;
}

.btn-visible {
	visibility: visible;
}

.btn-active {
	opacity: 1;
}

.scroll-buttons {
	position: relative;
	z-index: 1;
}

.scroll-buttons__position {
	position: absolute;
	right: 0;
	top: $gutter * 2;
	transform: translateY(-50%);
}


.product-info .grid--col {
	flex-direction: column-reverse;
}

.product-info .image__leafs {
	right: 0;
	bottom: -65px;
}

@media only screen and (min-width: $view-tablet-landscape) {

	.product-info__column:first-child {
		padding-right: $gutter;
	}

	.product-info {
		margin-bottom: $gutter * 2;
	}

	.product-info--nutrition-bg {
		padding: $gutter * 2;
		padding-right: 0;

    	&:before  {
	        left: 0;
	    }
	}
	
	.product-info__product-titel {
		padding-right: 110px;
	}
	
	.product-info__packshot {
		right: $gutter*2;
		max-width: 100%;
	}
}

@media only screen and (min-width: $view-desktop) {

	.product-info__column:first-child {
		padding-right: $gutter * 2;
	}

	.product-info--nutrition-bg {
		padding: $gutter * 3;
		padding-right: 0;
	}


	.product-info .grid--col {
		flex-direction: row-reverse;
	}

}