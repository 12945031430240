// ========================================================

// # HELPERS

.accessibility, .js .js-accessibility { clip: rect(1px, 1px, 1px, 1px); height: 1px; overflow: hidden; position: absolute; width: 1px; }
.accessibility:focus, .accessibility:active { clip: auto; height: auto; overflow: visible; position: fixed; width: auto; }
.group:before, .group:after { content: " "; display: table; }
.group:after { clear: both; }
.fieldset { @extend .group; }
.skip-to-main { left:-999px; position: absolute; top: auto; width: 1px; height: 1px; overflow: hidden; z-index: -9999; }
.skip-to-main:focus, .skip-to-main:active {color: #FFF; background-color: #000; left: 0; top: 0; overflow: auto; z-index: 999; }
.image { margin: 0 }
.text-center { text-align: center; } .text-left { text-align: left;} .text-green { color: $color-primary;}
.scroll-x { overflow-x: auto;  -webkit-overflow-scrolling: touch;}
.image-bg-leaves {background-image: url(../assets/images/green-bg.png); background-size: cover;}
html:not(.no-js) .no-js-only { display: none; }

@media only screen and (max-width: $view-tablet-portrait) {
    .hide-mobile { display: none;}
}