// ========================================================
// # TABS
.tabs {
    font-size: 1rem;
    margin-top: $gutter/2;
}

.tabs__scroll {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 56px;
        left: 0;
        right: 0;
        height: 4px;
        overflow: auto;
        background-color: $color-border;
    }
}

.tabs__list {
    overflow: auto;
    list-style-type: none;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}

.tabs__list li {
    display: inline-block;
    margin: 0;
    position: relative;
    white-space: normal;
    
    &:not(:first-of-type) {
        margin-left: -6px;
    }
}

.tabs__link {
    display: flex;
    color: $color-primary;
    height: 60px;
    width: 140px;
    line-height: 1;
    padding: $gutter;
    text-decoration: none;
    position: relative;
    font-weight: 300;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    box-sizing: content;

    &:before {
        content: '';
        transition: background-color $time-fast ease-in-out;
        bottom: 0;
        left: 0;
        height: 4px;
        width: 100%;
        position: absolute;
        border-radius: $radius-border*2;
    }

    &:hover,
    &:active,
    &:focus {
        color: $color-primary;

        &:before {
            background-color: $color-primary;
        }
    }
}

.tabs__link--active,
.tabs > input:checked + .tabs__link {
    color: $color-primary;

    &:before {
        bottom: 0;
        left: 0;
        content: '';
        height: 4px;
        width: 100%;
        position: absolute;
        background-color: $color-primary;
        border-radius: $radius-border*2;
    }

    &:after {
        bottom: -6px;
        left: 50%;
        content: '';
        height: 14px;
        width: 14px;
        position: absolute;
        margin: 0;
        background-color: $color-primary;
        border-radius: $radius-border / 2;
        transform: rotate(45deg) translate(-50%, 0%);
        transform-origin: 0% 50%;
    }
}

.tabs > input:not(:checked) + .tabs__content {
  display: none;
}

.tabs__content {
  width: 100%;
  margin: $gutter/2 0 0 0;
}
