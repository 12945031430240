// ========================================================

// # MIXINS


$fix-mqW: false !default;
$fix-mqH: false !default;

@mixin respond-min-width($width) {
	@if $fix-mqW {
		@if $fix-mqW >= $width {
			body:after { content:"#{$width}"; }
			@content;	
		}
	}
	@else {
		@media screen and (min-width: $width) {
			body:after { content:"#{$width}"; }
			@content;
		}
	}
}
@mixin respond-max-width($width) {
	@if $fix-mqW {
		@if $fix-mqW <= $width {
			@content;
		}
	}
	@else {
		@media screen and (max-width: $width) {
			@content;
		}
	}
}
@mixin respond-min-height($height) {
	@if $fix-mqH {
		@if $fix-mqH >= $height {
			@content;	
		}
	}
	@else {
		@media screen and (min-height: $height) {
			@content;
		}
	}
}
@mixin respond-max-height($height) {
	@if $fix-mqH {
		@if $fix-mqH <= $height {
			@content;	
		}
	}
	@else {
		@media screen and (max-height: $height) {
			@content;
		}
	}
}

@mixin respond-min-combi($width, $height) {
	@if $fix-mqW {
		@if $fix-mqW >= $width {
			body:after { content:"#{$width}"; }
			@content;	
		}
	} @else if $fix-mqH {
		@if $fix-mqH >= $height {
			@content;	
		}
	}
	@else {
		@media screen and (min-width: $width) and (min-height: $height) {
			body:after { content:"#{$width}"; }
			@content;
		}
	}
}

@mixin respond-max-combi($width, $height) {
	@if $fix-mqW {
		@if $fix-mqW <= $width {
			@content;	
		}
	} @else if $fix-mqH {
		@if $fix-mqH <= $height {
			@content;	
		}
	}
	@else {
		@media screen and (max-width: $width) and (max-height: $height) {
			@content;
		}
	}
}

@function globalPerc($text-size) {
	$global: percentage((100 / 16) * $text-size) / 100;
	@return $global;
}

@function rem($pxval) {
	@if (unitless($pxval)) {
		$pxval: $pxval * 1px;
	}

	@return $pxval / 16 * 1em;
}

@function cols($colcount, $partial: 1) {
	$total: (percentage(100 / $colcount) / 100) * $partial;
	@return $total;
}

@mixin columns($colcount: 1, $partial: 1) {
	width: cols($colcount, $partial);
}

@mixin keyframes($name) {
	@-webkit-keyframes #{$name} {
		@content;
	}
	@-moz-keyframes #{$name} {
		@content;
	}  
	@-ms-keyframes #{$name} {
		@content;
	}
	@-o-keyframes #{$name} {
		@content;
	}  
	@keyframes #{$name} {
		@content;
	}
}

@mixin animation($str) {
	-webkit-animation: #{$str};
	-moz-animation: #{$str};
	-ms-animation: #{$str};
	-o-animation: #{$str};
	animation: #{$str};      
}