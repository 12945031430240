
// ========================================================
// # MENU

.menu {
    font-weight: 300;
    margin: 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
    font-size: .875rem;
}

.menu a {
    color: $color-text;
}

.section--primary {
    position: relative;
    background-color: #fff;
    box-shadow: $box-shadow;
}


.section--secondary {
    position: relative;
    font-size: .75rem;
    
    &:before,
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    &:before {
        background-repeat: repeat;
        background-image: url('../assets/images/bg-main-green.png');
        background-position: center center;
        background-size: cover;
        mask-image: url('../assets/images/mask-bottom.png');
        mask-size: 100% 100%;
        mask-position: center;
    }

    &:after {
        background-color: #FFF;
        z-index: -1;    
    }
}

.menu__brand {
    position: absolute;
    top: $gutter/2;
    left: $gutter;
    transform: scale(1);
    transform-origin: 0 center;
    transition: transform $time-fast ease-in-out;
    z-index: 10;

    &:before {
        content: '';
        background-color: $color-green;
        width: 7px;
        height: 7px;
        transform: translate(-50%, 0) rotate(45deg);
        position: absolute;
        bottom: 35px;
        left: 50%;
        opacity: 0;
        transition: transform $time-fast ease-in-out, opacity $time-fast ease-in-out;
    }

    &:after {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
        content: attr(title);
        background-color: $color-green;
        border-radius: $radius-border;
        color: $color-secondary;
        padding: $gutter/3 $gutter/2 $gutter/2;
        opacity: 0;
        transition: transform $time-fast ease-in-out, opacity $time-fast ease-in-out;
    }

    &:hover,
    &:focus,
    &:active {
        &:before {
            transform: translate(-50%, 15px) rotate(45deg);
            opacity: 1;
        }

        &:after {
            transform: translate(-50%, 15px);
            opacity: 1;
        }
    }
}

    .menu__brand img {
        margin-bottom: 0;
        width: 110px;
        height: 84px;
    }


.menu__nav-inner {
    display: flex;
    align-items: center;
    padding: 0 $gutter;
    height: 78px;
    transition: height $time-fast ease-in-out;
}

.menu__nav-inner--meta {
    display: none;
    height: 55px;
    color: $color-secondary;
    font-weight: 300;
}

.user-scrolled .menu__nav-inner--meta {
    height: 0;
    overflow: hidden;
}

.menu__nav-inner--meta a {
    color: #FFF;
}

.menu__nav-inner--meta .menu__list {
    position: relative;
    z-index: 1;
    margin-left: auto;
}

.menu__nav-inner--meta .menu__list li {
    display: inline-block;
}


.menu__list-fixed {
    display: inline-flex;
    align-items: flex-start;
    margin-left: auto;
}

.menu__list-fixed li {
    margin: 0;
}

.menu__search {
    margin: auto $gutter/1.5;
    background-color: $color-grey;
    padding: $gutter/1.65;
    color: $color-text;
    display: inline-flex;
    align-items: center;
    position: relative;
}

.menu__search__text {
    display: none;
}

.menu__search .icon {
    margin-right: 0;
    width: 18px;
    height: 18px;
}

.menu__nav-inner-item-sub {
    margin: 0;
}

.menu__sub-header {
    display: inline-block;
    font-size: 1.25rem;
}

.menu__list {
    display: none;
    position: relative;
}


.menu__list--meta li {

    &:not(:last-of-type) {
        margin-right: $gutter;
    }
}

.menu__list--meta .icon {
    margin-right: $gutter/2;
}

.menu__list--meta a {
    
    &:hover,
    &:focus,
    &:active {
        opacity: .7;    
    }
}

.menu__nav-item {
    margin:$gutter/1.5;
    position: relative
}

.menu__nav-item .menu__sub__list {
    padding: $gutter/2 $gutter/2 0 $gutter/2;
}

.menu__nav-item .box--solid-primary .menu__sub__list {
    padding: $gutter;
}

.menu__link {
    display: block;
    line-height: 1;
    transform: translateZ(0);
    border-radius: $radius-border;
    padding: $gutter/1.7 $gutter/1.5 $gutter/1.3 $gutter/1.5;
    margin: -$gutter/2;
    font-size: 1.25rem;
    transition: background-color $time-fast ease-in-out, color $time-fast ease-in-out;

    &:hover,
    &:focus,
    &:active,
    &.menu__link--active {
        color: $color-secondary;
        background-color: $color-primary;
    }
}

.menu__link .icon {
    width: 10px;
    height: 10px;
}


.menu__sub {
    position: absolute;
    left: 50%;
    top: 100%;
    margin: $gutter/1.5 0 0;
    padding: 0;
    opacity: 0;
    width: 100%;
    transform: translate(-50%, -10px);
    transition: transform $time ease-in-out, visibility 0s $time, opacity $time ease-in-out;
    visibility: hidden;
    box-shadow: $box-shadow;
    width: 180px;
    overflow-y: auto;
    height: 100%;
    
    &:before {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 0; 
        height: 0; 
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 9px solid $color-primary;
    }
}




.menu .box--solid-secondary {
    position: fixed;
    left: 50%;
    transform: translate(-50%, 5px);
    top: auto;
    max-height: none;
    width: 100%;
    max-width: 85rem;
    padding: $gutter/2;

    &:before {
        content: none;
    }
}

.menu__search-box input {
    border: none;
    background-color: $color-grey;
}

.menu__sub .btn--wide {
    border-radius: 0;

    &:hover,
    &:active,
    &:focus {
        color: $color-primary;
    }
}

.menu__sub .btn--wide .icon {
    margin-left: $gutter/2;
}


.menu__sub__list {
    padding: 0 $gutter;
}

.menu__sub__list li {
    margin: 0;
}

.menu__sub__list a {
    color: $color-secondary;
    display: block;
}

.menu__sub__list .grid--col,
.menu__sub__list a {
    height: 100%;
}

.box--solid-primary .menu__sub__list a {
    font-size: 17px;
}

.box--solid-primary .menu__sub__item-meta {
    text-align: center;
}

.box--solid-primary .menu__sub__item-meta a {
    padding: $gutter/2;
}

.menu__sub__item-meta a {
    padding: 0;
    display: inline-block;
    vertical-align: middle;
}

.menu__sub__list a .box--bg-primary {
    text-align: left;
}

.menu .category__avatar {
    height: auto;
}

.menu .category__label {
    max-width: 200px;
    position: static;
    transform: none;
    margin-bottom: $gutter/2;
}

.menu__close {
    background-color: transparent;
}


.menu__toggle {
    display: flex;
    font-weight: 300;
    align-middle: middle;
    margin: 0;
    padding: $gutter/1.65;
}

    .menu__toggle .icon {
        margin-left: $gutter/2;
        margin-right: 0;
        width: 16px;
        height: 16px;
    }


.menu__target {

    &:checked {
        ~ .page {
            transform: scale(.8);
            box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.5);
        }

        ~ .page .page__content--active {
            -ms-overflow-style: none;  // IE 10+
            overflow: -moz-scrollbars-none;  // Firefox

            &::-webkit-scrollbar { 
                display: none;  // Safari and Chrome
            }
        }

        ~ .page .menu {
            position: absolute;
        }

        ~ .background-menu__overlay {
            z-index: 2;
        }

        ~ .background-menu,
        ~ .background-menu .background-menu__list,        
        ~ .background-menu .background-menu__nav {
            opacity: 1;
            visibility: visible;
        }
    }
}


// states menu
.menu--item-active .menu__sub {
    transition: transform $time ease-in-out, visibility 0s, opacity $time ease-in-out;
    transform: translate(-50%, 14px);
    opacity: 1;
    visibility: visible;
}


.menu--item-active .menu__link .icon {
    transition: transform $time ease-in-out;
    transform: rotate(-180deg) translateZ(0);
}

.menu--item-active .menu__search-box {
    transform: translate(-50%, 5px);
}

.menu--item-active .background-menu__slide-in {
    transform: translateX(15%);
}
.menu--item-active .background-menu__overlay {
    z-index: 1;
}

@media screen and (min-width: $view-tablet-portrait) {
    /*
    .menu__list {
        display: block;
        margin-left: 130px;
        transition: margin-left $time-fast ease-in-out;
    }

    .menu__list .menu__nav-item {
        display: none;

        &:first-of-type,
        &:nth-child(2) {
            display: inline-block;
        }
    } 
    */
}

@media screen and (min-width: $view-tablet-landscape) {
   /* .menu__sub {
        max-height: 300px;
    }

    .menu__search-box {
        max-height: none;
    }

    .menu__nav-inner--meta {
        display: flex;
    }*/
}

@media screen and (min-width: 64em) {

    .menu__sub {
        max-height: 300px;
    }

    .menu__search-box {
        max-height: none;
    }

    .menu__nav-inner--meta {
        display: flex;
    }

    .menu__brand {
        top: -$gutter;
    }

    .menu__brand img {
        width: 180px;
        height: 116px;
    }

    .menu__list {
        display: block;
        margin-left: 200px;
        transition: margin-left $time-fast ease-in-out;
    }

    .user-scrolled .menu__brand {
        transform: scale(.72);
        top: -$gutter/2;
    }

    .user-scrolled .menu__list {
        margin-left: 150px;
    }  

    .menu__nav-inner--meta .menu__list {
        margin-left: auto;
    }

    .menu__link {
        font-size: 1.2em;
        padding: $gutter/2;
    }

    .menu__list .menu__nav-item {
        display: inline-block;
        margin-left: $gutter/2;
        margin-right: $gutter/2;
    }
}

@media screen and (min-width: 90em) {
    .menu__list {
        margin-left: 220px;
    }

    .menu__list .menu__nav-item {
        display: inline-block;
        margin:$gutter/1.5;
    }

    .menu__link {
        padding: $gutter/1.7 $gutter/1.5 $gutter/1.3 $gutter/1.5;
        font-size: 1.25em;
    }

    .menu__search span {
        display: inline-block;
    }

    .menu__sub {
        height: auto;
        max-height: none;
    }

    .menu__brand img {
        width: 200px;
        height: 129px;
    }

    .menu__search__text {
        display: inline;
    }

    .menu__search .icon {
        margin-right: $gutter/2;
    }
    
}