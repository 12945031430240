// ========================================================
// FORM
// ========================================================

form {
    margin-top: 0;
}

h2 + form {
    margin-top: $gutter;
}

.text-label,
fieldset > label {
    font-weight: 700;
    margin-bottom: $gutter/2;
    font-size: 1em; // 16px;
    line-height: 1.365;
}

fieldset {
    margin-bottom: $gutter;
    position: relative;

    &:last-of-type {
        margin-bottom: 0;
    }
}

fieldset .icon {
    margin-right: 0;
}

.fieldset__inner {
    position: relative;
    display: block;
    border: 1px solid #fff;
    border-radius: $radius-border;
    margin-top: $gutter;
    background-color: #FFF;

    &:before {
        content: "";
        display: table;
        clear: both;
    }

    &:last-of-type {
        margin: 0;
    }
}

.fieldset__inner--bordless {
    border: none;
}


.fieldset__row {
    margin: -$gutter/2;
}

.fieldset__row > fieldset {
    padding: $gutter/2;
    margin: 0;
}


textarea,
input,
select {
    padding: $gutter/1.5;
    border: 1px solid $color-primary;
    border-radius: $radius-border;
    transition: color $time-fast ease-in-out, box-shadow $time-fast ease-in-out;
    line-height: 1;
    background-color: #FFF;
    color: $color-text;

    &::-webkit-input-placeholder {
        color: $color-text;
        opacity: 0.8;
    }

    &:focus,
    &:active {
        transition: color $time-fast ease-in-out, box-shadow $time-fast ease-in-out;
        box-shadow: 0 5px 15px rgba(0,0,0,.15), 0 0 0px rgba(0,0,0,.05);
        color: $color-text;
    }
}


label {
    font-weight: 400;
    display: inline-block;
}

.js-floating-label label {
    position: absolute;
    top: $gutter/1.5;
    left: $gutter/1.5;
    right: $gutter/1.5;
    transform-origin: 0 center;
    z-index: 1;
    transition: transform $time-fast ease-in-out, top $time-fast ease-in-out;
}

.js-floating-label.focus label,
.js-floating-label.has-value label,
.no-js .js-floating-label label {
    transform: scale(0.7);
    top: 0;
}


input[type='checkbox'],
input[type='radio'] {
    position: fixed;
    bottom: 100%;
    right: 100%;
    opacity: 0;
    visibility: hidden;
}

input[type='range'] {
    display: none;
    position: absolute;
    top: 0;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  animate: 0.2s;
  background-color: $color-primary;
}

input[type=range]::-webkit-slider-thumb {
  height: 36px;
  width: 16px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7.5px;
}

.no-js input[type='range'] {
    display: block;
    padding: 0;
}

// ERROR
.fieldset-error {
    position: relative;
    z-index: 4;
}

.error-box {
    transition: opacity $time ease-in-out, visibility $time $time ease-in-out, transform $time ease-in-out;
    transform: translateY(20%);
    visibility: hidden;
    opacity: 0;
    border-radius: $radius-border;
    background-color: $color-red;
    padding: $gutter/2 $gutter/1.25;
    font-size: 16px;
    color: #FFF;
    box-sizing: border-box;
    position: absolute;
    top: 7px;
    line-height: 1.4;
    left: 0;
    display: inline-block;
    z-index: 1;
    speak: none;
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.32);
}

.error-box:after {
    bottom: 100%;
    left: 12px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: $color-red;
    border-width: 7px;
}

.error {
    .fieldset-error .error-box {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
        speak: auto;
        transition: opacity $time ease-in-out, visibility $time ease-in-out, transform $time ease-in-out;
    }

    textarea, input {

        &:focus ~ .fieldset-error .error-box {
            transition: opacity $time ease-in-out, visibility $time $time ease-in-out, transform $time ease-in-out;
            transform: translateY(20%);
            visibility: hidden;
            opacity: 0;
        }
    }
}


.fieldset-required.error label:after {
    color: $color-red;
}

// RADIO

input[type='radio'] + label {
    display: block;
    float: none;
    position: relative;
    margin: 0 0 $gutter/2;
    padding: 0 0 0 $gutter*1.5;
    font-weight: 300;

    &:before,
    &:after {
        transition: background-color $time-fast ease-in-out, color $time-fast ease-in-out;
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 100%;
    }

    &:before {
        left: 0;
        width: 20px;
        height: 20px;
        background-color: #FFF;
        border: 1px solid $color-primary;
    }

    &:after {
        left: 5px;
        width: 10px;
        height: 10px;
        background-color: transparent;
    }

    &:hover,
    &:focus {
        &:after {
            background-color: $color-primary;
        }
    }
}

input[type='radio']:checked + label {
    &:before {
        background-color: #fff;
    }

    &:after {
        background-color: $color-primary;
    }
}


// SEARCH
[type='search'] {
    padding-left: $gutter*2.5;
}

[type='search'] + button {
    position: absolute;
}

.search__submit {
    padding: $gutter/2 $gutter/1.25;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    background-color: transparent;
}

.fieldset__search,
.fieldset__search--button {
    position: relative;

    &:before {
        content: '';
        background-image: url("../assets/images/icons/search.svg");
        background-size: contain;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: $gutter/1.25;
        transition: color $time-fast ease-in-out;
    }
}

    .fieldset__search--button [type='search'] {
        padding-right: $gutter*5;
    }

    .fieldset__search--button [type='search']:not(.border) {
        border: none;
    }

    .fieldset__search--button button {
        top: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        line-height: 0;
    }

// SELECT

.select-box {
    position: relative;

    &:before {
        content: '';
        border-radius: $radius-border;
        background-color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &:after {
        content: '';
        background-image: url("../assets/images/icons/dropdown-down.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        width: 18px;
        height: 18px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: $gutter/1.25;
        transition: color $time-fast ease-in-out;
    }

    &:focus:after {
        color: lighten($color-text,25%);
    }
}

.select-box select {
    opacity: 0.8;
    height: 49px;
    -webkit-appearance: none; /*Removes default chrome and safari style*/
    -moz-appearance: none; /*Removes default firefox style*/
    appearance: none; 
    background-color: transparent;
    z-index: 1;
    position: relative;
    padding-right: $gutter * 2;
    line-height: 1.2;

    &::-ms-expand {
        display: none; /*Removes default IE style*/
    }
}

.select-box .tall {
    height: 51px;
}

// CHECKBOX

input[type="checkbox"] + label {
    display: block;
    position: relative;
    font-weight: normal;
    float: none;
    vertical-align: middle;
    margin: 0 0 $gutter/2 0;
    padding: 0 0 0 $gutter*1.5;

    &:before {
        content: '';
        border: 1px solid #fff;
        border-radius: $radius-border;
        display: inline-block;
        width: 1.4em;
        height: 1.4em;
        box-sizing: border-box;
        position: absolute;
        left: 0;
        top: 0;
        transition: background-color $time-fast ease-in-out, color $time-fast ease-in-out, border $time-fast ease-in-out;
    }

    &:after {
        content: none;
        position: absolute;
        font-size: 12px;
        left: 8px;
        top: 4px;
        transition: color $time-fast ease-in-out;
    }

    &:hover,
    &:focus {
        &:before {
            background: url('../assets/images/icons/tick.svg') center center no-repeat;
        }
    }
}

input[type="checkbox"]:checked + label {

    &:before {
        background: #fff url('../assets/images/icons/tick.svg') center center no-repeat;
    }
}



// RANGE

.fieldset__range label {
    margin-bottom: $gutter*1.5;
}

.fieldset__range {
    margin: $gutter*1.25 auto;
}

.range {
    margin-top: $gutter;
    margin-bottom: $gutter;
    width: calc(100% - 80px);
    margin-left: $gutter*1.75;
    background-color: $color-primary;
    position: relative;
    height: 2px;
    font-weight: 700;
    color: $color-text;

    &:before,
    &:after {
        position: absolute;
        top: 50%;
        font-size: 0.9rem;
        // white-space: nowrap;
    }

    &:before {
        content: attr(data-min);
        left: 0;
        width: 35px;
        transform: translate(-100%, -50%);
        margin-right: $gutter/2;
    }

    &:after {
        content: attr(data-max);
        left: 100%;
        transform: translate(0%, -50%);
        margin-left: $gutter/2;
    }
}


.range__track {
    position: relative;
    background-color: $color-green; 
    height: 2px;
}

.range__thumb {
    box-shadow: 0px 4px 7px rgba(0,0,0,.3);
    border-radius: 100%;
    width: 15px;
    height: 15px;
    background-color: $color-green;
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    cursor: pointer;
    transition: background-color $time-fast ease-in-out, box-shadow $time-fast ease-in-out;

    &:hover,
    &:focus,
    &:active,
    .range__thumb--moving {
        background-color: darken($color-green, 2%);
        backdrop-filter: blur(50px);
        box-shadow: 0px 0px 7px rgba(0,0,0,.5);


        .range__tooltip {
            opacity: 1;
            visibility: visible;
            transition-delay: 0s;
            transform: translate(-50%, -$gutter/2);
        }
    }
}


.no-js .range__thumb {
    display: none;
}

.range__tooltip {
    font-weight: 100;
    background-color: $color-primary;
    display: inline-block;
    padding: $gutter/2;
    white-space: nowrap;
    border-radius: $radius-border;
    position: absolute;
    bottom: 100%;
    opacity: 0;
    font-size: 12px;
    line-height: 1;
    color: #fff;
    visibility: hidden;
    transition: $time-fast ease-out;
    transition-property: transform opacity visibility;
    left: 50%;
    transform: translate(-50%, 0);

    &:before {
        content: '';
        border-left: solid transparent 7px;
        border-right: solid transparent 7px;
        border-top: solid $color-primary 7px;
        top: 100%;
        height: 0;
        position: absolute;
        width: 0;
        left: 50%;
        transform: translate(-50%, 0);
    }
}

.range__face--moving .range__tooltip {
    transform: translate(-50%, 0);
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
}

.range__value {
    vertical-align: middle;
    display: inline-block;
    margin-top: -1px;
}



// ========================================================
// DESKTOP
// ========================================================

@include respond-min-width($view-desktop){

    textarea,
    input {
        line-height: 1;
    }

    .fieldset__row > fieldset {
        width: 50%;
        float: left;
    }

} // END MEDIA

