// ========================================================
// Box
// ========================================================

.box {
    border-radius: $radius-border;
    background-repeat: repeat; 
}


.box--border-primary {
	box-shadow: 0 0 0 1px inset $color-primary;
}

.box--border-secondary {
	box-shadow: 0 0 0 1px inset $color-secondary;
}

.box--border-blue {
	box-shadow: 0 0 0 1px inset $color-blue;
}

.box--border-green {
	box-shadow: 0 0 0 1px inset $color-green;
}

.box--border-red {
	box-shadow: 0 0 0 1px inset $color-red;
}

.box--border-purple {
	box-shadow: 0 0 0 1px inset $color-purple;
}



.box--solid-primary {
	background-color: $color-primary;
	color: #fff;
}

.box--solid-secondary {
	background-color: $color-secondary;
}

.box--solid-blue {
	background-color: $color-blue;
}

.box--solid-green {
	background-color: $color-green;
}

.box--solid-red {
	background-color: $color-red;
}

.box--solid-purple {
	background-color: $color-purple;
}



.box--bg-primary {
	background-image: url('../assets/images/bg-main-green.png');
	color: #fff;
	box-shadow: 0 15px 20px rgba(0,0,0,0), 0 2px 15px rgba(0,0,0,0.0);
	transition: box-shadow $time;

	&:hover,
	&:focus,
	&:active {
		box-shadow: 0 15px 20px rgba(0,0,0,0.1), 0 2px 15px rgba(0,0,0,0.1);
	}
}

.box--bg-secondary {
	background-image: url('../assets/images/bg-secondary.png');
	color: $color-text;
}

.box--bg-blue {
	background-image: url('../assets/images/bg-blue.png');
}

.box--bg-green {
	background-image: url('../assets/images/bg-green.png');
}

.box--bg-red {
	background-image: url('../assets/images/bg-red.png');
}

.box--bg-purple {
	background-image: url('../assets/images/bg-purple.png');
}