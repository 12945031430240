// ========================================================
// HERO PRODUCT
// ========================================================

.hero-product .hero__detail__preperation {
		margin-top: $gutter * 12;
		margin-bottom: $gutter;
	}

.hero-product .hero__detail__preperation li {
	display: block;

	&:first-child {
		margin-top: 0;
	}
}

.hero-product .hero__detail__ingredients {
	border: 1px solid rgba(255,255,255,0.5);
	padding: $gutter/2;
}

.hero-product .hero__detail__ingredients li {
	border: 1px solid $color-secondary;
	padding: $gutter/5 $gutter/2;
}

.hero-product .hero__detail__ingredients span {
	vertical-align: initial;
}
	
.hero-product .hero__detail .btn {
	margin-top: $gutter;

	&:hover,
	&:focus,
	&:active {
		.icon {
			fill: $color-secondary;
		}
	}	
}

.hero-product .hero__detail .btn .icon {
	margin-right: $gutter/4;
}

.hero-product .svg__product-bg {
	fill: $color-secondary;
}

.hero-product .hero__detail__product-image {
	transition: transform $time-fast ease-in-out;
	position: absolute;
	width: 170px;
	top: $gutter/2;
	left: $gutter * 2;

	&:hover,
	&:focus,
	&:active {
		transform: scale(1.1, 1.1) rotate(-2deg);

		.svg__product-bg {
			transform: scale(2.5);
			opacity: 1;
		}

		.icon--eye-circle {
			transform: rotate(4deg) translate(20%, 30%);
		}
	}
}

.hero-product .hero__detail__product-image .arrow {
	position: absolute;
	bottom: 0;
	transform: rotate(170deg);
	right: 50px;
	width: 50%;
}

.hero-product .product-image {
	margin: 0;
	z-index: 100;
	margin-bottom: $gutter/2;
}

.hero-product .card__bg {
	z-index: -1;
}

.hero-product .hero__detail__product-image .icon--eye-circle {
	position: absolute;
	right: 0;
	bottom: 0;
	transform: translate(30%, 20%);
	transform-origin: 40% 20%;
}

.hero-product .icon--zoomer {
	display: none;
}

.hero-product .btn--secondary {
	margin-top: $gutter;
}

.hero-product .hero__detail button .icon {
	transition: none;
}


@media only screen and (min-width: 440px) {
	
	.hero-product .hero__detail__product-image {
		right: $gutter * 2;
		left: auto;
	}

	.hero-product .hero__detail__preperation {
		margin-top: 0;
		margin-bottom: $gutter*3;
	}

	.hero-product .hero__header {
		padding-right: 200px;
	}

	.hero-product .hero__detail__product-image {
		top: -$gutter * 4;
	}
}


@media only screen and (min-width: $view-tablet-landscape) {

	.hero-product .hero__detail__preperation li {
		display: inline-block;
		margin-top: 0;
		margin-bottom: $gutter;
		margin-right: $gutter;
	}

	.hero-product .hero__detail__preperation {
		margin-bottom: $gutter;
	}


	.hero-product .hero__detail__product-image {
		top: -$gutter * 6;
	}
}

@media only screen and (min-width: $view-desktop) {

	.hero-product .content-box--4x\@desktop {
		padding-right: 350px;
	}

	.hero-product .hero__detail__product-image {
		width: 260px;
		right: $gutter * 3;
	}

	.hero-product .hero__detail__preperation {
		margin-bottom: 0;
	}

	.hero-product .hero__detail__ingredients,
	.hero-product .hero__detail__preperation {
		display: inline-block;
		vertical-align: top;
	}

	.hero-product .hero__detail__preperation li:first-child,
	.hero-product .hero__detail__preperation li {
		margin-top: $gutter/2;
		margin-bottom: $gutter/2;
	}

	.hero-product .hero__detail__product-image .arrow {
		width: 80px;
		bottom: -20px;
	}

	.hero-product .hero__detail__product-image {
		top: -$gutter * 7;
	}
}

