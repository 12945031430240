// ========================================================
// HERO HOME
// ========================================================

.hero-main {
	color: $color-secondary;
}


.hero-article .hero__image
.hero-main .hero__image {
	height: initial;
}

.hero-main h1,
.hero-main h2,
.hero-main p {
	text-shadow: 1px 1px rgba(0,0,0,.8);
}

.hero-main p {
	margin-bottom: $gutter/2;
}

@media only screen and (min-width: $view-tablet-landscape) {
	.hero-main h1,
	.hero-main h2,
	.hero-main p {
		text-shadow: 1px 2px rgba(0,0,0,.5);
	}

	.hero-main .content-box {
		width: 50%;
	}

	.hero-main .btn--arrow {
		position: absolute;
		left: 50%;
		bottom: 12%;
		z-index: 1;
	} 

}

@media only screen and (min-width: $view-desktop) {

}