// ========================================================
// CAROUSEL
// ========================================================
.carousel-slider {
	position: relative;
}

.carousel-scroller__direction-btn--prev,
.carousel-scroller__direction-btn--next {
	position: absolute;
	z-index: 1;
	top: 50%;

	&:hover,
	&:focus {
		.carousel-scroller__direction-icon--prev,
		.carousel-scroller__direction-icon--next {
			fill: $color-secondary;
		}	
	}
}

.carousel-scroller__direction-btn--prev {
	left: $gutter;
}

.carousel-scroller__direction-btn--next {
	right: $gutter;
}

.carousel-scroller__direction-icon--prev,
.carousel-scroller__direction-icon--next {
	transition: none;
	fill: $color-primary;
}

.carousel__direction-btn--prev img,
.carousel__direction-btn--next img {
	width: 1rem;
	height: 1rem;
}

.carousel-list {
	display: block;
	overflow: auto;
	width: 100%;
	white-space: nowrap;
	z-index: 0;
	-webkit-overflow-scrolling: touch;
}


html:not(.no-js) .carousel-list {
	-ms-overflow-style: none;  // IE 10+
    overflow: -moz-scrollbars-none;  // Firefox

    &::-webkit-scrollbar { 
	    display: none;  // Safari and Chrome
	}
}

.carousel-list > li {
	vertical-align: middle;
	display: inline-block;
	width: 100%;
	white-space: normal;
}

.carousel-list > li a {
	display: block;
}

@media screen and (min-width: $view-tablet-portrait) {
	.carousel-list > li {
		width: 50%;
	}
}

@media screen and (min-width: $view-tablet-landscape) {
	.no-js .carousel-list {
		height: 60%;
		top: 25%;
		transform: none;
	}
}

@media screen and (min-width: $view-desktop) {
	.carousel-list > li {
		width: 25%;
	}

	
	.carousel-list.carousel-list--odd {
		white-space: normal;
	}

	.carousel-list--odd > li {
		width: 33.333%;
	}
}