// ========================================================
// FULL SCREEN
// ========================================================

// section--fullscreen-bg

.section--fullscreen-bg	h1 {
	color: $color-secondary;
	text-shadow: 0 0 20px rgba(0,0,0,.5);
}

.section--fullscreen-bg	.fullscreen-bg__container {
	box-shadow: 0 0 20px rgba(0,0,0,.3);
	margin-bottom: $gutter;		
}

// press

.press .list-link__icon {
	fex-schrink: 0;
}

.press .list-link__icon svg {
	left: 0;
	bottom: initial;
	margin-right: $gutter;
}

// error 404
.error-404 {
	display: flex;
	align-items: center;
}

.error-404 .btn {
	text-transform: uppercase;
}

.error-404 .fullscreen-bg__container {
	position: relative;

	&:after {
		position: absolute;
		right: -35px;
		bottom: -50px;
		content: none;
		background-image: url('../assets/images/detail-leaf.png');
		background-repeat: no-repeat;
		background-size: contain;
		width: 220px;
		height: 125px;
	}
}




// faq

.faq h3 {
	color: $color-primary;
	margin-bottom: 0;
}

.faq .box {
	margin-bottom: $gutter;
}

// search-results

.search-results p {
	margin-top: 0;
}

.search-results h3 {
	color: $color-primary;
}

.search-results sup {
	padding-left: $gutter/4;
	color: $color-text;
}

.search-results .list-link {
	flex-direction: column;
}

.search-results .list--ingredients li  {
	padding: 0;
	padding-left: $gutter * 1.5;
	margin-right: $gutter;

	&:before {
		color: $color-primary;
	} 
}

.search-results .list-link__text li {
	&:before {
		color: $color-dark-grey;
	} 
}

.search-results .list--links__link {

	&:after {
		content: none;
		display: none;
	}

	&:last-of-type {

		&:after {
			content: "";
			display: block;
		}
	}
}

.search-results .list-link__icon--card {
	margin-bottom: $gutter/2;
}

@media only screen and (min-width: $view-tablet-portrait) {

	.search-results .list-link {
		flex-direction: row;
	}

	.search-results .list-link__icon--card {
		margin-bottom: 0;
	}
}

@media only screen and (min-width: $view-tablet-landscape) {

	.error-404 .fullscreen-bg__container {
		&:after {
			content: "";
		}
	}
}