// ========================================================
// HERO
// ========================================================

.hero {
    position: relative;
    color: $color-secondary;
    overflow: hidden;
}

.hero__image {
    position: relative;
    height: 500px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    &:after {
        content: '';
        background-image: url('../assets/images/skew-line-reverse.svg');
        position: absolute;
        bottom: -30px;
        left: 50%;
        right: 0;
        width: 101%;
        height: 140px;
        background-repeat: no-repeat;
        background-size: cover;
        transform: translateX(-50%);
       
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(0deg,rgba(0,0,0,.25) 25%,rgba(46,46,46,0) 85%);
    }
}


.hero .label-category {
    width: 225px;
    margin-bottom: $gutter/2;
}

.hero__header {
    position: absolute;
    left: $gutter;
    right: $gutter;
    bottom: 100%;
    text-shadow: 1px 2px rgba(0,0,0,.5);
}

.hero .section__inner {
    margin-top: (-$gutter * 7);
}

.hero__detail {
    margin: $gutter/2;
    background-repeat: no-repeat;
    background-size: cover;
}


.hero__detail__preperation .icon {
    margin-right: $gutter/4;
    fill: #fff;
}

.hero__detail__preperation p,
.hero__detail__ingredients p {
    display: inline-block;
    vertical-align: middle;
}


.hero--middle .section__inner {
    height: 100%;
    display: table;
}

.hero--middle .section__inner  .content-box {
    display: table-cell;
    vertical-align: middle;
} 

@media only screen and (min-width: $view-desktop) {

    .hero__header {
        max-width: 900px;
    }
    .hero__image {
        height: 640px;

        &:after {
            bottom: 0px;
        }
    }
}